import { ReactComponent as CropSteeringP0 } from '../Images/crop_steering_v2_p0.svg';
import { ReactComponent as CropSteeringP1 } from '../Images/crop_steering_v2_p1.svg';
import { ReactComponent as CropSteeringP2 } from '../Images/crop_steering_v2_p2.svg';
import { ReactComponent as CropSteeringP3 } from '../Images/crop_steering_v2_p3.svg';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiSwitch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { ExpandableSettingsGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { HandleAction } from '../Utils/utils.js';
import { LoadSettingsDialog } from '../Utils/load_settings';
import Desc from '../FormWidgets/Description';

import { settingControls, useSavedSettings } from './settingFunctions';
import { SettingActions } from '../components';
import { useTranslation } from 'react-i18next';
import { irrigationStrategyMapping } from '../Constants/constants';
import { FlexSelect } from '../FormWidgets/Select';
import { MyTimePicker } from '../FormWidgets/DatePicker';

import getStateManager from '../StateManager.js';

const useStyles = makeStyles((theme) => ({
  cropSteering: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0.5%'
    },
    [theme.breakpoints.up('md')]: {
      width: '200%',
      marginLeft: '0.5%'
    }
  }
}));

export default function IrrigationSettings({ controller, parent, ...other }) {
  let { tab_id } = useParams();
  let { t } = useTranslation();
  const classes = useStyles();
  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);
  const [displaySavedVWCMax, setDisplaySavedVWCMax] = useState('--- % VWC');

  useEffect(() => {
    if (values.vwc_auto_sp_max_saved_info === -1) {
      setDisplaySavedVWCMax('--- % VWC');
    } else {
      setDisplaySavedVWCMax((values.vwc_auto_sp_max_saved_info / 10).toFixed(1) + ' % VWC');
    }
  }, [values.vwc_auto_sp_max_saved_info]);

  const [confirm_action_dialog_infos, setOpenConfirmActionDialog] = React.useState({
    open: false,
    type: '',
    value: undefined
  });
  const openConfirmActionDialog = (type, value) => {
    setOpenConfirmActionDialog({ open: true, type: type, value: value });
  };
  const closeConfirmActionDialog = () => {
    setOpenConfirmActionDialog({ open: false, type: '', value: -1 });
  };

  console.log('[irr] ', values);

  return (
    <React.Fragment>
      <ConfirmActionDialog
        open={confirm_action_dialog_infos.open}
        handleClose={closeConfirmActionDialog}
        controller={controller}
        action_type={confirm_action_dialog_infos.type}
        action_value={confirm_action_dialog_infos.value}
      />

      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <ExpandableSettingsGroupGrid label={t('more_irrigation_set_hl')} expand_on_load={true}>
        <ValueBox controller={controller} id={'enable_irrigation'}>
          <Typography gutterBottom>
            <MuiSwitch
              checked={values.enable_irrigation === 1}
              onClick={(event) => {
                controls.setValue('enable_irrigation', event.target.checked ? 1 : 0);
              }}
            />
            <Desc id="enable_irrigation" />
          </Typography>
        </ValueBox>
        <ValueBox controller={controller} id={'irrigation_p2_strategy'}>
          <Typography gutterBottom>
            <Desc id="irrigation_p2_strategy" />
          </Typography>
          <FlexSelect
            id="irrigation_p2_strategy"
            controller={controller}
            value={values.irrigation_p2_strategy}
            controller_value={parent.settings.irrigation.irrigation_p2_strategy}
            controls={controls}
            mapping={irrigationStrategyMapping}
            onChange={(event) => controls.setValue('irrigation_p2_strategy', event.target.value)}
          />
        </ValueBox>
      </ExpandableSettingsGroupGrid>

      <ExpandableSettingsGroupGrid label="phase_0_activation">
        <CropSteeringP0 className={classes.cropSteering} />
        <></>
        <ShowSetting
          id="irrigation_p0_dry_back_abs"
          controller={controller}
          cur_value={values.irrigation_p0_dry_back_abs}
          controller_value={parent.settings.irrigation.irrigation_p0_dry_back_abs}
          controls={controls}
        />

        <ExpandableSettingsGroupGrid label={t('more')} outside={true}>
          <ShowSetting
            id="irrigation_p0_phase_timeout_minutes"
            controller={controller}
            cur_value={values.irrigation_p0_phase_timeout_minutes}
            controller_value={parent.settings.irrigation.irrigation_p0_phase_timeout_minutes}
            controls={controls}
          />
          <ValueBox controller={controller} id={'irrigation_p0_enable'}>
            <Typography gutterBottom>
              <MuiSwitch
                checked={values.irrigation_p0_enable === 1}
                onClick={(event) => {
                  controls.setValue('irrigation_p0_enable', event.target.checked ? 1 : 0);
                }}
              />
              <Desc id="irrigation_p0_enable" />
            </Typography>
          </ValueBox>
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <ExpandableSettingsGroupGrid label={t('phase_1_pamp_up')}>
        <CropSteeringP1 className={classes.cropSteering} />
        <></>
        {values.irrigation_p2_strategy !== 0 && (
          <ShowSetting
            id="irrigation_p1_target_vwc_abs"
            controller={controller}
            cur_value={values.irrigation_p1_target_vwc_abs}
            controller_value={parent.settings.irrigation.irrigation_p1_target_vwc_abs}
            controls={controls}
          />
        )}

        <ExpandableSettingsGroupGrid label={t('more')} outside={true}>
          <ShowSetting
            id="irrigation_p1_shot_size_vwc_rel"
            controller={controller}
            cur_value={values.irrigation_p1_shot_size_vwc_rel}
            controller_value={parent.settings.irrigation.irrigation_p1_shot_size_vwc_rel}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_dry_back_rel"
            controller={controller}
            cur_value={values.irrigation_p1_dry_back_rel}
            controller_value={parent.settings.irrigation.irrigation_p1_dry_back_rel}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_wait_after_shot_minutes"
            controller={controller}
            cur_value={values.irrigation_p1_wait_after_shot_minutes}
            controller_value={parent.settings.irrigation.irrigation_p1_wait_after_shot_minutes}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_shot_duration_timeout_minutes"
            controller={controller}
            cur_value={values.irrigation_p1_shot_duration_timeout_minutes}
            controller_value={
              parent.settings.irrigation.irrigation_p1_shot_duration_timeout_minutes
            }
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_phase_timeout_minute"
            controller={controller}
            cur_value={values.irrigation_p1_phase_timeout_minute}
            controller_value={parent.settings.irrigation.irrigation_p1_phase_timeout_minute}
            controls={controls}
          />
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <ExpandableSettingsGroupGrid label={t('phase_2_maintain')}>
        <CropSteeringP2 className={classes.cropSteering} />
        <></>
        {values.irrigation_p2_strategy === 0 ? (
          <>
            <ValueBox controller={controller} id={'vwc_auto_sp_max_saved_info'}>
              <Typography gutterBottom>
                <Desc id="vwc_auto_sp_max_saved_info" />
              </Typography>
              {displaySavedVWCMax}
            </ValueBox>

            <ShowSetting
              id="irrigation_p2_offset_target_vwc"
              controller={controller}
              cur_value={values.irrigation_p2_offset_target_vwc}
              controller_value={parent.settings.irrigation.irrigation_p2_offset_target_vwc}
              controls={controls}
              type="float"
            />
          </>
        ) : (
          <ShowSetting
            id="irrigation_p2_target_vwc_abs"
            controller={controller}
            cur_value={values.irrigation_p2_target_vwc_abs}
            controller_value={parent.settings.irrigation.irrigation_p2_target_vwc_abs}
            controls={controls}
          />
        )}
        <ShowSetting
          id="irrigation_p2_dry_back_abs"
          controller={controller}
          cur_value={values.irrigation_p2_dry_back_abs}
          controller_value={parent.settings.irrigation.irrigation_p2_dry_back_abs}
          controls={controls}
          type="float"
        />

        <ValueBox controller={controller} id={'irrigation_p2_end_before_sunset'}>
          <Typography gutterBottom>
            <Desc id="irrigation_p2_end_before_sunset" />
          </Typography>
          <MyTimePicker
            value={values.irrigation_p2_end_before_sunset}
            name="irrigation_p2_end_before_sunset"
            id="irrigation_p2_end_before_sunset"
            onChange={(value) =>
              controls.setValue(
                'irrigation_p2_end_before_sunset',
                value.toLocaleTimeString('de-DE')
              )
            }
          />
        </ValueBox>

        <ExpandableSettingsGroupGrid label={t('more')} outside={true}>
          <ShowSetting
            id="irrigation_p2_shot_duration_timeout_minutes"
            controller={controller}
            cur_value={values.irrigation_p2_shot_duration_timeout_minutes}
            controller_value={
              parent.settings.irrigation.irrigation_p2_shot_duration_timeout_minutes
            }
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p2_wait_after_shot_s"
            controller={controller}
            cur_value={values.irrigation_p2_wait_after_shot_s}
            controller_value={parent.settings.irrigation.irrigation_p2_wait_after_shot_s}
            controls={controls}
          />
          <Button
            onClick={() => openConfirmActionDialog('action_rs_vwc_auto_sp_max', undefined)}
            color="secondary"
          >
            {t('rs_vwc_auto_sp_max')}
          </Button>
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <ExpandableSettingsGroupGrid label={t('phase_3_drydown')}>
        <CropSteeringP3 className={classes.cropSteering} />
        <></>
        <ShowSetting
          id="irrigation_p3_min_vwc_abs"
          controller={controller}
          cur_value={values.irrigation_p3_min_vwc_abs}
          controller_value={parent.settings.irrigation.irrigation_p3_min_vwc_abs}
          controls={controls}
        />

        <ExpandableSettingsGroupGrid label={t('more')} outside={true}>
          <ShowSetting
            id="irrigation_p3_shot_size_rel"
            controller={controller}
            cur_value={values.irrigation_p3_shot_size_rel}
            controller_value={parent.settings.irrigation.irrigation_p3_shot_size_rel}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p3_min_wait_after_shot"
            controller={controller}
            cur_value={values.irrigation_p3_min_wait_after_shot}
            controller_value={parent.settings.irrigation.irrigation_p3_min_wait_after_shot}
            controls={controls}
          />
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}

function ConfirmActionDialog({ open, handleClose, controller, action_type, action_value }) {
  let { t } = useTranslation();

  const stateManager = getStateManager();

  const doAction = (event) => {
    HandleAction(stateManager.wsManager, controller, action_type, action_value);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('confirm_action_title')}</DialogTitle>
      <DialogContent>{t(action_type + '_text')}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={doAction} color="primary">
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
