import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Desc from '../FormWidgets/Description';
import { Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@material-ui/core/Button';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';

import { settingControls, useSavedSettings, writeTempSettings } from './settingFunctions';
import { SettingActions } from '../components';
import { GetIndexedId, IndexedId } from '../Utils/utils';
import { useTranslation } from 'react-i18next';
import { FlexSelect } from '../FormWidgets/Select';
import { nutritionPumpTypeMapping, nutritionPumpUnitMapping } from '../Constants/constants';

import { ExpandMore } from '../Utils/Expand';
import { Grid } from '@material-ui/core';
import { EditableLabel } from '../FormWidgets/EditableLabel';
import _ from 'lodash';

export default function NutrientSettings({ controller, parent, ...other }) {
  let { tab_id } = useParams();
  let { t } = useTranslation();
  const [values, setValues] = useState(_.cloneDeep(parent.settings[tab_id]));
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);

  // handle expand pumps
  const [expand, setExpand] = useState(
    Array.from({ length: values.pump_cnt_used }, (_, i) => false)
  );
  const handleExpandClick = (i) => {
    let modifiedExpand = expand;
    modifiedExpand[i] = !expand[i];
    setExpand(modifiedExpand);
  };

  controls.saveTempSettings = (id, v) => {
    if (parent.temp_settings[tab_id] == null) {
      parent.temp_settings[tab_id] = { pumps: [] };
    }
    if (id.indexOf(':') > -1) {
      const [index, field_id] = GetIndexedId(id);
      if (parent.temp_settings[tab_id].pumps.length > index)
        parent.temp_settings[tab_id].pumps[index][field_id] = v;
      else parent.temp_settings[tab_id].pumps.push({ [field_id]: v });
    } else parent.temp_settings[tab_id][id] = v;
    writeTempSettings(parent);
  };

  controls.restoreTempSettings = () => {
    let merged = _.merge(
      _.cloneDeep(parent.settings[tab_id]),
      _.cloneDeep(parent.temp_settings[tab_id])
    );
    // Always overwrite pumps from temp-settings when restoring temp-settings.
    merged.pumps = parent.temp_settings[tab_id].pumps;
    setValues(merged);
  };

  controls.resetSetting = (id) => {
    if (id.indexOf(':') > -1) {
      const [index, field_id] = GetIndexedId(id);
      delete parent.temp_settings[tab_id]['pumps'][index][field_id];
      let updatedValues = values;
      updatedValues['pumps'][index][field_id] = parent.settings[tab_id]['pumps'][index][field_id];
      setValues(updatedValues);
    } else {
      delete parent.temp_settings[tab_id][id];
      setValues({ ...values, [id]: parent.settings[tab_id][id] });
      delete parent.temp_settings[tab_id][id];
    }
    writeTempSettings(parent);
  };

  controls.setValue = (id, v, saveTemp = true) => {
    if (id.indexOf(':') > -1) {
      const [index, field_id] = GetIndexedId(id);
      let updatedValues = values;
      updatedValues['pumps'][index][field_id] = v;
      setValues(updatedValues);
    } else setValues({ ...values, [id]: v });
    // turn this off in slider's onChange to improve performance
    if (saveTemp) controls.saveTempSettings(id, v);
  };

  const addPump = () => {
    if (values.pump_cnt_used < 8) {
      let pumps = values.pumps;
      pumps.push({ type: 0, unit: 0, value: 0, name: '' });
      controls.setValue('pumps', pumps);
    }
  };
  const delLastPump = () => {
    let pumps = values.pumps;
    pumps.pop();
    controls.setValue('pumps', pumps);
  };

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroupGrid controller={controller} id={'nutrient_solution'}>
        <ShowSetting
          id="ec_setpoint"
          controller={controller}
          cur_value={values.ec_setpoint}
          controller_value={parent.settings.nutrition.ec_setpoint}
          controls={controls}
          type="float"
          num_digits={2}
        />
        <ShowSetting
          id="ph_setpoint"
          controller={controller}
          cur_value={values.ph_setpoint}
          controller_value={parent.settings.nutrition.ph_setpoint}
          controls={controls}
          type="float"
          num_digits={2}
        />
        <ShowSetting
          id="temp_setpoint"
          controller={controller}
          cur_value={values.temp_setpoint}
          controller_value={parent.settings.nutrition.temp_setpoint}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      {values.pumps.map((pump, i) => (
        <SettingGroupGrid label={t('pump') + ' ' + (i + 1)} fullWidth={true}>
          <Grid container>
            <Grid item xs={4}>
              <EditableLabel
                label={'my_memo'}
                id={IndexedId(i, 'name')}
                value={values.pumps[i].name ?? -1}
                handleInput={(_, event, value) => controls.setValue(IndexedId(i, 'name'), value)}
                no_label={true}
              />
            </Grid>
            <Grid item xs={4}>
              {t(
                nutritionPumpTypeMapping[values.pumps[i].type] ??
                  nutritionPumpTypeMapping['default']
              )}
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center', marginTop: '-5em', marginLeft: '45%', width: '10%' }}>
            <ExpandMore
              expand={expand[i]}
              onClick={() => handleExpandClick(i)}
              aria-expanded={expand[i]}
              aria-label="show more"
            >
              <ExpandMoreIcon fontSize="large" />
            </ExpandMore>
          </div>

          <Collapse in={expand[i]} timeout="auto" unmountOnExit>
            <Grid container spacing={1} columns={{ xs: 6, md: 12 }}>
              <Grid item xs={12} md={6}>
                <ShowSetting
                  id={IndexedId(i, 'value')}
                  show="pump_dosage"
                  controller={controller}
                  cur_value={pump.value}
                  controller_value={
                    parent.settings.nutrition.pumps.length > i
                      ? parent.settings.nutrition.pumps[i].value
                      : -1
                  }
                  controls={controls}
                  unit={t(
                    nutritionPumpUnitMapping[values.pumps[i].unit] ??
                      nutritionPumpUnitMapping['default']
                  )}
                  disabled={values.pumps[i].type === 0 || values.pumps[i].type >= 2}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ValueBox controller={controller} id={'type'}>
                  <Typography gutterBottom>
                    <Desc id="pump_type" />
                  </Typography>
                  <FlexSelect
                    id={IndexedId(i, 'type')}
                    controller={controller}
                    value={pump.type}
                    controller_value={
                      parent.settings.nutrition.pumps.length > i
                        ? parent.settings.nutrition.pumps[i].type
                        : -1
                    }
                    controls={controls}
                    mapping={nutritionPumpTypeMapping}
                    onChange={(event) =>
                      controls.setValue(IndexedId(i, 'type'), event.target.value)
                    }
                  />
                </ValueBox>
                <ValueBox controller={controller} id={'lights_dim_over_temp_enable'}>
                  <Typography gutterBottom>
                    <Desc id="pump_unit" />
                  </Typography>
                  <FlexSelect
                    id={IndexedId(i, 'unit')}
                    controller={controller}
                    value={pump.unit}
                    controller_value={
                      parent.settings.nutrition.pumps.length > i
                        ? parent.settings.nutrition.pumps[i].unit
                        : -1
                    }
                    controls={controls}
                    mapping={nutritionPumpUnitMapping}
                    onChange={(event) =>
                      controls.setValue(IndexedId(i, 'unit'), event.target.value)
                    }
                    disabled={values.pumps[i].type === 0 || values.pumps[i].type >= 2}
                  />
                </ValueBox>
              </Grid>
            </Grid>
          </Collapse>
        </SettingGroupGrid>
      ))}
      <Grid container spacing={1} columns={{ xs: 6, md: 12 }}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            onClick={() => {
              addPump();
              controls.setValue('pump_cnt_used', values.pump_cnt_used + 1);
            }}
            disabled={values.pump_cnt_used >= 8}
          >
            {t('add_pump')}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            onClick={() => {
              delLastPump();
              controls.setValue('pump_cnt_used', values.pump_cnt_used - 1);
            }}
          >
            {t('del_last_pump')}
          </Button>
        </Grid>
      </Grid>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}
