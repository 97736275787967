import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha
} from 'react-simple-captcha';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useAuth } from '../Hooks/authHook';

import { useTranslation } from 'react-i18next';

import LanguageSelect from '../LanguageSelect';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import { statusColors } from '../Constants/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  registerButton: {
    marginLeft: 'auto'
  }
}));

export default function RegistrationPage() {
  let { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const [msg, setMsg] = useState('');
  const [values, setValues] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
    captcha: ''
  });

  let { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    const timer = setTimeout(() => {
      loadCaptchaEnginge(6);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const register = (event) => {
    event.preventDefault();
    if (validateCaptcha(values.captcha)) {
      auth.register(values.username, values.password, values.password2, values.email, (m) => {
        setMsg(m);
        if (m === 'good') history.replace(from);
      });
    } else {
      alert(t('invalid_captcha'));
    }
  };

  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Paper mt={4} component={Box}>
        <form className={classes.root} onSubmit={register}>
          <TextField
            autoFocus
            id="username"
            label={t('username')}
            type="text"
            fullWidth
            onChange={(event) => setValues({ ...values, username: event.target.value })}
          />
          <TextField
            id="password"
            label={t('password')}
            type="password"
            fullWidth
            onChange={(event) => setValues({ ...values, password: event.target.value })}
          />
          <TextField
            id="password2"
            label={t('repeat_password')}
            type="password"
            fullWidth
            onChange={(event) => setValues({ ...values, password2: event.target.value })}
          />
          <TextField
            id="email"
            label="E-Mail (optional)"
            type="text"
            fullWidth
            onChange={(event) => setValues({ ...values, email: event.target.value })}
          />
          <Box display="flex" alignItems="center">
            <LoadCanvasTemplateNoReload />
            <TextField
              style={{ marginLeft: 8 }}
              id="captcha"
              label="Captcha"
              type="text"
              fullWidth
              onChange={(event) => setValues({ ...values, captcha: event.target.value })}
            />
          </Box>
          <Box mt={2} display="flex" color={statusColors['bad']}>
            {msg}
          </Box>
          <Box mt={2} display="flex" alignItems="center">
            <FormLabel>{t('account_language')}</FormLabel>
            <LanguageSelect />
            <Button
              className={classes.registerButton}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('register')}
            </Button>
          </Box>
          <Box mt={2} display="flex">
            <Link style={{ color: statusColors.good }} to="/login">
              {t('to_login')}
            </Link>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}
