export const help = {
  default: {
    humidity: {
      en: {
        brief: 'Current humidity',
        full: 'Current humidity (air/room)\r\n This value is mesured by the Humidity and Temperature sensor (RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Aktuelle Luftfeuchtigkeit',
        full: 'Aktuelle Luftfeuchtigkeit \r\nDieser Wert wird vom Luftfeuchte- und Temperatursensor erfasst (RH/T).',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'Humidité actuelle',
        full: "Humidité actuelle de l'air \r\nCette valeur est enregistrée par le capteur d'humidité et de température (RH/T)",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Humedad actual',
        full: 'Humedad actual \r\nEste valor es registrado por el sensor de humedad y temperatura (HR/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: 'Umidità attuale',
        full: 'Umidità attuale Questo valore è rilevato dal sensore di umidità e temperatura (RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },

    temp: {
      en: {
        brief: 'Current room temperature',
        full: 'Current room temperature \r\nThis value is mesured by the Humidity and Temperature sensor (RH/T).',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Aktuelle Raumtemperatur',
        full: 'Aktuelle Raumtemperatur \r\nDieser Wert wird vom Luftfeuchte- und Temperatursensor erfasst (RH/T).',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'Température ambiante actuelle',
        full: "Température ambiante actuelle \r\nCette valeur est saisie par le capteur d'humidité et de température (RH/T)",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Temperatura ambiente actual',
        full: 'Temperatura ambiente actual \r\nEste valor es registrado por el sensor de humedad y temperatura (HR/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: 'Temperatura ambiente attuale',
        full: 'Temperatura ambiente attuale ´Questo valore è registrato dal sensore di umidità e temperatura (RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },

    vpd: {
      en: {
        brief: 'Vapour pressure deficit',
        full: "Vapour pressure deficit (VPD) \r\nThis value is calculated from the current humidity and the leaf temperature. \r\nIf no leaf temperature sensor is connected, the leaf temperature is calculated from the room temperature \r\nLeaf temperature' = 'Room temperature' - 'Difference leaf temperature'. The 'Leaf temperature difference' can be entered in the 'Advanced settings' section.",
        image: ''
      },
      de: {
        brief: 'Sättigungsdefizit',
        full: "Sättigungsdefizit \r\n(Vapour Pressure Deficit, VPD) \r\nDieser Wert wird aus der aktuellen Luftfeuchtigkeit und der Blatttemperatur berechnet. \r\nWenn kein Blatt-Temperatursensor angeschlossen ist, wird die Blatt-Temperatur aus der Raumtemperatur berechnet. \r\n'Blatt-Temperatur' = 'Raumtemperatur' - 'Differenz Blatt-Temperatur' \r\nDie 'Differenz Blatttemperatur' kann im Bereich 'Erweiterte-Einstellungen' eingegeben werden.",
        image: ''
      },
      fr: {
        brief: 'déficit de saturation',
        full: "déficit de saturation \r\n(Vapour Pressure Deficit, VPD) \r\nCette valeur est calculée à partir de l'humidité actuelle et de la température de la feuille. \r\nSi aucun capteur de température de feuille n'est connecté, la température de feuille est calculée à partir de la température ambiante. \r\n'Température de la feuille' = 'Température ambiante' - 'Différence de température de la feuille'. \r\nLa 'différence de température de la feuille' peut être saisie dans la zone 'Paramètres avancés'",
        image: ''
      },
      es: {
        brief: 'Déficit de saturación',
        full: "Déficit de saturación (Déficit de presión de vapor, VPD) Este valor se calcula a partir de la humedad actual y la temperatura de la hoja. Si no hay ningún sensor de temperatura de la hoja conectado, la temperatura de la hoja se calcula a partir de la temperatura ambiente. Temperatura de la hoja = Temperatura ambiente - Diferencia de temperatura de la hoja. \r\nLa 'Diferencia de temperatura de la hoja' se puede introducir en el área 'Configuración avanzada'.",
        image: ''
      },
      it: {
        brief: 'deficit di saturazione',
        full: "Deficit di saturazione (Vapour Pressure Deficit, VPD) Questo valore viene calcolato in base all'umidità corrente e alla temperatura della foglia. Se non è collegato alcun sensore di temperatura fogliare, la temperatura fogliare viene calcolata in base alla temperatura ambiente. Temperatura fogliare' = 'Temperatura ambiente' - 'Differenza temperatura fogliare'. La 'Temperatura fogliare differenziale' può essere inserita nell'area 'Impostazioni avanzate'.",
        image: ''
      }
    },

    temp_leaf_current: {
      en: {
        brief: 'Current leaf temperature',
        full: 'Current leaf temperature \r\nThis value is measured by the leaf temperature sensor IRCube.',
        image: 'https://docs.growcontrol.de/GrowControl_IRCube_2_800x533.png'
      },
      de: {
        brief: 'Aktuelle Blatt-Temperatur',
        full: 'Aktuelle Blatt-Temperatur \r\nDieser Wert wird vom Blatt-Temperatur-Senor IRCube erfasst.',
        image: 'https://docs.growcontrol.de/GrowControl_IRCube_2_800x533.png'
      },
      fr: {
        brief: 'Température actuelle de la feuille',
        full: 'Température actuelle de la feuille \r\nCette valeur est enregistrée par le capteur de température de la feuille IRCube',
        image: 'https://docs.growcontrol.de/GrowControl_IRCube_2_800x533.png'
      },
      es: {
        brief: 'Temperatura actual de la hoja',
        full: 'Temperatura actual de la hoja Este valor es capturado por el sensor de temperatura de la hoja IRCube',
        image: 'https://docs.growcontrol.de/GrowControl_IRCube_2_800x533.png'
      },
      it: {
        brief: 'Temperatura attuale della foglia',
        full: 'Temperatura attuale della foglia Questo valore viene acquisito dal sensore di temperatura della foglia IRCube',
        image: 'https://docs.growcontrol.de/GrowControl_IRCube_2_800x533.png'
      }
    },

    co2: {
      en: {
        brief: 'Current CO₂ concentration',
        full: 'Current CO₂ concentration \r\nThis value is measured by the CO₂ senor CarbSense.',
        image: 'https://docs.growcontrol.de/CarbSense_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Aktuelle CO₂-Konzentration',
        full: 'Aktuelle CO₂-Konzentration \r\nDieser Wert wird vom CO₂-Senor CarbSense erfasst.',
        image: 'https://docs.growcontrol.de/CarbSense_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Concentration actuelle de CO₂',
        full: 'Concentration actuelle de CO₂ \r\nCette valeur est enregistrée par le senseur CarbSense de CO₂',
        image: 'https://docs.growcontrol.de/CarbSense_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Concentración actual de CO₂',
        full: 'Concentración actual de CO₂ \r\nEste valor lo registra el CO₂-Senor CarbSense',
        image: 'https://docs.growcontrol.de/CarbSense_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Concentrazione attuale di CO₂',
        full: 'Concentrazione attuale di CO₂ Questo valore viene rilevato dal sensore CO₂-Senor CarbSense',
        image: 'https://docs.growcontrol.de/CarbSense_1_GrowControl_800x.png'
      }
    },

    neg_pressure: {
      en: {
        brief: 'Current negative pressure',
        full: 'Current negative pressure \r\nThis value is measured by the PresSense vacuum sensor.',
        image: 'https://docs.growcontrol.de/GrowControl_PresSense_1_800x533.png'
      },
      de: {
        brief: 'Aktueller Unterdruck',
        full: 'Aktueller Unterdruck \r\nDieser Wert wird vom Unterdrucksensor PresSense erfasst.',
        image: 'https://docs.growcontrol.de/GrowControl_PresSense_1_800x533.png'
      },
      fr: {
        brief: 'Dépression actuelle',
        full: 'Dépression actuelle \r\nCette valeur est enregistrée par le capteur de dépression PresSense',
        image: 'https://docs.growcontrol.de/GrowControl_PresSense_1_800x533.png'
      },
      es: {
        brief: 'Presión negativa actual',
        full: 'Vacío actual \r\nEste valor es detectado por el sensor de vacío PresSense',
        image: 'https://docs.growcontrol.de/GrowControl_PresSense_1_800x533.png'
      },
      it: {
        brief: 'Vuoto attuale',
        full: 'Vuoto attuale Questo valore è rilevato dal sensore di vuoto PresSense',
        image: 'https://docs.growcontrol.de/GrowControl_PresSense_1_800x533.png'
      }
    },

    temp_heating_mat: {
      en: {
        brief: 'Current temperature heating mat',
        full: "Current temperature of the heating mat \r\nThis value is measured by the sensor 'Temperature sensor for heaters'. \r\nThe 'Heating mat' function can also be used for other temperature controls. E.g. to heat a water tank and control the temperature.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Aktuelle Temperatur der Heizmatte',
        full: "Aktuelle Temperatur der Heizmatte \r\nDieser Wert wird vom Sensor 'Temperatursensor für Heizgeräte' erfasst. \r\nDie Funktion 'Heizmatte' kann auch für weitere Temperaturregelungen verwendet werden. Z.B. um einen Wassertank zu beheizen und die Temperatur zu regeln.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        full: "Température actuelle du tapis chauffant \r\nCette valeur est relevée par le capteur 'Capteur de température pour appareils de chauffage'. \r\nLa fonction 'Tapis chauffant' peut également être utilisée pour d'autres régulations de température. Par exemple, pour chauffer un réservoir d'eau et réguler la température",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Temperatura actual de la alfombrilla de calefacción',
        full: "Temperatura actual de la alfombrilla calefactora \r\nEste valor lo registra el sensor 'Sensor de temperatura para calefactores'. La función 'Placa calefactora' también puede utilizarse para otros controles de temperatura. Por ejemplo, para calentar un depósito de agua y controlar la temperatura",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Temperatura attuale del tappetino di riscaldamento',
        full: "Questo valore viene registrato dal sensore 'Sensore di temperatura per riscaldatori'. La funzione 'Tappetino riscaldante' può essere utilizzata anche per altri controlli di temperatura. Ad esempio, per riscaldare un serbatoio d'acqua e controllarne la temperatura",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },

    humidity_max: {
      en: {
        brief: 'Maximum humidity',
        full: 'Maximum humidity that has occurred since the last reset. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Maximale Luftfeuchte',
        full: 'Maximale Luftfeuchte, die seit dem letzten Reset aufgetreten ist. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: "Humidité maximale de l'air",
        full: "Humidité maximale qui s'est produite depuis la dernière réinitialisation. (capteur RH/T)",
        image: ''
      },
      es: {
        brief: 'Humedad máxima',
        full: 'Humedad máxima que se ha producido desde el último reinicio. (Sensor RH/T)',
        image: ''
      },
      it: {
        brief: '',
        full: '',
        image: ''
      }
    },

    humidity_min: {
      en: {
        brief: 'Minimum humidity',
        full: 'Minimum humidity that has occurred since the last reset. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Minimale Luftfeuchte',
        full: 'Minimale Luftfeuchte, die seit dem letzten Reset aufgetreten ist. (Sensor RH/T).',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: "Humidité minimale de l'air",
        full: "Humidité minimale qui s'est produite depuis la dernière réinitialisation. (capteur RH/T)",
        image: ''
      },
      es: {
        brief: 'Humedad mínima',
        full: 'Humedad mínima que se ha producido desde el último reinicio. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: '',
        full: '',
        image: ''
      }
    },

    temp_max: {
      en: {
        brief: 'Maximum room temperature',
        full: 'Maximum room temperature that has occurred since the last reset. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Maximale Raumtemperatur',
        full: 'Maximale Raumtemperatur, die seit dem letzten Reset aufgetreten ist. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'température ambiante maximale',
        full: 'Température ambiante maximale survenue depuis la dernière réinitialisation. (capteur RH/T)',
        image: ''
      },
      es: {
        brief: 'Temperatura ambiente máxima',
        full: 'Temperatura ambiente máxima que se ha producido desde el último reinicio. (Sensor RH/T)',
        image: ''
      },
      it: {
        brief: '',
        full: '',
        image: ''
      }
    },

    temp_min: {
      en: {
        brief: 'Minimum room temperature',
        full: 'Minimum room temperature that has occurred since the last reset. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Minimale Raumtemperatur',
        full: 'Minimale Raumtemperatur, die seit dem letzten Reset aufgetreten ist. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'température ambiante minimale',
        full: "Température ambiante minimale qui s'est produite depuis la dernière réinitialisation. (capteur RH/T)",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Temperatura ambiente mínima',
        full: 'Temperatura ambiente mínima que se ha producido desde el último reinicio. (Sensor RH/T)',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: '',
        full: '',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },

    humidity_intake: {
      en: {
        brief: 'Humidity Intake',
        full: 'Humidity Intake \r\nThe controller uses this value to decide whether dehumidification via the exhaust air is possible. If neither cooling nor dehumidification is possible due to measured intake air values, the ventilation is reduced to the minimum values set in the Ventilation Settings section. \r\nAs soon as the measured intake air values again show a sufficient difference to the measured values in the room, the ventilation is started up again. \r\nIn addition, a recurring ramp-up of the ventilation can be set in the Advanced Settings section. \r\n\r\nThis value is measured by the intake humitity and temperature sensor.',
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      de: {
        brief: 'Luftfeuchte Zuluft',
        full: 'Luftfeuchtigkeit Zuluft \r\nDer Controller verwendet diesen Wert, um zu entscheiden, ob eine Entfeuchtung über die Abluft möglich ist. Wenn aufgrund der gemessenen Zuluftwerte weder eine Kühlung noch eine Entfeuchtung möglich ist, wird die Lüftung auf die im Bereich Lüftungseinstellungen eingestellten Mindestwerte reduziert. \r\nSobald die gemessenen Zuluftwerte wieder eine ausreichende Differenz zu den Messwerten im Raum aufweisen, wird die Lüftung wieder hochgefahren. \r\nZusätzlich kann im Bereich Erweiterte Einstellungen ein wiederkehrendes Hochfahren der Lüftung eingestellt werden. \r\n\r\nDieser Wert wird durch den Luftfeuchtigkeits- und Temperatursensor der Zuluft gemessen.',
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      fr: {
        brief: "Humidité de l'air d'admission",
        full: "Humidité de l'air d'admission \r\nLe contrôleur utilise cette valeur pour décider si une déshumidification par l'air d'évacuation est possible. Si les valeurs mesurées de l'air entrant ne permettent ni le refroidissement ni la déshumidification, la ventilation est réduite aux valeurs minimales définies dans la zone Réglages de la ventilation. \r\nDès que les valeurs d'air soufflé mesurées présentent à nouveau une différence suffisante par rapport aux valeurs mesurées dans la pièce, la ventilation est à nouveau augmentée. \r\nDe plus, il est possible de régler une remontée récurrente de la ventilation dans la zone Paramètres avancés. \r\n\nCette valeur est mesurée par le capteur d'humidité et de température de l'air entrant",
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      es: {
        brief: 'Humedad del aire de impulsión',
        full: 'El regulador utiliza este valor para decidir si es posible la deshumidificación a través del aire de extracción. Si no es posible ni la refrigeración ni la deshumidificación en base a los valores medidos del aire de impulsión, la ventilación se reduce a los valores mínimos establecidos en la sección de ajustes de ventilación. En cuanto los valores medidos del aire de impulsión vuelven a mostrar una diferencia suficiente respecto a los valores medidos en el local, la ventilación se pone en marcha de nuevo. Además, en el área Ajustes avanzados se puede configurar una puesta en marcha recurrente de la ventilación. Este valor lo mide el sensor de humedad y temperatura del aire de impulsión',
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      it: {
        brief: "Umidità dell'aria di mandata",
        full: "Umidità dell'aria di mandata \r\nIl regolatore utilizza questo valore per decidere se è possibile la deumidificazione attraverso l'aria estratta. Se i valori misurati dell'aria di mandata non consentono né il raffreddamento né la deumidificazione, la ventilazione viene ridotta ai valori minimi impostati nella sezione Impostazioni di ventilazione. Non appena i valori misurati dell'aria di mandata mostrano nuovamente una differenza sufficiente rispetto ai valori misurati nel locale, la ventilazione viene riavviata. Inoltre, nell'area delle impostazioni avanzate è possibile impostare un avvio ricorrente della ventilazione. \r\nQuesto valore viene misurato dal sensore di umidità e temperatura dell'aria di mandata.",
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      }
    },

    temp_intake: {
      en: {
        brief: 'Temperature Intake',
        full: 'Temperature Intake \r\nThe controller uses this value to decide whether cooling via the exhaust air is possible. If neither cooling nor dehumidification is possible due to measured intake air values, the ventilation is reduced to the minimum values set in the Ventilation Settings section. \r\nAs soon as the measured intake air values again show a sufficient difference to the measured values in the room, the ventilation is started up again. \r\nIn addition, a recurring ramp-up of the ventilation can be set in the Advanced Settings section. \r\n\r\nThis value is measured by the intake humitity and temperature sensor.',
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      de: {
        brief: 'Temperatur Zuluft',
        full: 'Temperatur Zuluft \r\nDer Controller verwendet diesen Wert, um zu entscheiden, ob eine Kühlung über die Abluft möglich ist. Wenn aufgrund der gemessenen Zuluftwerte weder eine Kühlung noch eine Entfeuchtung möglich ist, wird die Lüftung auf die im Bereich Lüftungseinstellungen eingestellten Mindestwerte reduziert. \r\nSobald die gemessenen Zuluftwerte wieder eine ausreichende Differenz zu den Messwerten im Raum aufweisen, wird die Lüftung wieder hochgefahren. \r\nZusätzlich kann im Bereich Erweiterte Einstellungen ein wiederkehrendes Hochfahren der Lüftung eingestellt werden. \r\n\r\nDieser Wert wird durch den Luftfeuchtigkeits- und Temperatursensor der Zuluft gemessen.',
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      fr: {
        brief: "Température de l'Intraction",
        full: "Température de l'Intraction \r\nLe contrôleur utilise cette valeur pour décider si un refroidissement par l'air d'évacuation est possible. Si les valeurs mesurées de l'air entrant ne permettent ni le refroidissement ni la déshumidification, la ventilation est réduite aux valeurs minimales définies dans la zone Réglages de la ventilation. \r\nDès que les valeurs d'air soufflé mesurées présentent à nouveau une différence suffisante par rapport aux valeurs mesurées dans la pièce, la ventilation est à nouveau augmentée. \r\nDe plus, il est possible de régler une remontée récurrente de la ventilation dans la zone Paramètres avancés. \r\n\r\nCette valeur est mesurée par le capteur d'humidité et de température de l'air entrant.",
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      es: {
        brief: 'Temperatura del aire de impulsión',
        full: 'Supply air humidity \r\nEl regulador utiliza este valor para decidir si es posible la refrigeración a través del aire de extracción. Si no es posible ni la refrigeración ni la deshumidificación en base a los valores medidos del aire de impulsión, la ventilación se reduce a los valores mínimos establecidos en la sección de ajustes de ventilación. En cuanto los valores medidos del aire de impulsión vuelven a mostrar una diferencia suficiente respecto a los valores medidos en el local, la ventilación se pone en marcha de nuevo. Además, en el área Ajustes avanzados se puede configurar una puesta en marcha recurrente de la ventilación. Este valor lo mide el sensor de humedad y temperatura del aire de impulsión',
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      },
      it: {
        brief: "Temperatura dell'aria di mandata",
        full: "Temperatura dell'aria di mandata \r\nIl regolatore utilizza questo valore per decidere se è possibile il raffreddamento attraverso l'aria di estrazione. Se i valori misurati dell'aria di mandata non consentono né il raffreddamento né la deumidificazione, la ventilazione viene ridotta ai valori minimi impostati nella sezione Impostazioni di ventilazione. Non appena i valori misurati dell'aria di mandata mostrano nuovamente una differenza sufficiente rispetto ai valori misurati nel locale, la ventilazione viene riavviata. Inoltre, nell'area delle impostazioni avanzate è possibile impostare un avvio ricorrente della ventilazione. \r\nQuesto valore viene misurato dal sensore di umidità e temperatura dell'aria di mandata.",
        image: 'https://docs.growcontrol.de/sensRHT_intake_800x.png'
      }
    },

    info_interval_timer: {
      en: {
        brief: '',
        full: 'Remaining time until the next switching of the interval timer. It is also displayed whether it will be switched on or off next.',
        image: ''
      },
      de: {
        brief: '',
        full: 'Verbleibende Zeit bis zum nächsten Schalten des Intervall-Timers. \r\nAußerdem wird angezeigt, ob als nächstes ein- oder ausgeschaltet wird.',
        image: ''
      },
      fr: {
        brief: '',
        full: "Temps restant jusqu'à la prochaine commutation de la minuterie d'intervalle. \r\nEn dehors de cela, il est indiqué si la prochaine étape est l'activation ou la désactivation",
        image: ''
      },
      es: {
        brief: '',
        full: 'Tiempo restante hasta la próxima conmutación del temporizador de intervalos. También se muestra si se encenderá o apagará a continuación',
        image: ''
      },
      it: {
        brief: '',
        full: 'Tempo rimanente fino alla prossima accensione del timer a intervalli. \r\nViene visualizzata la prossima accensione o spegnimento.',
        image: ''
      }
    },

    info_interval_timer_2: {
      en: {
        brief: '',
        full: 'Remaining time until the next switching of the interval timer. It is also displayed whether it will be switched on or off next.',
        image: ''
      },
      de: {
        brief: '',
        full: 'Verbleibende Zeit bis zum nächsten Schalten des Intervall-Timers. \r\nAußerdem wird angezeigt, ob als nächstes ein- oder ausgeschaltet wird.',
        image: ''
      },
      fr: {
        brief: '',
        full: "Temps restant jusqu'à la prochaine commutation de la minuterie d'intervalle. \r\nEn dehors de cela, il est indiqué si la prochaine étape est l'activation ou la désactivation",
        image: ''
      },
      es: {
        brief: '',
        full: 'Tiempo restante hasta la próxima conmutación del temporizador de intervalos. También se muestra si se encenderá o apagará a continuación',
        image: ''
      },
      it: {
        brief: '',
        full: 'Tempo rimanente fino alla prossima accensione del timer a intervalli. \r\nViene visualizzata la prossima accensione o spegnimento.',
        image: ''
      }
    },

    time: {
      en: {
        brief: 'Current time',
        full: 'Current controller time',
        image: ''
      },
      de: {
        brief: 'Aktuelle Uhrzeit',
        full: 'Aktuelle Uhrzeit des Controllers',
        image: ''
      },
      fr: {
        brief: 'Heure actuelle',
        full: 'Heure actuelle du contrôleur',
        image: ''
      },
      es: {
        brief: 'Hora actual',
        full: 'Hora actual del controlador',
        image: ''
      },
      it: {
        brief: 'Ora del controllore',
        full: 'Ora attuale del controllore',
        image: ''
      }
    },

    date: {
      en: {
        brief: 'Current date',
        full: 'Current controller date',
        image: ''
      },
      de: {
        brief: 'Atuelles datum',
        full: 'Atuelles Datum des Controllers',
        image: ''
      },
      fr: {
        brief: 'Date réelle',
        full: 'Date réelle du contrôleur',
        image: ''
      },
      es: {
        brief: 'Fecha actual',
        full: 'Fecha actual del controlador',
        image: ''
      },
      it: {
        brief: 'Data attuale',
        full: 'Data attuale del controllore',
        image: ''
      }
    },

    phase: {
      en: {
        brief: 'Current phase (Day/Night/CO₂)',
        full: "Current phase (Day/Night/CO₂) \r\nHere the mode is displayed in which the controller is currently working. (Day/Night/CO₂) \r\nWhen day or night is, depends on the current time and on the settings 'Start day'/'End day' in the 'Time' section.",
        image: ''
      },
      de: {
        brief: 'Aktuelle Phase (Tag/Nacht/CO₂)',
        full: "Aktuelle Phase (Tag/Nacht/CO₂) \r\nHier wird angezeigt, in welchem Modus der Controller aktuell arbeitet. (Tag/Nacht/CO₂) \r\nWann Tag bzw. Nacht ist, hängt von der aktuellen Uhrzeit und von den Einstellungen 'Beginn Tag'/'Ende Tag' im Bereich 'Zeit' ab.",
        image: ''
      },
      fr: {
        brief: 'Phase actuelle (jour/nuit/CO₂)',
        full: "Phase actuelle (jour/nuit/CO₂) \r\nIl est indiqué ici dans quel mode le contrôleur fonctionne actuellement. (Jour/Nuit/CO₂) \r\nLa date du jour ou de la nuit dépend de l'heure actuelle et des paramètres 'Début jour'/'Fin jour' dans la section 'Heure'.",
        image: ''
      },
      es: {
        brief: 'Fase actual (día/noche/CO₂)',
        full: "Fase actual (día/noche/CO₂) \r\nEsto muestra en qué modo está trabajando actualmente el controlador. (Día/Noche/CO₂) \r\nCuando es de día o de noche depende de la hora actual y de los ajustes 'Inicio Día'/'Fin Día' en la sección 'Hora'.",
        image: ''
      },
      it: {
        brief: 'Fase attuale (giorno/notte/CO₂)',
        full: "Fase attuale (giorno/notte/CO₂) \r\nQuesto indica in quale modalità sta lavorando il regolatore. (Giorno/Notte/CO₂) \r\nQuando è giorno o notte dipende dall'ora corrente e dalle impostazioni 'Inizio giorno'/'Fine giorno' nell'area 'Ora'.",
        image: ''
      }
    },

    temp_heater_setpoint_info: {
      en: {
        brief: 'Current set temperature for heating',
        full: 'Current set temperature for heating \r\nThe set temperature for heating that the controller is currently using. \r\nWhen switching between day and night, the set value for heating is slowly changed along a ramp.',
        image: ''
      },
      de: {
        brief: 'Aktuelle Soll-Temperatur für die Heizung',
        full: 'Aktuelle Soll-Temperatur für die Heizung \r\nDie Solltemperatur für die Heizung, mit der der Controller aktuell arbeitet. \r\nBei einem Wechsel zwischen Tag und Nacht wird der Sollwert für die Heizung langsam entlang einer Rampe verändert.',
        image: ''
      },
      fr: {
        brief: 'Température de consigne actuelle pour le chauffage',
        full: "Température de consigne actuelle pour le chauffage \r\nLa température de consigne pour le chauffage que le contrôleur utilise actuellement. \r\nLors du passage entre le jour et la nuit, la valeur de consigne pour le chauffage est lentement modifiée le long d'une rampe.",
        image: ''
      },
      es: {
        brief: 'Temperatura de consigna actual para la calefacción',
        full: 'Temperatura de consigna actual para la calefacción \r\nLa temperatura de consigna para la calefacción que el controlador está utilizando actualmente. \r\nCuando se cambia entre día y noche, el valor establecido para la calefacción se cambia lentamente a lo largo de una rampa.',
        image: ''
      },
      it: {
        brief: 'Temperatura impostata attuale per il riscaldamento',
        full: 'Temperatura impostata attuale per il riscaldamento \r\nLa temperatura impostata per il riscaldamento che il controller sta utilizzando attualmente. \r\nDurante il passaggio tra il giorno e la notte, il valore impostato per il riscaldamento viene modificato lentamente lungo una rampa.',
        image: ''
      }
    },

    cur_day: {
      en: {
        brief: 'Current day/week in plant cycle',
        full: '',
        image: ''
      },
      de: {
        brief: 'Aktueller Tag/Woche im Pflanzen-Zyklus',
        full: '',
        image: ''
      },
      fr: {
        brief: 'Jour/semaine actuel(le) dans le cycle des plantes',
        full: '',
        image: ''
      },
      es: {
        brief: 'Día/semana actual en el ciclo de la planta',
        full: '',
        image: ''
      },
      it: {
        brief: "Giorno/settimana corrente nel ciclo dell'impianto",
        full: '',
        image: ''
      }
    },

    info_temp_sp: {
      en: {
        brief: 'Current setpoint for room temperature',
        full: 'Current setpoint for room temperature \r\nThe setpoint temperature for the room air with which the controller is currently operating. \r\nWhen changing between day and night, the setpoint for the room temperature is changed slowly along a ramp.',
        image: ''
      },
      de: {
        brief: 'Aktuelle Soll-Temperatur Raum',
        full: 'Aktuelle Soll-Temperatur Raum \r\nDie Solltemperatur für die Raumluft, mit der der Controller aktuell arbeitet. \r\nBei einem Wechsel zwischen Tag und Nacht wird der Sollwert für die Raumtemperatur langsam entlang einer Rampe verändert.',
        image: ''
      },
      fr: {
        brief: 'Température de consigne actuelle de la pièce',
        full: "Température de consigne actuelle de la pièce \r\nLa température de consigne pour l'air ambiant avec laquelle le contrôleur fonctionne actuellement. \r\nLors d'un changement entre le jour et la nuit, la valeur de consigne pour la température ambiante est modifiée lentement le long d'une rampe",
        image: ''
      },
      es: {
        brief: 'Temperatura de consigna actual ambiente',
        full: 'Temperatura de consigna actual ambiente \r\nLa temperatura de consigna para el aire ambiente con la que el controlador está funcionando actualmente. \rAl cambiar entre día y noche, el valor de consigna para la temperatura ambiente se modifica lentamente a lo largo de una rampa',
        image: ''
      },
      it: {
        brief: 'Temperatura nominale attuale ambiente',
        full: "Temperatura nominale attuale ambiente \r\nLa temperatura nominale dell'aria ambiente con cui il regolatore sta attualmente operando. \r\nQuando si passa dal giorno alla notte, il setpoint della temperatura ambiente viene modificato lentamente lungo una rampa.",
        image: ''
      }
    },

    info_humidity_sp: {
      en: {
        brief: 'Current humidity setpoint',
        full: 'Aktuelle Soll-Luftfeuchte \r\nWhen VPD control is active, the setpoint for humidity is calculated by the controller.',
        image: ''
      },
      de: {
        brief: 'Aktuelle Soll-Luftfeuchte',
        full: 'Aktuelle Soll-Luftfeuchte \r\nWenn die VPD-Regelung aktiv ist, wird der Sollwert für die Luftfeuchte vom Controller berechnet.',
        image: ''
      },
      fr: {
        brief: "Consigne d'humidité actuelle",
        full: "Consigne d'humidité actuelle\rLorsque la régulation VPD est active, la valeur de consigne pour l'humidité de l'air est calculée par le contrôleur",
        image: ''
      },
      es: {
        brief: 'Humedad de consigna actual',
        full: 'Humedad de consigna actual \r\nCuando el control VPD está activo, la consigna de humedad es calculada por el controlador',
        image: ''
      },
      it: {
        brief: "Valore nominale attuale dell'umidità dell'aria",
        full: "Se la regolazione VPD è attiva, il setpoint dell'umidità dell'aria viene calcolato dal regolatore.",
        image: ''
      }
    },

    info_lights: {
      en: {
        brief: 'Info lights 0-10/1-10V',
        full: 'Info lights 0-10/1-10V \r\rThe value shows the current lights control via the 0-10/1-10V output.',
        image: ''
      },
      de: {
        brief: 'Info Beleuchtung 0-10/1-10V',
        full: 'Info Beleuchtung 0-10/1-10V \r\nDer Wert zeigt die aktuelle Leuchten-Ansteuerung über den 0-10/1-10V-Ausgang.',
        image: ''
      },
      fr: {
        brief: 'Info éclairage 0-10/1-10V',
        full: 'Info éclairage 0-10/1-10V\nLa valeur indique la commande actuelle du luminaire via la sortie 0-10/1-10V',
        image: ''
      },
      es: {
        brief: 'Info iluminación 0-10/1-10V',
        full: 'Info iluminación 0-10/1-10V \r\nEl valor muestra el control actual de la luminaria a través de la salida 0-10/1-10V',
        image: ''
      },
      it: {
        brief: 'Info illuminazione 0-10/1-10V',
        full: "Info illuminazione 0-10/1-10V \r\nIl valore indica il controllo attuale dell'apparecchio tramite l'uscita 0-10/1-10V.",
        image: ''
      }
    },

    ec_fans_intake: {
      en: {
        brief: 'Info intake EC fans',
        full: "Info intake EC fans \r\nThe percentage number represents the output signal transmitted to the fan, the 'rpm number' represents the actual measured speed. \r\nThe measured speed is only displayed if the EC fan provides a corresponding signal.  This value is for information only and has no influence on the function. \r\n\r\nrpm = revolutions per minute, revolutions per minute",
        image: ''
      },
      de: {
        brief: 'Info Zuluft EC',
        full: "Info Zuluft EC \r\nMomentane Drehzahl der EC-Zuluftventilatoren. Die  Prozentzahl  stellt  die  an  den  Ventilator übertragene Vorgabe dar, die 'rpm-Zahl' stellt  die  tatsächlich  gemessene  Drehzahl dar. \r\nDie gemessene Drehzahl wird nur dann angezeigt, wenn der verwendete EC-Ventilator ein entsprechendes Signal zur Verfügung stellt. Dieser Wert dient nur als Information und hat keinen Einfluss auf die Funktion. \r\nrpm  =  revolutions  per  minute,  Umdrehungen pro Minute",
        image: ''
      },
      fr: {
        brief: 'Info soufflage EC',
        full: "Info soufflage EC \r\nVitesse instantanée des ventilateurs de soufflage EC. Le pourcentage représente la consigne transmise au ventilateur, le 'nombre de tr/min' représente la vitesse réellement mesurée. \r\nLa vitesse mesurée n'est affichée que si le ventilateur EC utilisé met à disposition un signal correspondant. Cette valeur sert uniquement d'information et n'a aucune influence sur le fonctionnement. \r\nrpm = revolutions per minute, 'tours par minute'",
        image: ''
      },
      es: {
        brief: 'Info suministro aire EC',
        full: "Info suministro aire EC \r\nVelocidad momentánea de los ventiladores de suministro de aire EC. El porcentaje representa el valor por defecto transmitido al ventilador, el 'número de rpm' representa la velocidad real medida. La velocidad medida sólo se muestra si el ventilador EC utilizado proporciona una señal correspondiente. Este valor es meramente informativo y no influye en la función. \r\nrpm = revoluciones por minuto.",
        image: ''
      },
      it: {
        brief: 'Info aria di alimentazione EC',
        full: "Info aria di alimentazione EC \r\nVelocità momentanea dei ventilatori dell'aria di alimentazione EC. Il numero percentuale rappresenta l'impostazione predefinita trasmessa al ventilatore, il 'numero di giri' rappresenta la velocità effettiva misurata. La velocità misurata viene visualizzata solo se il ventilatore EC utilizzato fornisce un segnale corrispondente. Questo valore è solo informativo e non ha alcuna influenza sul funzionamento. \rpm = giri al minuto, giri al minuto",
        image: ''
      }
    },

    ec_fans_exhaust: {
      en: {
        brief: 'Info exhaust EC fans',
        full: "Info exhaust EC fans \r\nThe percentage number represents the output signal transmitted to the fan, the 'rpm number' represents the actual measured speed. \r\nThe measured speed is only displayed if the EC fan provides a corresponding signal.  This value is for information only and has no influence on the function. \r\n\r\nrpm = revolutions per minute, revolutions per minute",
        image: ''
      },
      de: {
        brief: 'Info Abluft EC',
        full: "Info Abluft EC \r\nMomentane Drehzahl der EC-Abluftventilatoren. Die  Prozentzahl  stellt  die  an  den  Ventilator übertragene Vorgabe dar, die 'rpm-Zahl' stellt  die  tatsächlich  gemessene  Drehzahl dar. \r\nDie gemessene Drehzahl wird nur dann angezeigt, wenn der verwendete EC-Ventilator ein entsprechendes Signal zur Verfügung stellt. Dieser Wert dient nur als Information und hat keinen Einfluss auf die Funktion. \r\nrpm  =  revolutions  per  minute,  Umdrehungen pro Minute",
        image: ''
      },
      fr: {
        brief: 'Info air extrait EC',
        full: "Info air extrait EC \r\nVitesse instantanée des ventilateurs d'extraction EC. Le pourcentage représente la consigne transmise au ventilateur, le 'nombre de tr/min' représente la vitesse réellement mesurée. \r\nLa vitesse mesurée n'est affichée que si le ventilateur EC utilisé met à disposition un signal correspondant. Cette valeur sert uniquement d'information et n'a aucune influence sur le fonctionnement. \r\nrpm = revolutions per minute, 'tours par minute'",
        image: ''
      },
      es: {
        brief: 'Info aire de extracción EC',
        full: "Info extraer aire EC \r\nVelocidad momentánea de los extractores de aire EC. El porcentaje representa el valor por defecto transmitido al ventilador, el 'número de rpm' representa la velocidad real medida. La velocidad medida sólo se muestra si el ventilador EC utilizado proporciona una señal correspondiente. Este valor es meramente informativo y no influye en la función. \r\nrpm = revoluciones por minuto",
        image: ''
      },
      it: {
        brief: 'Info aria estratta EC',
        full: "Info aria estratta EC \r\nMomento di velocità dei ventilatori dell'aria estratta EC. Il numero percentuale rappresenta l'impostazione predefinita trasmessa al ventilatore, il 'numero di giri' rappresenta la velocità effettiva misurata. \r\nLa velocità misurata viene visualizzata solo se il ventilatore EC utilizzato fornisce un segnale corrispondente. Questo valore è solo informativo e non ha alcuna influenza sul funzionamento. \r\nrpm = giri al minuto, giri al minuto",
        image: ''
      }
    },

    // climate
    humidity_sp_day: {
      en: {
        brief: 'Humidity day setpoint',
        full: 'Humidity day setpoint \r\nThis setting is disabled if VPD control is enabled.',
        image: ''
      },
      de: {
        brief: 'Luftfeuchte Tag Sollwert',
        full: 'Sollwert Luftfeuchte Tag \r\nDie Einstellung ist deaktiviert, wenn die VPD-Regelung aktiviert ist.',
        image: ''
      },
      fr: {
        brief: "Consigne d'humidité de jour",
        full: "Valeur de consigne humidité de l'air jour\nLe réglage est désactivé si la régulation VPD est activée",
        image: ''
      },
      es: {
        brief: 'Valor de consigna día humedad',
        full: 'Consigna día humedad \r\nEl ajuste se desactiva si se activa el control VPD',
        image: ''
      },
      it: {
        brief: "Valore nominale dell'umidità dell'aria giorno",
        full: "Valore nominale dell'umidità dell'aria giorno \n\rL'impostazione è disattivata se è attivata la regolazione VPD.",
        image: ''
      }
    },

    humidity_sp_night: {
      en: {
        brief: 'Humidity night setpoint',
        full: 'Humidity night setpoint \r\nThis setting is disabled if VPD control is enabled.',
        image: ''
      },
      de: {
        brief: 'Luftfeuchte Nacht Sollwert',
        full: 'Sollwert Luftfeuchte Nacht \r\nDie Einstellung ist deaktiviert, wenn die VPD-Regelung aktiviert ist.',
        image: ''
      },
      fr: {
        brief: "Consigne d'humidité de nuit",
        full: "Valeur de consigne humidité de l'air nuit\nLe réglage est désactivé si la régulation VPD est activée",
        image: ''
      },
      es: {
        brief: 'Consigna nocturna de humedad',
        full: 'Consigna nocturna de humedad El ajuste se desactiva si se activa el control VPD',
        image: ''
      },
      it: {
        brief: "Valore nominale umidità dell'aria notte",
        full: "Valore nominale umidità dell'aria notte \r\nL'impostazione è disattivata se è attivata la regolazione VPD.",
        image: ''
      }
    },

    temp_sp_day: {
      en: {
        brief: 'Room temperature day setpoint',
        full: 'Room temperature day setpoint',
        image: ''
      },
      de: {
        brief: 'Raum-Temperatur Tag Sollwert',
        full: 'Sollwert Raum-Temperatur Tag',
        image: ''
      },
      fr: {
        brief: 'température ambiante jour',
        full: 'Valeur de consigne température ambiante jour',
        image: ''
      },
      es: {
        brief: 'Consigna diaria de temperatura ambiente',
        full: 'Punto de ajuste diario de la temperatura ambiente',
        image: ''
      },
      it: {
        brief: 'Temperatura ambiente impostata per il giorno',
        full: 'Temperatura ambiente impostata per il giorno',
        image: ''
      }
    },

    temp_sp_night: {
      en: {
        brief: 'Room temperature night setpoint',
        full: 'Room temperature night setpoint',
        image: ''
      },
      de: {
        brief: 'Raum-Temperatur Nacht Sollwert',
        full: 'Sollwert Raum-Temperatur Nacht',
        image: ''
      },
      fr: {
        brief: 'Température de la pièce la nuit',
        full: 'Valeur de consigne température ambiante nuit',
        image: ''
      },
      es: {
        brief: 'Punto de ajuste nocturno de la temperatura ambiente',
        full: 'Punto de ajuste nocturno de la temperatura ambiente',
        image: ''
      },
      it: {
        brief: 'Temperatura ambiente impostata per la notte',
        full: 'Temperatura ambiente impostata per la notte',
        image: ''
      }
    },

    vpd_sp: {
      en: {
        brief: 'VPD Setpoint Day',
        full: 'VPD Setpoint (Vapour pressure deficit)',
        image: ''
      },
      de: {
        brief: 'Sättigungsdefizit (VPD) Sollwert Tag',
        full: 'Sollwert Sättigungsdefizit (VPD) \r\n(VPD = Vapour Pressure Deficit)',
        image: ''
      },
      fr: {
        brief: 'Valeur de consigne du déficit de saturation (VPD) your',
        full: 'Valeur de consigne du déficit de saturation (VPD) \r\n(VPD = Vapour Pressure Deficit)',
        image: ''
      },
      es: {
        brief: 'Consigna de déficit de saturación (VPD) dia',
        full: 'Consigna de déficit de saturación (VPD)(VPD = Déficit de presión de vapor)',
        image: ''
      },
      it: {
        brief: 'Punto di regolazione del deficit di saturazione (VPD) gioro',
        full: 'Punto di regolazione del deficit di saturazione (VPD) \r\n(VPD = deficit di pressione di vapore)',
        image: ''
      }
    },
    vpd_sp_night: {
      en: {
        brief: 'VPD Setpoint Night',
        full: 'VPD Setpoint Night (Vapour pressure deficit)',
        image: ''
      },
      de: {
        brief: 'Sättigungsdefizit (VPD) Sollwert Nacht',
        full: 'Sollwert Sättigungsdefizit (VPD) Nacht \r\n(VPD = Vapour Pressure Deficit)',
        image: ''
      },
      fr: {
        brief: 'Valeur de consigne du déficit de saturation (VPD) nuit',
        full: 'Valeur de consigne du déficit de saturation (VPD) nuit \r\n(VPD = Vapour Pressure Deficit)',
        image: ''
      },
      es: {
        brief: 'Déficit de saturación (VPD) Consigna nocturna',
        full: 'Déficit de saturación (VPD) Consigna nocturna \r\n(VPD = Vapour Pressure Deficit)',
        image: ''
      },
      it: {
        brief: 'Punto di regolazione del deficit di saturazione (VPD) notte',
        full: 'Punto di regolazione del deficit di saturazione (VPD) notte \r\n(VPD = Vapour Pressure Deficit)',
        image: ''
      }
    },

    vpd_calc_enable: {
      en: {
        brief: 'VPD control on/off',
        full: "VPD control on/off \r\nIf VPD control is active, the controller calculates the required air humidity to achieve the set VPD value. \r\nThe 'Setpoint humidity day/night' setting is deactivated when VPD control is active.",
        image: ''
      },
      de: {
        brief: 'VPD-Regelung ein/aus',
        full: "VPD-Regelung ein/aus \r\nWenn die VPD-Regelung aktiv ist, berechnet der Controller die erforderliche Luftfeuchte, um den eingestellten VPD-Wert zu erreichen. \r\nDie Einstellung 'Sollwert Luftfeuchte Tag/Nacht' ist deaktiviert, wenn die VPD-Regelung aktiviert ist.",
        image: ''
      },
      fr: {
        brief: 'Régulation VPD on/off',
        full: "Régulation VPD on/off \r\nLorsque la régulation VPD est activée, le contrôleur calcule l'humidité de l'air nécessaire pour atteindre la valeur VPD réglée. \r\nLe réglage 'Valeur de consigne de l'humidité jour/nuit' est désactivé lorsque la régulation VPD est activée.",
        image: ''
      },
      es: {
        brief: 'Control VPD activado/desactivado',
        full: "VPD control on/off \r\nCuando el control VPD está activado, el regulador calcula la humedad necesaria para alcanzar el valor VPD ajustado. \nEl ajuste 'Humedad de consigna día/noche' se desactiva cuando el control VPD está activado.",
        image: ''
      },
      it: {
        brief: 'Controllo VPD attivo/disattivo',
        full: "Controllo VPD attivo/disattivo \r\nQuando la regolazione VPD è attiva, il regolatore calcola l'umidità necessaria per raggiungere il valore VPD impostato. \r\nL'impostazione 'Umidità nominale giorno/notte' è disattivata quando la regolazione VPD è attiva.",
        image: ''
      }
    },
    preheat_night_day: {
      en: {
        brief: 'Preheat Room in The Morning',
        full: 'Preheat Room in The Morning \r\nThis value can be used to determine how long before the lighting is switched on the setpoint temperatures are raised to the values during the day. \r\n\r\nPreheating has the following advantages: \r\nIt avoids that buds that are still damp are exposed to the lighting. \r\nAt the beginning of the lighting, the plants are already at a temperature that enables optimal photosynthesis at optimal VPD values.',
        image: 'https://docs.growcontrol.de/preheat_room_EN_x820.png'
      },
      de: {
        brief: 'Raum vorwärmen am Morgen',
        full: 'Raum vorwärmen am Morgen \r\nMit diesem Wert kann bestimmt werden, wie lange vor dem Einschalten der Beleuchtung die Solltemperaturen auf die Werte am Tage angehoben werden. \r\n\r\n- Das Vorwärmen hat folgende Vorteile: \r\nEs wird vermieden, dass noch feuchte Blüten der Beleuchtung ausgesetzt werden. \r\n- Die Pflanzen sind schon zum Beginn der Belichtung auf einer Temperatur, die optimale Photosythese bei optimalen VPD-Werten ermöglicht.',
        image: 'https://docs.growcontrol.de/preheat_room_DE_x820.png'
      },
      fr: {
        brief: 'Préchauffage de la pièce le matin',
        full: "Préchauffage de la pièce le matin \r\nCette valeur permet de déterminer combien de temps avant l'allumage de l'éclairage les températures de consigne sont augmentées pour atteindre les valeurs de la journée. \r\n\n- Le préchauffage a les avantages suivants : \r\nOn évite d'exposer à l'éclairage des fleurs encore humides. \r\n- Les plantes sont déjà à une température qui permet une photosynthèse optimale avec des valeurs VPD optimales au début de l'exposition",
        image: 'https://docs.growcontrol.de/preheat_room_DE_x820.png'
      },
      es: {
        brief: 'Precalentar habitación por la mañana',
        full: 'Este valor puede utilizarse para determinar cuánto tiempo antes de encender la iluminación las temperaturas de consigna se elevan a los valores durante el día. El precalentamiento tiene las siguientes ventajas: \r\n- Se evita exponer las flores aún húmedas a la iluminación. \r\n- Las plantas ya están a una temperatura al principio de la exposición que permite una fotosíntesis óptima a valores óptimos de VPD',
        image: 'https://docs.growcontrol.de/preheat_room_DE_x820.png'
      },
      it: {
        brief: "Preriscaldare l'ambiente al mattino",
        full: "Preriscaldare l'ambiente al mattino \r\nCon questo valore si può stabilire quanto tempo prima dell'accensione dell'illuminazione le temperature di setpoint vengono portate ai valori del giorno. \r\nIl preriscaldamento presenta i seguenti vantaggi: \r\n- Si evita di esporre i fiori ancora umidi all'illuminazione. \r\n- All'inizio dell'esposizione le piante sono già a una temperatura che consente una fotosintesi ottimale a valori ottimali di VPD.",
        image: 'https://docs.growcontrol.de/preheat_room_DE_x820.png'
      }
    },

    temp_sp_heater_day: {
      en: {
        brief: 'Temperature heater day setpoint',
        full: "Temperature heater day setpoint \r\nThe temperature for the control of a heater is measured by the RH/T sensor. \r\nIf 'off' is set, the heater is guaranteed to be off during the day. This prevents the large energy consumers of heating and lighting from being on at the same time.",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Temperatur Heizung Tag Sollwert',
        full: "Sollwert Temperatur Heizung Tag \r\nDie Temperatur für die Regelung einer Heizung wird vom Sensor RH/T erfasst. \r\nWenn 'aus' eingestellt ist, ist die Heizung am Tag garantiert aus. Dadurch wird verhindert, dass die großen Energieverbraucher Heizung und Beleuchtung gleichzeitig eingeschaltet sind.",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'Température chauffage jour valeur de consigne',
        full: "Valeur de consigne température chauffage jour \r\nLa température pour la régulation d'un chauffage est détectée par le capteur RH/T. \r\nSi 'éteint' est réglé, le chauffage est garanti éteint pendant la journée. Cela permet d'éviter que les gros consommateurs d'énergie que sont le chauffage et l'éclairage soient allumés en même temps",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Temperatura consigna día calefacción',
        full: "Temperatura consigna día calefacción \r\nLa temperatura para el control de una calefacción es detectada por el sensor RH/T. \r\nSi se ajusta 'apagado', se garantiza que la calefacción esté apagada durante el día. De este modo se evita que la calefacción y la iluminación, grandes consumidoras de energía, estén encendidas al mismo tiempo",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: '',
        full: '',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },

    temp_sp_heater_night: {
      en: {
        brief: 'Temperature heater night setpoint',
        full: 'Temperature heater night setpoint \r\nThe temperature for the control of a heater is measured by the RH/T sensor.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Temperatur Heizung Nacht Sollwert',
        full: 'Sollwert Temperatur Heizung Nacht \r\nDie Temperatur für die Regelung einer Heizung wird vom Sensor RH/T erfasst.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'Température chauffage nuit valeur de consigne',
        full: "Valeur de consigne Température Chauffage Nuit \r\nLa température pour la régulation d'un chauffage est saisie par le capteur RH/T",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Temperatura consigna calefacción noche',
        full: 'Temperatura consigna calefacción noche \r\nLa temperatura para el control de una calefacción es detectada por el sensor RH/T.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: 'Temperatura nominale riscaldamento notte',
        full: 'Temperatura nominale riscaldamento notte \r\nLa temperatura per la regolazione di un sistema di riscaldamento viene rilevata dal sensore RH/T.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },

    toggle_temp_heating_mat_day: {
      en: {
        brief: 'Mode heating mat day',
        full: "Mode heating mat day \r\nThe heating mat temperature can be controlled either relative to the room temperature or to an absolute value. \r\nThe temperature for the control of a heating mat is detected by the 'Temperature sensor for heaters'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Modus Temperatur Heizmatte Tag',
        full: "Modus Temperatur Heizmatte Tag \r\nDie Heizmatten temperatur kann entweder relativ zur Raum-Temperatur oder auf einen absoluten Wert geregelt werden. \r\nDie Temperatur für die Regelung einer Heizmatte wird vom 'Temperatursensor für Heizgeräte' erfasst.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Mode température tapis chauffant jour',
        full: "Mode température tapis chauffant jour \r\nLa température du tapis chauffant peut être réglée soit par rapport à la température ambiante, soit sur une valeur absolue. \r\nLa température pour la régulation d'un tapis chauffant est détectée par le 'capteur de température pour appareils de chauffage'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Modo Temperatura estera calefactora día',
        full: "Modo temperatura estera calefactora día \r\nLa temperatura de la estera calefactora puede controlarse en relación con la temperatura ambiente o con un valor absoluto. \r\nLa temperatura para el control de una placa calefactora se mide mediante el 'Sensor de temperatura para calefactores'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Modalità Temperatura Tappetino di riscaldamento Giorno',
        full: "Modalità Temperatura Tappetino di riscaldamento Giorno \r\nLa temperatura del tappetino di riscaldamento può essere regolata rispetto alla temperatura ambiente o a un valore assoluto. \r\nLa temperatura per il controllo del tappeto riscaldante viene rilevata dal 'Sensore di temperatura per riscaldatori'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },

    toggle_temp_heating_mat_night: {
      en: {
        brief: 'Mode heating mat night',
        full: "Mode heating mat night \r\nThe heating mat temperature can be controlled either relative to the room temperature or to an absolute value. \r\nThe temperature for the control of a heating mat is detected by the 'Temperature sensor for heaters'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Modus Temperatur Heizmatte Nacht',
        full: "Modus Temperatur Heizmatte Nacht \r\nDie Heizmatten temperatur kann entweder relativ zur Raum-Temperatur oder auf einen absoluten Wert geregelt werden. \r\nDie Temperatur für die Regelung einer Heizmatte wird vom 'Temperatursensor für Heizgeräte' erfasst.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Mode température tapis chauffant nuit',
        full: "Mode température tapis chauffant nuit \r\nLa température du tapis chauffant peut être réglée soit par rapport à la température ambiante, soit sur une valeur absolue. \r\nLa température pour la régulation d'un tapis chauffant est détectée par le 'Capteur de température pour appareils de chauffage'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Modo Temperatura Estera Calefactora Noche',
        full: "Modo Temperatura Estera Calefactora Noche \r\nLa temperatura de la estera calefactora se puede controlar en relación con la temperatura ambiente o con un valor absoluto. La temperatura para el control de una placa calefactora se mide mediante el 'Sensor de temperatura para calefactores'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Modalità Temperatura Tappetino di riscaldamento Notte',
        full: "Modalità Temperatura Tappetino di riscaldamento Notte \r\nLa temperatura del tappetino di riscaldamento può essere regolata rispetto alla temperatura ambiente o a un valore assoluto. \r\nLa temperatura per il controllo del tappeto riscaldante viene rilevata dal 'Sensore di temperatura per riscaldatori'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },

    temp_sp_heating_mat_day_rel: {
      en: {
        brief: 'Temperature heating mat day setpoint',
        full: "Temperature heating mat day setpoint \r\nThe heating mat temperature can be controlled either relative to the room temperature or to an absolute value. \r\nThe temperature for the control of a heating mat is detected by the 'Temperature sensor for heaters'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Temperatur Heizmatte Tag Sollwert',
        full: "Sollwert Temperatur Heizmatte Tag \r\nDie Heizmatten Temperatur kann entweder relativ zur Raum-Temperatur oder auf einen absoluten Wert geregelt werden. Die Temperatur für die Regelung einer Heizmatte wird vom 'Temperatursensor für Heizgeräte' erfasst.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Température du tapis chauffant jour',
        full: "Valeur de consigne température tapis chauffant jour \nLa température du tapis chauffant peut être réglée soit par rapport à la température ambiante, soit sur une valeur absolue. La température pour la régulation d'un tapis chauffant est détectée par le 'capteur de température pour appareils de chauffage'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Temperatura consigna día estera calefactora',
        full: "Temperatura consigna día alfombra calefactora La temperatura de la alfombra calefactora puede controlarse en relación con la temperatura ambiente o con un valor absoluto. La temperatura para controlar una estera calefactora se detecta mediante el 'Sensor de temperatura para calefactores'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Temperatura nominale del tappetino di riscaldamento giorno',
        full: "Temperatura nominale del tappetino di riscaldamento giorno \r\nLa temperatura del tappetino di riscaldamento può essere regolata rispetto alla temperatura ambiente o a un valore assoluto. La temperatura per il controllo del tappeto riscaldante viene rilevata dal 'Sensore di temperatura per riscaldatori'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },

    temp_sp_heating_mat_night_rel: {
      en: {
        brief: 'Temperature heating mat night setpoint',
        full: "Temperature heating mat night setpoint \r\nThe heating mat temperature can be controlled either relative to the room temperature or to an absolute value. \r\nThe temperature for the control of a heating mat is detected by the 'Temperature sensor for heaters'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Temperatur Heizmatte Nacht Sollwert',
        full: "Sollwert Temperatur Heizmatte Nacht \r\nDie Heizmatten temperatur kann entweder relativ zur Raum-Temperatur oder auf einen absoluten Wert geregelt werden. Die Temperatur für die Regelung einer Heizmatte wird vom 'Temperatursensor für Heizgeräte' erfasst.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Température du tapis chauffant nuit',
        full: "Valeur de consigne de la température du tapis chauffant de nuit\nLa température du tapis chauffant peut être réglée soit par rapport à la température ambiante, soit sur une valeur absolue. La température pour la régulation d'un tapis chauffant est détectée par le 'capteur de température pour appareils de chauffage'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Temperatura consigna noche estera calefactora',
        full: "Temperatura de consigna nocturna de la alfombra calefactora La temperatura de la alfombra calefactora puede controlarse en relación con la temperatura ambiente o con un valor absoluto. La temperatura para controlar una estera calefactora se detecta mediante el 'Sensor de temperatura para calefactores'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Setpoint temperatura riscaldamento tappetino notte',
        full: "Setpoint temperatura tappetino di riscaldamento notte \r\nLa temperatura del tappetino di riscaldamento può essere regolata rispetto alla temperatura ambiente o a un valore assoluto. La temperatura per il controllo del tappeto riscaldante viene rilevata dal 'Sensore di temperatura per riscaldatori'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },
    temp_sp_heating_mat_day_abs: {
      en: {
        brief: 'Temperature heating mat day setpoint',
        full: "Temperature heating mat day setpoint \r\nThe heating mat temperature can be controlled either relative to the room temperature or to an absolute value. \r\nThe temperature for the control of a heating mat is detected by the 'Temperature sensor for heaters'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Temperatur Heizmatte Tag Sollwert',
        full: "Sollwert Temperatur Heizmatte Tag \r\nDie Heizmatten Temperatur kann entweder relativ zur Raum-Temperatur oder auf einen absoluten Wert geregelt werden. Die Temperatur für die Regelung einer Heizmatte wird vom 'Temperatursensor für Heizgeräte' erfasst.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Température du tapis chauffant jour',
        full: "Valeur de consigne température tapis chauffant jour \nLa température du tapis chauffant peut être réglée soit par rapport à la température ambiante, soit sur une valeur absolue. La température pour la régulation d'un tapis chauffant est détectée par le 'capteur de température pour appareils de chauffage'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Temperatura consigna día estera calefactora',
        full: "Temperatura consigna día alfombra calefactora La temperatura de la alfombra calefactora puede controlarse en relación con la temperatura ambiente o con un valor absoluto. La temperatura para controlar una estera calefactora se detecta mediante el 'Sensor de temperatura para calefactores'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Temperatura nominale del tappetino di riscaldamento giorno',
        full: "Temperatura nominale del tappetino di riscaldamento giorno \r\nLa temperatura del tappetino di riscaldamento può essere regolata rispetto alla temperatura ambiente o a un valore assoluto. La temperatura per il controllo del tappeto riscaldante viene rilevata dal 'Sensore di temperatura per riscaldatori'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },

    temp_sp_heating_mat_night_abs: {
      en: {
        brief: 'Temperature heating mat night setpoint',
        full: "Temperature heating mat night setpoint \r\nThe heating mat temperature can be controlled either relative to the room temperature or to an absolute value. \r\nThe temperature for the control of a heating mat is detected by the 'Temperature sensor for heaters'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      de: {
        brief: 'Temperatur Heizmatte Nacht Sollwert',
        full: "Sollwert Temperatur Heizmatte Nacht \r\nDie Heizmatten temperatur kann entweder relativ zur Raum-Temperatur oder auf einen absoluten Wert geregelt werden. Die Temperatur für die Regelung einer Heizmatte wird vom 'Temperatursensor für Heizgeräte' erfasst.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      fr: {
        brief: 'Température du tapis chauffant nuit',
        full: "Valeur de consigne de la température du tapis chauffant de nuit\nLa température du tapis chauffant peut être réglée soit par rapport à la température ambiante, soit sur une valeur absolue. La température pour la régulation d'un tapis chauffant est détectée par le 'capteur de température pour appareils de chauffage'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      es: {
        brief: 'Temperatura consigna noche estera calefactora',
        full: "Temperatura de consigna nocturna de la alfombra calefactora La temperatura de la alfombra calefactora puede controlarse en relación con la temperatura ambiente o con un valor absoluto. La temperatura para controlar una estera calefactora se detecta mediante el 'Sensor de temperatura para calefactores'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      },
      it: {
        brief: 'Setpoint temperatura riscaldamento tappetino notte',
        full: "Setpoint temperatura tappetino di riscaldamento notte \r\nLa temperatura del tappetino di riscaldamento può essere regolata rispetto alla temperatura ambiente o a un valore assoluto. La temperatura per il controllo del tappeto riscaldante viene rilevata dal 'Sensore di temperatura per riscaldatori'.",
        image: 'https://docs.growcontrol.de/Temperatur_Sensor_1_GrowControl_800x.png'
      }
    },

    dehumidification_mode: {
      en: {
        brief: 'Dehumidification via Exhaust',
        full: 'Dehumidification (exhaust air) \r\nIf the desired humidity is exceeded, it is possible to lower it by increasing the air flow. This may also lower the temperature. \r\nThe setting specifies how strong the effect on ventilation is when dehumidifying via the exhaust air. Additional settings can be found in the ADVANCED section.',
        image: ''
      },
      de: {
        brief: 'Entfeuchtung über Abluft',
        full: 'Entfeuchtungs-Modus (Abluft)\r\nBei  Überschreiten  der  gewünschten  Luftfeuchtigkeit  ist  es  möglich,  diese  durch Erhöhen des Luftstroms abzusenken. Dadurch  sinkt  ggf.  auch  die  Temperatur. \r\nDie Einstellung gibt vor, wie stark die Auswirkung auf die Lüftung beim Entfeuchten über die Abluft ist. Weitere Einstellungen sind im Bereich ERWEITERT zu finden.',
        image: ''
      },
      fr: {
        brief: 'Déshumidification (air évacué)',
        full: "Déshumidification (air évacué) \r\nSi l'humidité de l'air souhaitée est dépassée, il est possible de la faire baisser en augmentant le flux d'air. Le cas échéant, cela fait également baisser la température. \r\nCe réglage détermine l'importance de l'effet sur la ventilation lors de la déshumidification par l'air d'évacuation. D'autres paramètres peuvent être trouvés dans la section AVANCÉE.",
        image: ''
      },
      es: {
        brief: 'Deshumidificación (aire de escape)',
        full: 'Deshumidificación (aire de salida) \r\nSi se supera la humedad deseada, es posible reducirla aumentando el caudal de aire. Esto también puede reducir la temperatura. \r\rEl ajuste determina la intensidad del efecto sobre la ventilación cuando se deshumidifica a través del aire de salida. Encontrará más ajustes en la sección AVANZADA.',
        image: ''
      },
      it: {
        brief: 'Deumidificazione (aria di scarico)',
        full: "Se l'umidità desiderata viene superata, è possibile abbassarla aumentando il flusso d'aria. In questo modo si può anche abbassare la temperatura. \r\nL'impostazione determina l'intensità dell'effetto sulla ventilazione quando si deumidifica attraverso l'aria di scarico. Ulteriori impostazioni sono disponibili nella sezione AVANZATE.",
        image: ''
      }
    },
    heating_to_dehumidify_en: {
      en: {
        brief: 'Heating for dehumidification',
        full: 'Heating for dehumidification \r\nIf the set humidity is exceeded by 5% RH, the connected heating is switched on, provided that the measured temperature is below the desired one. \r\nExplanation: The humidity of the air is physically dependent on the temperature in such a way that during heating the humidity of the air decreases.',
        image: ''
      },
      de: {
        brief: 'Heizen zu Entfeuchten',
        full: 'Heizen zum Entfeuchten \r\nBei Überschreitung der eingestellten Luftfeuchtigkeit um 5% RH wird die angeschlossene Heizung eingeschaltet, sofern  die  gemessene  Temperatur  unter der  gewünschten  liegt. \r\nErläuterung:  Die Luftfeuchtigkeit ist physikalisch in der Weise von der Temperatur abhängig, dass beim Heizen die Luftfeuchtigkeit sinkt.',
        image: ''
      },
      fr: {
        brief: 'Chauffage pour déshumidifier',
        full: "Chauffage pour déshumidifier \r\nEn cas de dépassement de 5% RH de l'humidité de l'air réglée, le chauffage raccordé est activé si la température mesurée est inférieure à celle souhaitée. \r\nExplication : L'humidité de l'air dépend physiquement de la température de telle sorte que l'humidité de l'air diminue lors du chauffage.",
        image: ''
      },
      es: {
        brief: 'Calefacción para deshumidificación',
        full: 'Calefacción para deshumidificación \r\nSi la humedad ajustada se supera en un 5% RH, se conecta la calefacción conectada, siempre que la temperatura medida esté por debajo de la deseada. \r\nExplicación: La humedad del aire depende físicamente de la temperatura, de forma que la humedad del aire disminuye al calentar.',
        image: ''
      },
      it: {
        brief: 'Riscaldamento per la deumidificazione',
        full: "Riscaldamento per la deumidificazione \r\nSe l'umidità impostata viene superata del 5% RH, viene attivato il riscaldamento collegato, a condizione che la temperatura misurata sia inferiore a quella desiderata. \r\nSpiegazione: l'umidità dell'aria dipende fisicamente dalla temperatura, in modo tale che l'umidità dell'aria diminuisce durante il riscaldamento.",
        image: ''
      }
    },

    // co2
    co2_control: {
      en: {
        brief: 'CO₂ control on/off',
        full: 'CO₂ control on/off \r\nThe CO₂ control is only active at daytime.',
        image: ''
      },
      de: {
        brief: 'CO₂-Regelung ein/aus',
        full: 'CO₂-Regelung ein/aus \r\nDie CO₂-Regelung ist nur während der Tag-Phase aktiv.',
        image: ''
      },
      fr: {
        brief: 'Régulation CO₂ activée/désactivée',
        full: "Régulation CO₂ on/off \r\nLa régulation CO₂ n'est active que pendant la phase de jour",
        image: ''
      },
      es: {
        brief: 'Control de CO₂ activado/desactivado',
        full: 'CO₂-control on/off \r\nEl CO₂-control sólo está activo durante la fase diurna',
        image: ''
      },
      it: {
        brief: 'Controllo CO₂ on/off',
        full: 'Controllo CO₂ on/off \r\nIl controllo CO₂ è attivo solo durante la fase diurna.',
        image: ''
      }
    },

    co2_sp: {
      en: {
        brief: 'CO₂ setpoint',
        full: 'CO₂ setpoint',
        image: ''
      },
      de: {
        brief: 'Sollwert CO₂',
        full: 'Sollwert CO₂',
        image: ''
      },
      fr: {
        brief: 'Valeur de consigne CO₂',
        full: 'Valeur de consigne CO₂',
        image: ''
      },
      es: {
        brief: 'Punto de consigna CO₂',
        full: 'Punto de consigna CO₂',
        image: ''
      },
      it: {
        brief: 'Valore nominale CO₂',
        full: 'Valore nominale CO₂',
        image: ''
      }
    },

    room_vol_sp: {
      en: {
        brief: 'Room volume',
        full: 'Room volume \r\nTo calculate the initial CO₂ dose for the fuzzy CO₂ control, the controller must know the room volume.',
        image: ''
      },
      de: {
        brief: 'Raum Volumen',
        full: 'Raum Volumen \r\nUm die Anfangs-CO₂-Dosis für die Fuzzy-CO₂-Regelung zu berechnen, muss der Controller das Raum-Volumen kannen.',
        image: ''
      },
      fr: {
        brief: 'volume de la pièce',
        full: 'Raum Volumen \r\nPour calculer la dose initiale de CO₂ pour la régulation Fuzzy-CO₂, le contrôleur doit connaître le volume de la pièce',
        image: ''
      },
      es: {
        brief: 'Volumen de la habitación',
        full: 'Volumen de la sala \r\nPara calcular la dosis inicial de CO₂ para el control difuso de CO₂, el controlador debe conocer el volumen de la sala.',
        image: ''
      },
      it: {
        brief: 'Volume della stanza',
        full: 'Volume della stanza \r\nPer calcolare la dose iniziale di CO₂ per il controllo fuzzy della CO₂, il controllore deve conoscere il volume del locale.',
        image: ''
      }
    },
    room_scale: {
      en: {
        brief: 'Room Size Range',
        full: 'From this setting depends how large the offered setting range is for: \r\n-Room volume \r\n-CO₂ flow \r\n-Exhaust fans flow at 100% ',
        image: ''
      },
      de: {
        brief: 'Raum-Größenordnung',
        full: 'Raumgröße \r\nVon dieser Einstellung ist abhängig, wie groß der angebotene Einstellbereich ist für: \r\n-Raumvolumen \r\n-CO2-Durchfluss \r\n-Abluft-Leistung bei 100%',
        image: ''
      },
      fr: {
        brief: "Ordre de grandeur de l'espace",
        full: "Ordre de grandeur de l'espace \r\nLa taille de la plage de réglage proposée pour dépend de ce réglage : \r\n-volume de la pièce \r\n-débit de CO2 \r\n-puissance d'extraction à 100%.",
        image: ''
      },
      es: {
        brief: 'Rango de tamaño de habitación',
        full: 'Rango de tamaño de habitación \r\nEste ajuste determina para qué tamaño es el rango de ajuste ofrecido: \volumen de la habitación\r\n-volumen de la habitación \r\n-caudal de CO2 \r\n-rendimiento del aire de escape al 100%',
        image: ''
      },
      it: {
        brief: 'Dimensioni della camera',
        full: "Dimensione della camera \r\nQuesta impostazione determina l'ampiezza del campo di regolazione offerto per: \r\n-volume del locale \r\n-Portata di CO2 \r\n-Prestazioni dell'aria di scarico al 100%.",
        image: ''
      }
    },
    co2_flow: {
      en: {
        brief: 'CO₂ flow rate',
        full: 'CO₂ flow rate \r\nEnter the flow rate value set on the CO₂ pressure reducer or the output of the CO₂ generator here. \r\nGuide values depending on the room volume: \r\nup to 10 m³ - 5 bis 8 l/min \r\n10 bis 20 m³ - 10 l/min \r\n20 bis 50 m³ - 15 l/min \r\n50 bis 100 m³ - 20 l/min \r\ngreater then 100 m³ - 25 l/min',
        image: ''
      },
      de: {
        brief: 'Durchfluss CO₂',
        full: 'Durchfluss CO₂\r\nGib hier den Durchfluss-Wert ein, der am CO₂-Druckminderer eingestellt ist bzw. die CO₂-Leistung des CO₂-Generators. \r\nRichtwerte in Abhängigkeit zum Raumvolumen: \r\nBis 10 m³ - 5 bis 8 l/min \r\n10 bis 20 m³ - 10 l/min \r\n20 bis 50 m³ - 15 l/min \r\n50 bis 100 m³ - 20 l/min \r\nGrößer 100 m³ - 25 l/min',
        image: ''
      },
      fr: {
        brief: 'Débit CO₂',
        full: "Débit CO₂NIndiquez ici la valeur de débit réglée sur le détendeur de CO₂ ou la puissance de CO₂ du générateur de CO₂. \r\nValeurs indicatives en fonction du volume de la pièce : \r\nJusqu'à 10 m³ - 5 à 8 l/min \r\n10 à 20 m³ - 10 l/min \r\n20 à 50 m³ - 15 l/min \r\n50 à 100 m³ - 20 l/min \r\nPlus de 100 m³ - 25 l/min",
        image: ''
      },
      es: {
        brief: 'Caudal CO₂',
        full: 'Caudal CO₂\r\nIntroduzca aquí el valor de caudal ajustado en el reductor de presión de CO₂ o en la salida de CO₂ del generador de CO₂. \r\nValores orientativos en función del volumen del local: \r\nHasta 10 m³ - 5 a 8 l/min \r\nDe 10 a 20 m³ - 10 l/min \r\nDe 20 a 50 m³ - 15 l/min \r\nDe 50 a 100 m³ - 20 l/min \r\nMayor de 100 m³ - 25 l/min',
        image: ''
      },
      it: {
        brief: 'Caudal de CO₂',
        full: 'Caudal de CO₂ \r\nIntroduzca aquí el valor de caudal ajustado en el reductor de presión de CO₂ o en la salida de CO₂ del generador de CO₂. \r\nValores orientativos en función del volumen del local: \r\nHasta 10 m³ - 5 a 8 l/min \r\nDe 10 a 20 m³ - 10 l/min \r\n20 a 50 m³ - 15 l/min \r\n50 a 100 m³ - 20 l/min \r\nMás de 100 m³ - 25 litros/minuto',
        image: ''
      }
    },
    temp_heater_co2: {
      en: {
        brief: 'Setpoint Temperature Heater CO₂',
        full: 'Setpoint Temperature Heater CO₂\r setpoint for heating during CO₂ phase.',
        image: ''
      },
      de: {
        brief: 'Solltemperatur Heizung CO₂',
        full: 'Solltemperatur Heizung CO₂\r\nSollwert für die Heizung während der CO₂-Phase.',
        image: ''
      },
      fr: {
        brief: 'Température de consigne du chauffage CO₂',
        full: 'Température de consigne du chauffage CO₂\r\nValeur de consigne du chauffage pendant la phase CO₂.',
        image: ''
      },
      es: {
        brief: 'Consigna temperatura calefacción CO₂',
        full: 'Consigna temperatura calefacción CO₂\r\nConsigna calefacción durante fase CO₂',
        image: ''
      },
      it: {
        brief: 'Valor de consigna de la temperatura de calentamiento de CO₂',
        full: 'Valor de consigna de la temperatura de calentamiento de CO₂ \r\n.Consigna de calefacción durante la fase de CO₂.',
        image: ''
      }
    },
    exhaust_fans_flow: {
      en: {
        brief: 'Exhaust Air Flow',
        full: 'Exhaust Air Flow @100%\r\nEnter the flow rate of your exhaust fans according to the manufacturer. (Sum of all exhaust air fans). This setting allows the fuzzy CO₂ control to know the amount of air that is extracted from the room in a given period of time and to adjust the CO₂ dosage accordingly.',
        image: ''
      },
      de: {
        brief: 'Abluftförderleistung',
        full: 'Abluftförderleistung @100%\r\nGib hier an, welche Förderleistung deine Abluftventilatoren laut Hersteller haben. (Summe aller Abluftventilatoren). Durch diese Einstellung kann die Fuzzy-CO₂-Regelung die Menge der abgesaugten Luft innerhalb eines bestimmten Zeitraums erfassen und die CO₂-Dosis entsprechend anpassen.',
        image: ''
      },
      fr: {
        brief: "Débit d'air d'évacuation",
        full: "Débit d'air d'évacuation @100%\r\nIndique ici le débit de tes ventilateurs d'évacuation selon le fabricant. (Somme de tous les ventilateurs d'évacuation). Ce réglage permet à la régulation Fuzzy-CO₂ de connaître la quantité d'air qui est extraite de la pièce pendant une période donnée et d'ajuster la dose de CO₂ en conséquence.",
        image: ''
      },
      es: {
        brief: 'Flujo de aire de escape',
        full: 'Flujo de aire de escape @100%\r\nIndica aquí el caudal de tus ventiladores de aire de escape según el fabricante. (Suma de todos los ventiladores de aire de escape). Este ajuste permite al control difuso de CO₂ conocer la cantidad de aire que se extrae de la habitación en un determinado período de tiempo y ajustar la dosis de CO₂ en consecuencia.',
        image: ''
      },
      it: {
        brief: 'Flusso di aria di scarico',
        full: 'Flusso di aria di scarico @100%\r\nInserisci il flusso di aria dei tuoi ventilatori di scarico secondo il produttore. (Somma di tutti i ventilatori di aria di scarico). Questa impostazione consente al controllo CO₂ sfumato di conoscere la quantità di aria che viene estratta dalla stanza in un determinato periodo di tempo e di regolare di conseguenza la dose di CO₂.',
        image: ''
      }
    },

    temp_sp_co2: {
      en: {
        brief: 'Temperature setpoint for CO₂ mode',
        full: 'Temperature setpoint for CO₂ mode (room)',
        image: ''
      },
      de: {
        brief: 'Temperatur-Sollwert im CO₂-Modus',
        full: 'Temperatur-Sollwert im CO₂-Modus (Raum)',
        image: ''
      },
      fr: {
        brief: 'Température de consigne en mode CO₂',
        full: 'Température de consigne en mode CO₂ (pièce)',
        image: ''
      },
      es: {
        brief: 'Temperatura objetivo en modo CO₂',
        full: 'Temperatura objetivo en modo CO₂ (habitación)',
        image: ''
      },
      it: {
        brief: 'Setpoint di temperatura per modalità CO₂',
        full: 'Setpoint di temperatura per modalità CO₂ (stanza)',
        image: ''
      }
    },

    exhaust_fans_ec_min_max_co2: {
      en: {
        brief: 'Exhaust EC fans MIN/MAX CO₂',
        full: "Exhaust EC fans MIN/MAX CO₂ \r\nHere the allowed speed range of the EC exhaust fans during the CO₂ phase is set. \r\nAn EC fan is switched off below 10%. \r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Abluft EC MIN/MAX CO₂',
        full: "Abluft EC MIN/MAX CO₂ \r\nHier wird er erlaubte Drehzahlbereich der EC Abluftventilatoren während der CO₂-Phase eingestellt. \r\nEin EC-Ventilator wird unterhalb von 10% abgeschaltet. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Extraction EC MIN/MAX CO₂',
        full: "Extraction EC MIN/MAX CO₂ \r\nIl s'agit de définir la plage de vitesse autorisée pour les ventilateurs d'extraction EC pendant la phase CO₂. \r\nUn ventilateur EC est arrêté en dessous de 10%. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTEM>MENU REGLAGES>Menu affichage ventilateurs'",
        image: ''
      },
      es: {
        brief: 'Aire de escape EC MIN/MAX CO₂',
        full: "Aire de escape EC MIN/MAX CO₂ \r\n Esto establece el rango de velocidad permitido de los ventiladores de aire de escape EC durante la fase de CO₂. NUn ventilador EC se desconecta por debajo del 10%. \r\n\r\nLa visualización de las opciones de los ventiladores de CA, CE o CA y CE depende del ajuste 'SISTEMA>AJUSTES DE MENÚ>Visualización del menú de ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di scarico EC MIN/MAX CO₂',
        full: "Aria di scarico EC MIN/MAX CO₂ \r\nQuesto parametro imposta la gamma di velocità consentita dei ventilatori dell'aria di scarico EC durante la fase CO₂. Un ventilatore EC viene spento al di sotto del 10%. \r\n\r\nLa visualizzazione delle opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>Impostazioni menu>Visualizzazione menu ventilatori'.",
        image: ''
      }
    },

    intake_fan_ec_min_max_co2: {
      en: {
        brief: 'Intake EC fans MIN/MAX CO₂',
        full: "Intake EC fans MIN/MAX CO₂ \r\nHere the allowed speed range of the EC intake fans during the CO₂ phase is set. \r\nAn EC fan is switched off below 10%. \r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Zuluft EC MIN/MAX CO₂',
        full: "Zuluft EC MIN/MAX CO₂ \r\nHier wird er erlaubte Drehzahlbereich der EC Zuluftventilatoren während der CO₂-Phase eingestellt. \r\nEin EC-Ventilator wird unterhalb von 10% abgeschaltet. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Intraction EC MIN/MAX CO₂',
        full: "Intraction EC MIN/MAX CO₂ \r\nIl s'agit de régler la plage de vitesse autorisée des ventilateurs d'intraction EC pendant la phase CO₂. \r\nUn ventilateur EC est arrêté en dessous de 10%. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTÈME>RÉGLAGES DU MENU>Affichage du menu Ventilateurs'",
        image: ''
      },
      es: {
        brief: 'Aire de suministro EC MIN/MAX CO₂',
        full: "Suministro aire EC MIN/MAX CO₂ \r\nEsto establece el rango de velocidad permitido de los ventiladores de suministro de aire EC durante la fase de CO₂. NUn ventilador EC se desconecta por debajo del 10%. \r\n\r\nLa visualización de las opciones de los ventiladores de CA, CE o CA y CE depende del ajuste 'SISTEMA>AJUSTES DE MENÚ>Visualización del menú de ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di aspirazione EC MIN/MAX CO₂',
        full: "Aria di aspirazione EC MIN/MAX CO₂ \r\nQuesto parametro imposta la gamma di velocità consentita dei ventilatori dell'aria di aspirazione EC durante la fase CO₂. Un ventilatore EC viene spento al di sotto del 10%. \r\n\r\nLa visualizzazione delle opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>Impostazioni menu>Visualizzazione menu ventilatori'.",
        image: ''
      }
    },
    exhaust_fans_ac_min_max_co2: {
      en: {
        brief: 'Exhaust AC fans MIN/MAX CO₂',
        full: "Exhaust AC fans MIN/MAX CO₂ \r\nHere the allowed speed range of the AC exhaust fans during the CO₂ phase is set. \r\nAn AC exhaust fan is switched off when the value set under 'Advanced'->'Fan AC exhaust turnoff' is undercut.\r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Abluft AC MIN/MAX CO₂',
        full: "Abluft AC MIN/MAX CO₂ \r\nHier wird er erlaubte Drehzahlbereich der AC Abluftventilatoren während der CO₂-Phase eingestellt. \r\nEin AC-Abluft-Ventilator wird abgeschaltet, wenn der Wert, der unter 'Erweitert'->'Abschaltung AC-Ventilator Abluft' eingestellt ist, unterschritten wird. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Extraction AC MIN/MAX CO₂',
        full: "Extraction AC MIN/MAX CO₂ \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'extraction AC pendant la phase CO₂. \r\nUn ventilateur d'extraction AC est arrêté lorsque la valeur définie dans 'Avancé'->'Arrêt du ventilateur d'extraction AC' n'est pas atteinte. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTEME>MENU REGLAGES>Menu affichage ventilateurs'",
        image: ''
      },
      es: {
        brief: 'Aire de escape CA MIN/MAX CO₂',
        full: "Aire de extracción AC MIN/MAX CO₂ \r Esto establece el rango de velocidad permitido de los ventiladores de extracción AC durante la fase de CO₂. \r\nUn ventilador de extracción de aire de CA se desconecta cuando el valor ajustado en 'Avanzado'->'Desconectar ventilador de extracción de aire de CA' es inferior a la velocidad permitida. La visualización de las opciones de los ventiladores de CA, CE o CA y CE depende del ajuste 'SISTEMA>AJUSTES DE MENÚ>Visualización del menú de ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di scarico EC MIN/MAX CO₂',
        full: "Aria di scarico EC MIN/MAX CO₂ \r\nQuesto parametro imposta la gamma di velocità consentita dei ventilatori dell'aria di scarico EC durante la fase CO₂. Un ventilatore EC viene spento al di sotto del 10%. \r\n\r\nLa visualizzazione delle opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>Impostazioni menu>Visualizzazione menu ventilatori'.",
        image: ''
      }
    },

    intake_fan_ac_min_max_co2: {
      en: {
        brief: 'Intake AC fans MIN/MAX CO₂',
        full: "Intake AC fans MIN/MAX CO₂ \r\nHere the allowed speed range of the AC intake fans during the CO₂ phase is set. \r\n An AC exhaust fan is switched off when the value set under 'Advanced'->'Fan AC exhaust turnoff' is undercut.\r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Zuluft AC MIN/MAX CO₂',
        full: "Zuluft AC MIN/MAX CO₂ \r\nHier wird er erlaubte Drehzahlbereich der AC Zuluftventilatoren während der CO₂-Phase eingestellt. \r\nEin AC-Abluft-Ventilator wird abgeschaltet, wenn der Wert, der unter 'Erweitert'->'Abschaltung AC-Ventilator Abluft' eingestellt ist, unterschritten wird. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Intraction AC MIN/MAX CO₂',
        full: "Intraction AC MIN/MAX CO₂ \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'intraction AC pendant la phase CO₂. \r\nUn ventilateur d'extraction AC est arrêté lorsque la valeur définie dans 'Avancé'->'Arrêt du ventilateur d'extraction AC' n'est pas atteinte. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTEME>MENU REGLAGES>Menu affichage ventilateurs'",
        image: ''
      },
      es: {
        brief: 'Suministro aire AC MIN/MAX CO₂',
        full: "Aire de impulsión AC MIN/MAX CO₂ \r\nEsto ajusta el rango de velocidad permitido de los ventiladores de aire de impulsión AC durante la fase de CO₂. \r\nUn ventilador de extracción de aire de CA se desconecta cuando el valor ajustado en 'Avanzado'->'Desconectar ventilador de extracción de aire de CA' es inferior a la velocidad permitida. La visualización de las opciones de los ventiladores de CA, CE o CA y CE depende del ajuste 'SISTEMA>AJUSTES DE MENÚ>Visualización del menú de ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aspirazione aria EC MIN/MAX CO₂',
        full: "Aspirazione aria EC MIN/MAX CO₂ \r\nQuesto parametro imposta la gamma di velocità consentita dei ventilatori dell'aria di aspirazione EC durante la fase CO₂. \r\nUn ventilatore EC viene spento al di sotto del 10%. \r\n\r\nLa visualizzazione delle opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>Impostazioni menu>Visualizzazione menu ventilatori'.",
        image: ''
      }
    },

    // ventilation
    pressure_percent_day: {
      en: {
        brief: 'Negative pressure day in %',
        full: 'Negative pressure day in % \r\nThis setting has different effects depending on whether a negative pressure sensor is connected or not: \r\n\r\nWithout a pressure sensor connected: \r\nA negative pressure is created in the planting room by air flows of different sizes from the supply and exhaust fans to prevent odors from escaping. The strength of the negative pressure can be adjusted via this value. In addition, when using supply and exhaust fans of different strengths, the fans can be matched to each other. \r\n\r\nWith pressure sensor connected: \r\nThe setting is the minimum speed difference between the supply and exhaust fans. In the event that the pressure sensor is badly calibrated, the supply air fan could rotate faster than the exhaust air fan. As a result, the negative pressure would no longer be guaranteed. \r\nExample: Negative pressure in % = 10% \r\nThe supply air fan turns at least 10% slower than the exhaust air fan.',
        image: ''
      },
      de: {
        brief: 'Unterdruck Tag in %',
        full: 'Unterdruck Tag in % \r\nDiese Einstellung hat unterschiedliche Auswirkungen, je nachdem, ob ein Unterdrucksensor angeschlossen ist oder nicht: \r\n\r\nOhne angeschlossenen Drucksensor: \r\nDurch unterschiedlich große Luftströme der Zu- und Abluft-Ventilatoren wird im Pflanzraum ein Unterdruck erzeugt, um den Austritt von Gerüchen zu vermeiden. Über diesen Wert kann die Stärke des Unterdrucks eingestellt werden. Zusätzlich können  beim Einsatz  von unterschiedlich starken Zu- und Abluft-Ventilatoren die Ventilatoren aufeinander abgestimmt werden. \r\n\r\nMit angeschlossenem Drucksensor: Die  Einstellung ist die Mindest-Drehzahldifferenz zwischen Zuluft- und Abluft-Ventilator. Für den Fall, dass der Drucksensor schlecht kalibriert ist, könnte der Zuluft-Ventilator schneller drehen, als der Abluft-Ventilator. Dadurch wäre der Unterdruck nicht mehr garantiert. \r\nBeispiel: Unterdruck in % = 10% \r\nDer Zuluft-Ventilator dreht mindestens 10% langsamer, als der Abluftventilator.',
        image: ''
      },
      fr: {
        brief: 'dépression jour en %',
        full: "Dépression jour en % \r\nCe paramètre a des effets différents selon qu'un capteur de dépression est connecté ou non : \r\n\r\nSans capteur de pression raccordé : \r\nUne dépression est créée dans la chambre de plantation par des flux d'air de tailles différentes des ventilateurs d'alimentation et d'extraction, afin d'éviter que les odeurs ne s'échappent. Cette valeur permet de régler l'intensité de la dépression. De plus, en cas d'utilisation de ventilateurs d'alimentation et d'extraction de différentes puissances, les ventilateurs peuvent être adaptés les uns aux autres. \r\n\ravec un capteur de pression raccordé : le réglage correspond à la différence de vitesse minimale entre le ventilateur de soufflage et le ventilateur d'extraction. Dans le cas où le capteur de pression serait mal calibré, le ventilateur d'Intraction pourrait tourner plus vite que le ventilateur d'Extraction. La dépression ne serait alors plus garantie. \r\nExemple : dépression en % = 10% \r\nLe ventilateur d'intraction tourne au moins 10% plus lentement que le ventilateur d'extraction",
        image: ''
      },
      es: {
        brief: 'Presión negativa día en %',
        full: 'Este ajuste tiene diferentes efectos dependiendo de si un sensor de presión negativa está conectado o no: \r\nSin sensor de presión conectado: Debido a los diferentes caudales de aire de los ventiladores de impulsión y extracción, se crea una presión negativa en la sala de máquinas para evitar la salida de olores. La intensidad de la presión negativa puede ajustarse mediante este valor. Además, cuando se utilizan ventiladores de impulsión y extracción con diferentes potencias, los ventiladores se pueden adaptar entre sí. \r\nCon el sensor de presión conectado: El ajuste es la diferencia mínima de velocidad entre los ventiladores de impulsión y de extracción. En caso de que el sensor de presión esté mal calibrado, el ventilador de aire de impulsión podría girar más rápido que el ventilador de aire de extracción. Esto significaría que la presión negativa ya no está garantizada. \r\nEjemplo: Presión negativa en % = 10% \r\nEl ventilador de impulsión gira al menos un 10% más lento que el de extracción.',
        image: ''
      },
      it: {
        brief: 'Pressione negativa giorno in %',
        full: "Pressione negativa giorno in % \r\nQuesta impostazione ha effetti diversi a seconda che sia collegato o meno un sensore di pressione negativa: \r\n\r\nSenza sensore di pressione collegato: \r\nNella stanza di coltivazione viene creata una pressione negativa da flussi d'aria di diverse dimensioni dai ventilatori di alimentazione e di estrazione per evitare che gli odori fuoriescano. L'intensità della pressione negativa può essere regolata tramite questo valore. Inoltre, quando si utilizzano ventilatori di alimentazione e di estrazione di diverse potenze, i ventilatori possono essere adattati l'uno all'altro. \r\n\r\nCon sensore di pressione collegato: \r\nL'impostazione è la differenza di velocità minima tra i ventilatori di alimentazione e di estrazione. Nel caso in cui il sensore di pressione sia mal calibrato, il ventilatore di alimentazione potrebbe ruotare più velocemente del ventilatore di estrazione. Di conseguenza, la pressione negativa non sarebbe più garantita. \r\nEsempio: Pressione negativa in % = 10% \r\nIl ventilatore di alimentazione gira almeno il 10% più lentamente del ventilatore di estrazione.",
        image: ''
      }
    },

    pressure_percent_night: {
      en: {
        brief: 'Negative pressure night in %',
        full: 'Negative pressure night in % \r\nThis setting has different effects depending on whether a negative pressure sensor is connected or not: \r\n\r\nWithout a pressure sensor connected: \r\nA negative pressure is created in the planting room by air flows of different sizes from the supply and exhaust fans to prevent odors from escaping. The strength of the negative pressure can be adjusted via this value. In addition, when using supply and exhaust fans of different strengths, the fans can be matched to each other. \r\n\r\nWith pressure sensor connected: \r\nThe setting is the minimum speed difference between the supply and exhaust fans. In the event that the pressure sensor is badly calibrated, the supply air fan could rotate faster than the exhaust air fan. As a result, the negative pressure would no longer be guaranteed. \r\nExample: Negative pressure in % = 10% \r\nThe supply air fan turns at least 10% slower than the exhaust air fan.',
        image: ''
      },
      de: {
        brief: 'Unterdruck Nacht in %',
        full: 'Unterdruck Nacht in %\r\nDiese Einstellung hat unterschiedliche Auswirkungen, je nachdem, ob ein Unterdrucksensor angeschlossen ist oder nicht: \r\n\r\nOhne angeschlossenen Drucksensor: \r\nDurch unterschiedlich große Luftströme der Zu- und Abluft-Ventilatoren wird im Pflanzraum ein Unterdruck erzeugt, um den Austritt von Gerüchen zu vermeiden. Über diesen Wert kann die Stärke des Unterdrucks eingestellt werden. Zusätzlich können  beim Einsatz  von unterschiedlich starken Zu- und Abluft-Ventilatoren die Ventilatoren aufeinander abgestimmt werden. \r\n\r\nMit angeschlossenem Drucksensor: Die  Einstellung ist die Mindest-Drehzahldifferenz zwischen Zuluft- und Abluft-Ventilator. Für den Fall, dass der Drucksensor schlecht kalibriert ist, könnte der Zuluft-Ventilator schneller drehen, als der Abluft-Ventilator. Dadurch wäre der Unterdruck nicht mehr garantiert. \r\nBeispiel: Unterdruck in % = 10% \r\nDer Zuluft-Ventilator dreht mindestens 10% langsamer, als der Abluftventilator.',
        image: ''
      },
      fr: {
        brief: 'Dépression nuit en %',
        full: "Dépression nuit en %\r\nCe paramètre a des effets différents selon qu'un capteur de dépression est connecté ou non : \r\n\r\nSans capteur de pression raccordé : \r\nUne dépression est créée dans la chambre de plantation par des flux d'air de tailles différentes des ventilateurs d'alimentation et d'extraction, afin d'éviter que les odeurs ne s'échappent. Cette valeur permet de régler l'intensité de la dépression. De plus, en cas d'utilisation de ventilateurs d'alimentation et d'extraction de différentes puissances, les ventilateurs peuvent être adaptés les uns aux autres. \r\n\ravec un capteur de pression raccordé : le réglage correspond à la différence de vitesse minimale entre le ventilateur de soufflage et le ventilateur d'extraction. Dans le cas où le capteur de pression serait mal calibré, le ventilateur d'Intraction pourrait tourner plus vite que le ventilateur d'Extraction. La dépression ne serait alors plus garantie. \r\nExemple : dépression en % = 10% \r\nLe ventilateur d'intraction tourne au moins 10% plus lentement que le ventilateur d'extraction",
        image: ''
      },
      es: {
        brief: 'Presión negativa noche en %',
        full: 'Presión negativa noche en %r\nEste ajuste tiene diferentes efectos dependiendo de si hay un sensor de presión negativa conectado o no: Sin sensor de presión conectado: Debido a los diferentes caudales de aire de los ventiladores de impulsión y extracción, se crea una presión negativa en la sala de máquinas para evitar la salida de olores. La intensidad de la presión negativa puede ajustarse mediante este valor. Además, cuando se utilizan ventiladores de impulsión y extracción con diferentes potencias, los ventiladores se pueden adaptar entre sí. Con el sensor de presión conectado: El ajuste es la diferencia mínima de velocidad entre los ventiladores de impulsión y de extracción. En caso de que el sensor de presión esté mal calibrado, el ventilador de aire de impulsión podría girar más rápido que el ventilador de aire de extracción. Esto significaría que la presión negativa ya no está garantizada. \r\nEjemplo: Presión negativa en % = 10% \r\nEl ventilador de impulsión gira al menos un 10% más lento que el de extracción.',
        image: ''
      },
      it: {
        brief: 'Pressione negativa notturna in %',
        full: "Pressione negativa notturna in %\r\nQuesta impostazione ha effetti diversi a seconda che sia collegato o meno un sensore di pressione negativa: \r\n\r\nSenza sensore di pressione collegato: \r\nNella stanza di coltivazione viene creata una pressione negativa da flussi d'aria di diverse dimensioni dai ventilatori di alimentazione e di estrazione per evitare che gli odori fuoriescano. L'intensità della pressione negativa può essere regolata tramite questo valore. Inoltre, quando si utilizzano ventilatori di alimentazione e di estrazione di diversa potenza, i ventilatori possono essere abbinati tra loro. \r\n\r\nCon sensore di pressione collegato: \r\nL'impostazione è la differenza di velocità minima tra i ventilatori di alimentazione e di estrazione. Nel caso in cui il sensore di pressione sia mal calibrato, il ventilatore di alimentazione potrebbe ruotare più velocemente del ventilatore di estrazione. Di conseguenza, la pressione negativa non sarebbe più garantita. \r\nEsempio: Pressione negativa in % = 10% \r\nIl ventilatore di alimentazione gira almeno il 10% più lentamente del ventilatore di estrazione.",
        image: ''
      }
    },

    exhaust_fan_ec_min_max_day: {
      en: {
        brief: 'Exhaust EC fans MIN/MAX Day',
        full: "Exhaust EC fans MIN/MAX Day \r\nHere the allowed speed range of the EC exhaust fans during the day phase is set. \r\nAn EC fan is switched off below 10%. \r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Abluft EC MIN/MAX Tag',
        full: "Abluft EC MIN/MAX Tag \r\nHier wird der erlaubte Drehzahlbereich der EC Abluftventilatoren während der Tagphase eingestellt. \r\nEin EC-Ventilator wird unterhalb von 10% abgeschaltet. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Extraction EC MIN/MAX jour',
        full: "Extraction EC MIN/MAX Tag \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'extraction EC pendant la phase de jour. \r\nUn ventilateur EC est arrêté en dessous de 10%. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTÈME>RÉGLAGES DU MENU>Affichage du menu Ventilateurs'.",
        image: ''
      },
      es: {
        brief: 'Aire de escape EC MIN/MAX día',
        full: "Exhaust air EC MIN/MAX day \r\nAjusta el rango de velocidad permitido de los ventiladores de aire de escape EC durante la fase diurna. Un ventilador EC se desconecta por debajo del 10%.  Si se muestran las opciones de los ventiladores AC, EC, o AC&EC depende del ajuste 'SISTEMA>AJUSTES DE MENÚ>Menú de ventiladores'",
        image: ''
      },
      it: {
        brief: 'Aria di estrazione EC MIN/MAX giorno',
        full: "Aria di estrazione EC MIN/MAX giorno \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di estrazione EC durante la fase diurna. \r\nUn ventilatore EC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },

    intake_fan_ec_min_max_day: {
      en: {
        brief: 'Intake EC fans MIN/MAX Day',
        full: "Intake EC fans MIN/MAX Day \r\nHere the allowed speed range of the EC intake fans during the day phase is set. \r\nAn EC fan is switched off below 10%. \r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Zuluft EC MIN/MAX Tag',
        full: "Zuluft EC MIN/MAX Tag \r\nHier wird der erlaubte Drehzahlbereich der EC Zuluftventilatoren während der Tagphase eingestellt. \r\nEin EC-Ventilator wird unterhalb von 10% abgeschaltet. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Intraction EC MIN/MAX jour',
        full: "Intraction EC MIN/MAX Tag \r\nCette option permet de définir la plage de vitesse autorisée des ventilateurs d'intraction EC pendant la phase de jour. \r\nUn ventilateur EC est arrêté en dessous de 10%. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTÈME>RÉGLAGES DU MENU>Affichage du menu Ventilateurs'.",
        image: ''
      },
      es: {
        brief: 'Suministro aire EC MIN/MAX día',
        full: "Aire de impulsión EC MIN/MAX día \r\nEsto ajusta el rango de velocidad permitido de los ventiladores de aire de impulsión EC durante la fase diurna. Un ventilador EC se desconecta por debajo del 10%. \r\nLa visualización de las opciones de los ventiladores AC, EC o AC&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Menú ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di immissione EC MIN/MAX giorno',
        full: "Aria di immissione EC MIN/MAX giorno \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di immissione EC durante la fase diurna. \r\nUn ventilatore EC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },
    exhaust_fan_ac_min_max_day: {
      en: {
        brief: 'Exhaust AC fans MIN/MAX Day',
        full: "Exhaust AC fans MIN/MAX Day \r\nHere the allowed speed range of the AC exhaust fans during the day phase is set. \r\n An AC exhaust fan is switched off when the value set under 'Advanced'->'Fan AC exhaust turnoff' is undercut.\r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Abluft AC MIN/MAX Tag',
        full: "Abluft AC MIN/MAX Tag \r\nHier wird der erlaubte Drehzahlbereich der AC Abluftventilatoren während der Tagphase eingestellt. \r\nEin AC-Abluft-Ventilator wird abgeschaltet, wenn der Wert, der unter 'Erweitert'->'Abschaltung AC-Ventilator Abluft' eingestellt ist, unterschritten wird. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Extraction AC MIN/MAX jour',
        full: "Extraction AC MIN/MAX Tag \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'extraction AC pendant la phase de jour. \r\nUn ventilateur d'extraction AC est arrêté lorsque la valeur définie dans 'Avancé'->'Arrêt du ventilateur d'extraction AC' n'est pas atteinte. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTÈME>RÉGLAGES DU MENU>Affichage du menu Ventilateurs'.",
        image: ''
      },
      es: {
        brief: 'Aire de escape CA MIN/MAX día',
        full: "Esto ajusta el rango de velocidad permitido de los ventiladores de extracción de aire de CA durante la fase diurna. Un ventilador de extracción de aire de CA se desconecta cuando el valor establecido en 'Avanzado'->'Desconectar ventilador de extracción de aire de CA' está por debajo. \r\nLa visualización de las opciones de los ventiladores AC, EC o AC&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Menú ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di estrazione AC MIN/MAX giorno',
        full: "Aria di estrazione AC MIN/MAX giorno \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di estrazione AC durante la fase diurna. \r\nUn ventilatore AC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },

    intake_fan_ac_min_max_day: {
      en: {
        brief: 'Intake AC fans MIN/MAX Day',
        full: "Intake AC fans MIN/MAX Day \r\nHere the allowed speed range of the AC intake fans during the day phase is set. \r\n An AC exhaust fan is switched off when the value set under 'Advanced'->'Fan AC exhaust turnoff' is undercut.\r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Zuluft AC MIN/MAX Tag',
        full: "Zuluft AC MIN/MAX Tag \r\nHier wird der erlaubte Drehzahlbereich der AC Zuluftventilatoren während der Tagphase eingestellt. \r\nEin AC-Abluft-Ventilator wird abgeschaltet, wenn der Wert, der unter 'Erweitert'->'Abschaltung AC-Ventilator Abluft' eingestellt ist, unterschritten wird. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Intraction CA MIN/MAX jour',
        full: "Intraction CA MIN/MAX jour \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'intraction AC pendant la phase de jour. \r\nUn ventilateur d'extraction AC est arrêté lorsque la valeur définie dans 'Avancé'->'Arrêt du ventilateur d'extraction AC' n'est pas atteinte. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTÈME>RÉGLAGES DU MENU>Affichage du menu Ventilateurs'.",
        image: ''
      },
      es: {
        brief: 'Suministro aire AC MIN/MAX día',
        full: "Esto ajusta el rango de velocidad permitido de los ventiladores de aire de impulsión de CA durante la fase diurna. Un ventilador de extracción de aire de CA se desconecta cuando el valor ajustado en 'Avanzado'->'Desconectar ventilador de extracción de aire de CA' es inferior al valor ajustado. \r\nSi se muestran las opciones de los ventiladores CA, EC, o AC&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Visualización menú ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di immissione AC MIN/MAX giorno',
        full: "Aria di immissione AC MIN/MAX giorno \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di immissione AC durante la fase diurna. \r\nUn ventilatore AC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },
    exhaust_fan_ec_min_max_night: {
      en: {
        brief: 'Exhaust EC fans MIN/MAX Night',
        full: "Exhaust EC fans MIN/MAX Night \r\nHere the allowed speed range of the EC exhaust fans during the night phase is set. \r\nAn EC fan is switched off below 10%. \r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Abluft EC MIN/MAX Nacht',
        full: "Abluft EC MIN/MAX Nacht \r\nHier wird der erlaubte Drehzahlbereich der EC Abluftventilatoren während der Nachtphase eingestellt. \r\nEin EC-Ventilator wird unterhalb von 10% abgeschaltet. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Extraction EC MIN/MAX nuit',
        full: "Extraction EC MIN/MAX nuit \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'extraction EC pendant la phase de nuit. \r\nUn ventilateur EC est arrêté en dessous de 10%. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTÈME>RÉGLAGES DU MENU>Affichage du menu Ventilateurs'.",
        image: ''
      },
      es: {
        brief: 'MÍN/MÁX noche EC de aire de escape',
        full: "MÍN/MÁX noche EC de aire de escape \r\nAjusta el intervalo de velocidad permitido de los ventiladores de aire de escape EC durante la fase nocturna. Un ventilador EC se desconecta por debajo del 10%. \r\nSi se muestran las opciones de los ventiladores CA, EC o CA&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Menú ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di estrazione EC MIN/MAX notte',
        full: "Aria di estrazione EC MIN/MAX notte \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di estrazione EC durante la fase notturna. \r\nUn ventilatore EC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },

    intake_fan_ec_min_max_night: {
      en: {
        brief: 'Intake EC fans MIN/MAX Night',
        full: "Intake EC fans MIN/MAX Night \r\nHere the allowed speed range of the EC intake fans during the night phase is set. \r\nAn EC fan is switched off below 10%. \r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Zuluft EC MIN/MAX Nacht',
        full: "Zuluft EC MIN/MAX Nacht \r\nHier wird der erlaubte Drehzahlbereich der EC Zuluftventilatoren während der Nachtphase eingestellt. \r\nEin EC-Ventilator wird unterhalb von 10% abgeschaltet. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Intraction EC MIN/MAX nuit',
        full: "Intraction EC MIN/MAX nuit \r\nCette option permet de régler la plage de vitesse autorisée des ventilateurs d'intraction EC pendant la phase de nuit. \r\nUn ventilateur EC est arrêté en dessous de 10%. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTEM>MENU REGLAGES>Menu affichage ventilateurs'.",
        image: '',
        image: ''
      },
      es: {
        brief: 'Aire de impulsión EC MÍN/MÁX noche',
        full: "Esto ajusta el rango de velocidad permitido de los ventiladores de suministro de aire EC durante la fase nocturna. Un ventilador EC se desconecta por debajo del 10%. \r\nSi se muestran las opciones de los ventiladores AC, EC, o AC&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Visualización menú ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di immissione EC MIN/MAX notte',
        full: "Ariria di immissione EC MIN/MAX notte \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di immissione EC durante la fase notturna. \r\nUn ventilatore EC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },
    exhaust_fan_ac_min_max_night: {
      en: {
        brief: 'Exhaust AC fans MIN/MAX Night',
        full: "Exhaust AC fans MIN/MAX Night \r\nHere the allowed speed range of the AC exhaust fans during the night phase is set. \r\n An AC exhaust fan is switched off when the value set under 'Advanced'->'Fan AC exhaust turnoff' is undercut.\r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Abluft AC MIN/MAX Nacht',
        full: "Abluft AC MIN/MAX Nacht \r\nHier wird der erlaubte Drehzahlbereich der AC Abluftventilatoren während der Nachtphase eingestellt. \r\nEin AC-Abluft-Ventilator wird abgeschaltet, wenn der Wert, der unter 'Erweitert'->'Abschaltung AC-Ventilator Abluft' eingestellt ist, unterschritten wird. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Extraction AC MIN/MAX nuit',
        full: "Extraction AC MIN/MAX nuit \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'extraction AC pendant la phase nocturne. \r\nUn ventilateur d'extraction AC est arrêté lorsque la valeur définie dans 'Avancé'->'Arrêt du ventilateur d'extraction AC' n'est pas atteinte. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTEME>MENU REGLAGES>Menu affichage ventilateurs'",
        image: ''
      },
      es: {
        brief: 'Aire de escape CA MÍN/MÁX noche',
        full: "Extractor aire CA MIN/MAX noche \r\nAsí se ajusta el rango de velocidad permitido de los extractores de aire CA durante la fase nocturna. Un ventilador de extracción de aire de CA se desconecta cuando el valor ajustado en 'Avanzado'->'Desconectar ventilador de extracción de aire de CA' está por debajo. \r\nSi se muestran las opciones de los ventiladores AC, EC, o CA&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Visualización menú ventiladores'",
        image: ''
      },
      it: {
        brief: 'Aria di estrazione AC MIN/MAX notte',
        full: "Aria di estrazione AC MIN/MAX notte \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di estrazione AC durante la fase notturna. \r\nUn ventilatore AC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },

    intake_fan_ac_min_max_night: {
      en: {
        brief: 'Intake AC fans MIN/MAX Night',
        full: "Intake AC fans MIN/MAX Night \r\nHere the allowed speed range of the AC intake fans during the night phase is set. \r\n An AC exhaust fan is switched off when the value set under 'Advanced'->'Fan AC exhaust turnoff' is undercut.\r\n\r\nWhether the options for AC, EC, or AC&EC fans are displayed depends on the setting 'SYSTEM>MENU SETTINGS>Fan Type Select'.",
        image: ''
      },
      de: {
        brief: 'Zuluft AC MIN/MAX Nacht',
        full: "Zuluft AC MIN/MAX Nacht \r\nHier wird der erlaubte Drehzahlbereich der AC Zuluftventilatoren während der Nachtphase eingestellt. \r\nEin AC-Abluft-Ventilator wird abgeschaltet, wenn der Wert, der unter 'Erweitert'->'Abschaltung AC-Ventilator Abluft' eingestellt ist, unterschritten wird. \r\n\r\nOb die Optionen für AC-, EC-, oder AC&EC-Ventilatoren angezeigt werden, hängt von der Einstellung 'SYSTEM>MENU EINSTELLUNGEN>Menü-Anzeige Ventilatoren' ab.",
        image: ''
      },
      fr: {
        brief: 'Intraction AC MIN/MAX nuit',
        full: "Intraction AC MIN/MAX nuit \r\nCette option permet de définir la plage de vitesse autorisée pour les ventilateurs d'intraction AC pendant la phase de nuit. \r\nUn ventilateur d'extraction AC est arrêté lorsque la valeur définie dans 'Avancé'->'Arrêt du ventilateur d'extraction AC' n'est pas atteinte. \r\n\r\nL'affichage des options pour les ventilateurs AC, EC ou AC&EC dépend du réglage 'SYSTEME>MENU REGLAGES>Menu affichage ventilateurs'",
        image: ''
      },
      es: {
        brief: 'Aire de impulsión CA MÍN/MÁX noche',
        full: "Suministro aire CA MIN/MÁX noche \r\nAsí se ajusta el rango de velocidad permitido de los ventiladores de suministro de aire CA durante la fase nocturna. Un ventilador de extracción de aire de CA se desconecta cuando el valor ajustado en 'Avanzado'->'Desconectar ventilador de extracción de aire de CA' está por debajo. \r\nSi se muestran las opciones de los ventiladores CA, EC o CA&EC depende del ajuste 'SISTEMA>AJUSTES MENU>Visualización menú ventiladores'.",
        image: ''
      },
      it: {
        brief: 'Aria di immissione AC MIN/MAX notte',
        full: "Aria di immissione AC MIN/MAX notte \r\nQui viene impostata la gamma di velocità consentita dei ventilatori di immissione AC durante la fase notturna. \r\nUn ventilatore AC viene spento al di sotto del 10%. \r\n\r\nSe vengono visualizzate le opzioni per i ventilatori AC, EC o AC&EC dipende dall'impostazione 'SISTEMA>IMPOSTAZIONI MENU>Selezione tipo di ventilatore'.",
        image: ''
      }
    },

    deviation_rpm_reduction: {
      en: {
        brief: 'Deviation RPM Reduction',
        full: 'Deviation RPM Reduction \r\nTo prevent the fans from running at maximum speed when the desired temperature (setpoint temperature) is slightly exceeded, the permitted temperature deviation can be set here. \r\nThis function is also called hysteresis by other manufacturers.',
        image: ''
      },
      de: {
        brief: 'Abweichung reduzierte Drehzahl',
        full: 'Abweichung reduzierte Drehzahl \r\nUm zu vermeiden, dass die Lüfter bei einer kleinen  Überschreitung  der  gewünschten Temperatur (Solltemperatur) auf der maximalen Drehzahl laufen, kann hier die erlaubte Temperatur-Abweichung eingestellt werden. \r\nDiese Funktion wird von anderen Herstellern auch als Hysterese bezeichnet.',
        image: ''
      },
      fr: {
        brief: 'Ecart vitesse réduite',
        full: "Ecart vitesse réduite \r\nPour éviter que les ventilateurs ne fonctionnent à la vitesse maximale lorsque la température souhaitée (température de consigne) est légèrement dépassée, il est possible de régler ici l'écart de température autorisé. \r\nCette fonction est également appelée hystérésis par d'autres fabricants",
        image: ''
      },
      es: {
        brief: 'Desviación velocidad reducida',
        full: 'Desviación velocidad reducida \r\nPara evitar que los ventiladores funcionen a máxima velocidad cuando se supera ligeramente la temperatura deseada (temperatura de consigna), aquí se puede ajustar la desviación de temperatura permitida. Esta función también se denomina histéresis en otros fabricantes',
        image: ''
      },
      it: {
        brief: 'Deviazione RPM riduzione',
        full: 'Deviazione RPM riduzione \r\nPer evitare che i ventilatori funzionino alla massima velocità quando la temperatura desiderata (temperatura di setpoint) viene leggermente superata, qui è possibile impostare la deviazione di temperatura consentita. \r\nQuesta funzione è chiamata anche isteresi da altri produttori.',
        image: ''
      }
    },

    pressure_pa: {
      en: {
        brief: 'Negative pressure in pascal',
        full: 'Negative pressure in pascal setpoint \r\nThis value is the negative pressure setpoint used for negative pressure control with our PresSense negative pressure sensor.',
        image: ''
      },
      de: {
        brief: 'Unterdruck in Pascal',
        full: 'Sollwert Unterdruck in Pascal\r\nDieser Wert ist der Unterdruck-Sollwert, der für die Unterdruckregelung mit unserem Unterdruck-Sensor PresSense.',
        image: ''
      },
      fr: {
        brief: 'Pression négative en pascals',
        full: 'Valeur de consigne de la dépression en pascalsNCette valeur est la valeur de consigne de la dépression qui est utilisée pour la régulation de la dépression avec notre capteur de dépression PresSense.',
        image: ''
      },
      es: {
        brief: 'Presión negativa en Pascal',
        full: 'Valor de consigna de presión negativa en Pascal Este valor es el valor de consigna de presión negativa utilizado para el control de presión negativa con nuestro sensor de presión negativa PresSense',
        image: ''
      },
      it: {
        brief: 'Pressione negativa in pascal',
        full: 'Pressione negativa in pascal setpoint \r\nQuesto valore è il setpoint della pressione negativa utilizzato per il controllo della pressione negativa con il nostro sensore di pressione negativa PresSense.',
        image: ''
      }
    },

    pressure_auto_cal: {
      en: {
        brief: 'Auto calibration pressure',
        full: "Auto calibration negative pressure sensor \r\nIf 'Enabled' is selected here, the pressure sensor is automatically calibrated 10 minutes before the end of the night phase. During this process, the fans are switched off for about one minute. (only if the negative pressure sensor is connected)",
        image: ''
      },
      de: {
        brief: 'Auto-Kalibrierung Druck',
        full: 'Auto-Kalibrierung Unterdruck-Sensor r\nWenn hier AN ausgewählt ist, wird der Drucksensor  automatisch  10  Minuten  vor dem Ende der Nachtphase kalibriert. Dabei werden die Ventilatoren für etwa eine Minute  abgeschaltet. (nur  bei  angeschlossenem Unterdrucksensor)',
        image: ''
      },
      fr: {
        brief: 'Auto-étalonnage de la pression',
        full: 'Auto-calibrage du capteur de dépression r\nSi AN est sélectionné ici, le capteur de pression est automatiquement calibré 10 minutes avant la fin de la phase de nuit. Les ventilateurs sont alors arrêtés pendant environ une minute. (uniquement si le capteur de dépression est connecté)',
        image: ''
      },
      es: {
        brief: 'Autocalibración presión',
        full: 'Autocalibración sensor de vacío r\nSi aquí se selecciona ON, el sensor de presión se calibra automáticamente 10 minutos antes del final de la fase nocturna. Los ventiladores se desconectan durante aproximadamente un minuto. (sólo si está conectado el sensor de presión negativa)',
        image: ''
      },
      it: {
        brief: 'Auto calibrazione pressione',
        full: 'Auto calibrazione sensore di pressione \r\nSe qui è selezionato ON, il sensore di pressione viene calibrato automaticamente 10 minuti prima della fine della fase notturna. I ventilatori vengono spenti per circa un minuto. (solo se il sensore di pressione negativa è collegato)',
        image: ''
      }
    },
    caliprate_pressure_trigger: {
      en: {
        brief: 'Calibrate Negative Pressure Sensor',
        full: 'Calibration of Negative Pressure Sensor r\nThe calibration takes about one minute. The fans are switched off during this process. (only with negative pressure sensor connected).',
        image: ''
      },
      de: {
        brief: 'Kalibriereung Unterdrucksensor',
        full: 'Kalibriereung Unterdrucksensor r\nDie Kalibrierung dauert etwa eine Minute. Dabei werden die Ventilatoren abgeschaltet. (nur  bei  angeschlossenem Unterdrucksensor)',
        image: ''
      },
      fr: {
        brief: 'Calibrage du capteur de dépression',
        full: 'Calibrage du capteur de dépression r\nLe calibrage dure environ une minute. Les ventilateurs sont alors arrêtés. (uniquement si le capteur de dépression est connecté)',
        image: ''
      },
      es: {
        brief: 'Calibración del sensor de vacío',
        full: 'Calibración del sensor de vacío r\nLa calibración dura aproximadamente un minuto. Los ventiladores se desconectan durante este tiempo. (sólo si está conectado el sensor de presión negativa)',
        image: ''
      },
      it: {
        brief: 'Calibrazione sensore di pressione negativa',
        full: 'Calibrazione sensore di pressione negativa r\nLa calibrazione dura circa un minuto. I ventilatori vengono spenti durante questo processo. (solo con sensore di pressione negativa collegato).',
        image: ''
      }
    },

    // timing
    controller_time: {
      en: {
        brief: 'Controller time',
        full: 'Controller time',
        image: ''
      },
      de: {
        brief: 'Uhrzeit Controller',
        full: 'Uhrzeit Controller',
        image: ''
      },
      fr: {
        brief: 'Heure contrôleur',
        full: 'heure contrôleur',
        image: ''
      },
      es: {
        brief: 'controlador de tiempo',
        full: 'Controlador de tiempo',
        image: ''
      },
      it: {
        brief: 'Tempo del controllore',
        full: 'Tempo del controllore',
        image: ''
      }
    },
    controller_date: {
      en: {
        brief: 'Controller date',
        full: 'Controller date',
        image: ''
      },
      de: {
        brief: 'Datum Controller',
        full: 'Datum Controller',
        image: ''
      },
      fr: {
        brief: 'contrôleur de date',
        full: 'date contrôleur',
        image: ''
      },
      es: {
        brief: 'controlador de fechas',
        full: 'Controlador de fecha',
        image: ''
      },
      it: {
        brief: 'Data del controllore',
        full: 'Data del controllore',
        image: ''
      }
    },
    start_day: {
      en: {
        brief: 'Start of day period',
        full: "Start of day period \r\nSelect here the time at which an output, for which the function 'Lights' is selected, is to be switched on and accordingly the day phase begins.",
        image: ''
      },
      de: {
        brief: 'Beginn Tag',
        full: "Beginn Tag \r\nWähle hier die Uhrzeit, zu welcher ein Ausgang, für den die Funktion 'Beleuchtung' ausgewählt  ist,  eingeschaltet werden soll und dementsprechend die Tag-Phase beginnt.",
        image: ''
      },
      fr: {
        brief: 'Début de la journée',
        full: "Début du jour\nChoisissez ici l'heure à laquelle une sortie pour laquelle la fonction 'éclairage' est sélectionnée doit être allumée et débuter en conséquence la phase de jour",
        image: ''
      },
      es: {
        brief: 'Día de inicio',
        full: "Seleccione la hora a la que debe encenderse una salida para la que se ha seleccionado la función 'Iluminación' y, en consecuencia, debe comenzar la fase diurna",
        image: ''
      },
      it: {
        brief: 'Inizio del giorno',
        full: "Seleziona qui l'ora in cui deve essere attivata un'uscita, per la quale è selezionata la funzione 'Illuminazione', e di conseguenza inizia la fase diurna.",
        image: ''
      }
    },
    end_day: {
      en: {
        brief: 'End of day period',
        full: "End of day period \r\nSelect here the time at which an output, for which the function 'Lights' is selected, is to be switched off and accordingly the day phase end.",
        image: ''
      },
      de: {
        brief: 'Ende Tag',
        full: "Ende Tag\r\nWähle hier die Uhrzeit, zu welcher ein Ausgang, für den die Funktion 'Zeitschaltuhr' ausgewählt  ist, ausgeschaltet werden soll und dementsprechend die Nacht-Phase beginnt.",
        image: ''
      },
      fr: {
        brief: 'Fin de journée',
        full: "Fin du jour\r\nChoisissez ici l'heure à laquelle une sortie pour laquelle la fonction 'minuterie' est sélectionnée doit être désactivée et, en conséquence, la phase de nuit doit commencer",
        image: ''
      },
      es: {
        brief: 'Fin de día',
        full: "Fin de día\r\nSeleccione aquí la hora a la que debe desconectarse una salida para la que se ha seleccionado la función 'Temporizador' y, en consecuencia, comienza la fase nocturna",
        image: ''
      },
      it: {
        brief: 'Fine del giorno',
        full: "Fine del giorno\r\nSeleziona qui l'ora in cui deve essere disattivata un'uscita, per la quale è selezionata la funzione 'Timer', e di conseguenza inizia la fase notturna.",
        image: ''
      }
    },
    interval_on_day: {
      en: {
        brief: 'Interval timer ON day',
        full: "Interval ON Day \r\nInterval duration for which an outlet/socket set to the 'Interval Timer' function is switched on during the day. \r\nThe interval timer is suitable for switching on and off e.g. irrigation pumps or circulating fans exactly to the second (e.g. 15 seconds ON and 10 minutes OFF during the day and 10 seconds ON and 30 minutes OFF at night). The ON/OFF sequence repeats continuously. The interval timer is suitable if the connected device is to be switched on and off continuously. It is not suitable for switching the connected device at a defined time during the course of the day.",
        image: ''
      },
      de: {
        brief: 'Intervall AN Tag',
        full: "Intervall AN Tag \r\nIntervall-Dauer für die ein Ausgang/Steckdose, die auf die Funktion 'Intervall-Timer' eingestellt ist, am Tag eingeschaltet ist. \r\n\r\nDer Intervall-Timer ist geeignet, um z.B. Bewässerungspumpen oder Umluft-Ventilatoren sekundengenau ein- und wieder auszuschalten (z.B. 15 Sekunden AN und 10 Minuten AUS am Tag und 10 Sekunden AN und 30 Minuten AUS in der Nacht). Der Ablauf AN/AUS wiederholt sich kontinuierlich. Der Intervall-Timer ist geeignet, wenn das angeschlossene Gerät kontinuierlich ein- und ausgeschaltet werden soll. Um das angeschlossene Gerät zu einer definierten Uhrzeit im Tagesverlauf zu schalten, ist er nicht geeignet.",
        image: ''
      },
      fr: {
        brief: 'Intervalle AN jour',
        full: "Intervalle ON jour \r\nDurée de l'intervalle pendant laquelle une sortie/prise réglée sur la fonction 'Interval Timer' est activée dans la journée. \r\n\r\nLa minuterie d'intervalle convient pour mettre en marche et arrêter à la seconde près des pompes d'arrosage ou des ventilateurs de circulation d'air par exemple (par ex. 15 secondes MARCHE et 10 minutes ARRÊT le jour et 10 secondes MARCHE et 30 minutes ARRÊT la nuit). La séquence MARCHE/ARRÊT se répète continuellement. La minuterie à intervalles convient si l'appareil raccordé doit être allumé et éteint en continu. Elle n'est pas adaptée pour allumer l'appareil raccordé à une heure définie au cours de la journée",
        image: ''
      },
      es: {
        brief: 'Intervalo ON Día',
        full: "Duración del intervalo durante el cual se enciende durante el día una toma de corriente/enchufe configurado con la función 'Temporizador de intervalos'. \r\nEl temporizador de intervalos es adecuado para encender y apagar, por ejemplo, bombas de riego o ventiladores de circulación con una precisión de segundos (por ejemplo, 15 segundos ON y 10 minutos OFF durante el día y 10 segundos ON y 30 minutos OFF por la noche). La secuencia de encendido y apagado se repite continuamente. El temporizador de intervalos es adecuado si la unidad conectada debe encenderse y apagarse continuamente. No es adecuado para conectar la unidad conectada a una hora definida durante el transcurso del día",
        image: ''
      },
      it: {
        brief: 'Intervallo ON giorno',
        full: "Intervallo ON giorno \r\nDurata dell'intervallo durante il quale un'uscita/presa impostata sulla funzione 'Timer intervallo' è attivata durante il giorno. \r\n\r\nIl timer a intervalli è adatto per accendere e spegnere ad esempio pompe di irrigazione o ventilatori di circolazione con precisione al secondo (ad esempio 15 secondi ON e 10 minuti OFF durante il giorno e 10 secondi ON e 30 minuti OFF di notte). La sequenza ON/OFF si ripete continuamente. Il timer a intervalli è adatto se il dispositivo collegato deve essere acceso e spento continuamente. Non è adatto per collegare il dispositivo collegato a un'ora definita durante il corso della giornata.",
        image: ''
      }
    },
    interval_off_day: {
      en: {
        brief: 'Interval Pause Day',
        full: "Interval Pause Day \r\nInterval duration for which an outlet/socket set to the 'Interval Timer' function is switched off during the day. \r\nThe interval timer is suitable for switching on and off e.g. irrigation pumps or circulating fans exactly to the second (e.g. 15 seconds ON and 10 minutes OFF during the day and 10 seconds ON and 30 minutes OFF at night). The ON/OFF sequence repeats continuously. The interval timer is suitable if the connected device is to be switched on and off continuously. It is not suitable for switching the connected device at a defined time during the course of the day.",
        image: ''
      },
      de: {
        brief: 'Intervall Pause Tag',
        full: "Intervall Pause Tag \r\nIntervall-Dauer für die ein Ausgang/Steckdose, die auf die Funktion 'Intervall-Timer' eingestellt ist, am Tag ausgeschaltet ist. \r\n\r\nDer Intervall-Timer ist geeignet, um z.B. Bewässerungspumpen oder Umluft-Ventilatoren sekundengenau ein- und wieder auszuschalten (z.B. 15 Sekunden AN und 10 Minuten AUS am Tag und 10 Sekunden AN und 30 Minuten AUS in der Nacht). Der Ablauf AN/AUS wiederholt sich kontinuierlich. Der Intervall-Timer ist geeignet, wenn das angeschlossene Gerät kontinuierlich ein- und ausgeschaltet werden soll. Um das angeschlossene Gerät zu einer definierten Uhrzeit im Tagesverlauf zu schalten, ist er nicht geeignet.",
        image: ''
      },
      fr: {
        brief: 'Intervalle Pause Jour',
        full: "Intervalle Pause Jour \r\nDurée de l'intervalle pendant laquelle une sortie/prise réglée sur la fonction 'Interval Timer' est désactivée dans la journée. \r\n\r\nLa minuterie d'intervalle convient pour mettre en marche et arrêter à la seconde près des pompes d'arrosage ou des ventilateurs de circulation d'air par exemple (par ex. 15 secondes MARCHE et 10 minutes ARRÊT le jour et 10 secondes MARCHE et 30 minutes ARRÊT la nuit). La séquence MARCHE/ARRÊT se répète continuellement. La minuterie à intervalles convient si l'appareil raccordé doit être allumé et éteint en continu. Elle n'est pas adaptée pour allumer l'appareil raccordé à une heure définie au cours de la journée",
        image: ''
      },
      es: {
        brief: 'Intervalo Pausa Día',
        full: "Intervalo Pausa Día \r\nDuración del intervalo durante el cual una toma de corriente/enchufe ajustada a la función 'Temporizador de Intervalos' se apaga durante el día. \r\nEl temporizador de intervalos es adecuado para encender y apagar, por ejemplo, bombas de riego o ventiladores de circulación con una precisión de segundos (por ejemplo, 15 segundos ON y 10 minutos OFF durante el día y 10 segundos ON y 30 minutos OFF por la noche). La secuencia de encendido y apagado se repite continuamente. El temporizador de intervalos es adecuado si la unidad conectada debe encenderse y apagarse continuamente. No es adecuado para conectar la unidad conectada a una hora definida durante el transcurso del día",
        image: ''
      },
      it: {
        brief: 'Intervallo Pausa giorno',
        full: "Intervallo Pausa giorno \r\nDurata dell'intervallo durante il quale una presa configurata sulla funzione 'Timer intervallo' è disattivata durante il giorno. \r\n\r\nIl timer a intervalli è adatto per accendere e spegnere ad esempio pompe di irrigazione o ventilatori di circolazione con precisione al secondo (ad esempio 15 secondi ON e 10 minuti OFF durante il giorno e 10 secondi ON e 30 minuti OFF di notte). La sequenza ON/OFF si ripete continuamente. Il timer a intervalli è adatto se il dispositivo collegato deve essere acceso e spento continuamente. Non è adatto per collegare il dispositivo collegato a un'ora definita durante il corso della giornata.",
        image: ''
      }
    },
    interval_on_night: {
      en: {
        brief: 'Interval ON Night',
        full: "Interval ON Night \r\nThe duration for which an outlet/socket set to the 'Interval Timer' function is switched on at night. \r\nThe interval timer is suitable for switching on and off e.g. irrigation pumps or circulating fans exactly to the second (e.g. 15 seconds ON and 10 minutes OFF during the day and 10 seconds ON and 30 minutes OFF at night). The ON/OFF sequence repeats continuously. The interval timer is suitable if the connected device (e.g. a pump) is to be switched on and off continuously. It is not suitable for switching the connected device at a defined time during the course of the day.",
        image: ''
      },
      de: {
        brief: 'Intervall AN Nacht',
        full: "Intervall AN Nacht \r\nDauer für die ein Ausgang/Steckdose, die auf die Funktion 'Intervall-Timer' eingestellt ist, in der Nacht eingeschaltet ist. \r\n\r\nDer Intervall-Timer ist geeignet, um z.B. Bewässerungspumpen oder Umluft-Ventilatoren sekundengenau ein- und wieder auszuschalten (z.B. 15 Sekunden AN und 10 Minuten AUS am Tag und 10 Sekunden AN und 30 Minuten AUS in der Nacht). Der Ablauf AN/AUS wiederholt sich kontinuierlich. Der Intervall-Timer ist geeignet, wenn das angeschlossene Gerät (z.B. eine Pumpe) kontinuierlich ein- und ausgeschaltet werden soll. Um das angeschlossene Gerät zu einer definierten Uhrzeit im Tagesverlauf zu schalten, ist er nicht geeignet.",
        image: ''
      },
      fr: {
        brief: 'Intervalle AN nuit',
        full: "Intervalle ON Nuit \r\nDurée pendant laquelle une sortie/prise réglée sur la fonction 'Interval Timer' est activée la nuit. \r\n\r\nLa minuterie d'intervalle convient pour mettre en marche et arrêter à la seconde près des pompes d'arrosage ou des ventilateurs de circulation d'air par exemple (par ex. 15 secondes MARCHE et 10 minutes ARRÊT le jour et 10 secondes MARCHE et 30 minutes ARRÊT la nuit). La séquence MARCHE/ARRÊT se répète continuellement. La minuterie à intervalles convient si l'appareil raccordé (par ex. une pompe) doit être mis en marche et arrêté en continu. Elle n'est pas adaptée pour allumer l'appareil raccordé à une heure définie au cours de la journée",
        image: ''
      },
      es: {
        brief: 'Intervalo ON Noche',
        full: 'El temporizador de intervalos es adecuado para encender y apagar, por ejemplo, bombas de riego o ventiladores de circulación. \r\nEl temporizador de intervalos es adecuado para encender y apagar, por ejemplo, bombas de riego o ventiladores de circulación con una precisión de segundos (por ejemplo, 15 segundos ON y 10 minutos OFF durante el día y 10 segundos ON y 30 minutos OFF por la noche). La secuencia de encendido y apagado se repite continuamente. El temporizador de intervalos es adecuado si el dispositivo conectado (por ejemplo, una bomba) debe encenderse y apagarse continuamente. No es adecuado para conectar la unidad conectada a una hora definida durante el transcurso del día',
        image: ''
      },
      it: {
        brief: 'Intervallo ON notte',
        full: "Intervallo ON notte \r\nDurata dell'intervallo durante il quale una presa configurata sulla funzione 'Timer intervallo' è attivata di notte. \r\n\r\nIl timer a intervalli è adatto per accendere e spegnere ad esempio pompe di irrigazione o ventilatori di circolazione con precisione al secondo (ad esempio 15 secondi ON e 10 minuti OFF durante il giorno e 10 secondi ON e 30 minuti OFF di notte). La sequenza ON/OFF si ripete continuamente. Il timer a intervalli è adatto se il dispositivo collegato deve essere acceso e spento continuamente. Non è adatto per collegare il dispositivo collegato a un'ora definita durante il corso della giornata.",
        image: ''
      }
    },
    interval_off_night: {
      en: {
        brief: 'Interval Pause Night',
        full: "Interval Pause Night \r\nDuration for which an outlet/socket set to the 'Interval timer' function is switched off at night. \r\nThe interval timer is suitable for switching on and off e.g. irrigation pumps or circulating fans exactly to the second (e.g. 15 seconds ON and 10 minutes OFF during the day and 10 seconds ON and 30 minutes OFF at night). The ON/OFF sequence repeats continuously. The interval timer is suitable if the connected device (e.g. a pump) is to be switched on and off continuously. It is not suitable for switching the connected device at a defined time during the course of the day.",
        image: ''
      },
      de: {
        brief: 'Intervall Pause Nacht',
        full: "Intervall Pause Nacht \r\nDauer für die ein Ausgang/Steckdose, die auf die Funktion 'Intervall-Timer' eingestellt ist, in der Nacht ausgeschaltet ist. \r\n\r\nDer Intervall-Timer ist geeignet, um z.B. Bewässerungspumpen oder Umluft-Ventilatoren sekundengenau ein- und wieder auszuschalten (z.B. 15 Sekunden AN und 10 Minuten AUS am Tag und 10 Sekunden AN und 30 Minuten AUS in der Nacht). Der Ablauf AN/AUS wiederholt sich kontinuierlich. Der Intervall-Timer ist geeignet, wenn das angeschlossene Gerät (z.B. eine Pumpe) kontinuierlich ein- und ausgeschaltet werden soll. Um das angeschlossene Gerät zu einer definierten Uhrzeit im Tagesverlauf zu schalten, ist er nicht geeignet.",
        image: ''
      },
      fr: {
        brief: 'Intervalle Pause Nuit',
        full: "Intervalle Pause Nuit \r\nDurée pendant laquelle une sortie/prise réglée sur la fonction 'Interval Timer' est désactivée pendant la nuit. \r\n\r\nLa minuterie d'intervalle convient pour mettre en marche et arrêter à la seconde près des pompes d'arrosage ou des ventilateurs de circulation d'air par exemple (par ex. 15 secondes MARCHE et 10 minutes ARRÊT le jour et 10 secondes MARCHE et 30 minutes ARRÊT la nuit). La séquence MARCHE/ARRÊT se répète continuellement. La minuterie à intervalles convient si l'appareil raccordé (par ex. une pompe) doit être mis en marche et arrêté en continu. Elle n'est pas adaptée pour allumer l'appareil raccordé à une heure définie au cours de la journée",
        image: ''
      },
      es: {
        brief: 'Intervalo pausa noche',
        full: 'El temporizador de intervalos es adecuado para encender y apagar, por ejemplo, bombas de riego o ventiladores de circulación de aire exactamente al segundo (por ejemplo, 15 segundos ON y 10 minutos OFF durante la noche). \r\nEl temporizador de intervalos es adecuado para encender y apagar, por ejemplo, bombas de riego o ventiladores de aire circulante con una precisión de segundos (por ejemplo, 15 segundos ON y 10 minutos OFF durante el día y 10 segundos ON y 30 minutos OFF por la noche). La secuencia ON/OFF se repite continuamente. El temporizador de intervalos es adecuado si el dispositivo conectado (por ejemplo, una bomba) debe encenderse y apagarse continuamente. No es adecuado para conmutar el dispositivo conectado a una hora definida durante el transcurso del día.',
        image: ''
      },
      it: {
        brief: 'Intervallo OFF notte',
        full: "Intervallo OFF notte \r\nDurata dell'intervallo durante il quale una presa configurata sulla funzione 'Timer intervallo' è disattivata di notte. \r\n\r\nIl timer a intervalli è adatto per accendere e spegnere ad esempio pompe di irrigazione o ventilatori di circolazione con precisione al secondo (ad esempio 15 secondi ON e 10 minuti OFF durante il giorno e 10 secondi ON e 30 minuti OFF di notte). La sequenza ON/OFF si ripete continuamente. Il timer a intervalli è adatto se il dispositivo collegato deve essere acceso e spento continuamente. Non è adatto per collegare il dispositivo collegato a un'ora definita durante il corso della giornata.",
        image: ''
      }
    },
    'Event Starttime': {
      en: {
        brief: 'Event Start',
        full: 'Event Start\r\nFor each of the event timers an event start time as well as an event duration can be set (e.g. Event 1 start 14:30, Event 1 duration 00:10:00 hh:mm:ss). \r\n\r\nOne or more events can be used to switch a socket on the controller (e.g. Function Out 1: Event 1, Function Out 1: Event 1&2&3 or Function Out 1: Event 3&4).',
        image: ''
      },
      de: {
        brief: 'Beginn Event',
        full: 'Event Start\r\nFür jeden der Event-Timer kann eine Event-Start-Uhrzeit sowie eine Event-Dauer eingestellt werden (z.B. Event 1 Start 14:30 Uhr, Event 1 Dauer 00:10:00 hh:mm:ss). Außerdem kann der Ausführungsrythmus vorgegeben werden \r\n\r\nEs können ein oder mehrere Events zum Schalten einer Steckdose am Controller verwendet werden (z.B. Funktion Out 1: Event 1, Funktion Out 1: Event 1-3 oder Funktion Out 1: Event 3&4).',
        image: ''
      },
      fr: {
        brief: "Début de l'événement",
        full: "Event Start\rPour chacune des minuteries d'événement, il est possible de régler une heure de début d'événement ainsi qu'une durée d'événement (par ex. Event 1 Start 14:30, Event 1 Durée 00:10:00 hh:mm:ss). \r\n\r\nUn ou plusieurs événements peuvent être utilisés pour commuter une prise de courant sur le contrôleur (par ex. fonction Out 1 : événement 1, fonction Out 1 : événement 1-3 ou fonction Out 1 : événement 3&4)",
        image: ''
      },
      es: {
        brief: 'Inicio de Evento',
        full: 'Para cada uno de los temporizadores de eventos, se puede establecer una hora de inicio y una duración (por ejemplo, Evento 1 Inicio 14:30, Evento 1 Duración 00:10:00 hh:mm:ss). Se pueden utilizar uno o varios eventos para conmutar una toma del controlador (por ejemplo, función Salida 1: Evento 1, función Salida 1: Evento 1-3 o función Salida 1: Evento 3&4)',
        image: ''
      },
      it: {
        brief: 'Inizio Evento',
        full: "Inizio Evento\r\nPer ogni timer di evento è possibile impostare un'ora di inizio evento e una durata dell'evento (ad esempio, Evento 1 Inizio alle 14:30, Durata evento 00:10:00 hh:mm:ss). Inoltre, è possibile specificare il ritmo di esecuzione \r\n\r\nUn o più eventi possono essere utilizzati per controllare una presa sul controller (ad esempio, Funzione Uscita 1: Evento 1, Funzione Uscita 1: Evento 1-3 o Funzione Uscita 1: Evento 3 e 4).",
        image: ''
      }
    },
    'Event Duration': {
      en: {
        brief: 'Event Duration',
        full: 'Event Duration \r\nThis setting determines the duration for which the respective event is active.',
        image: ''
      },
      de: {
        brief: 'Dauer Event',
        full: 'Dauer Event  \r\nDiese Einstellung bestimmt, für welche Dauer das jeweilige Event aktiv ist.',
        image: ''
      },
      fr: {
        brief: 'Événement de durée',
        full: "Événement de durée \r\nCe paramètre détermine la durée pendant laquelle l'événement respectif est actif.",
        image: ''
      },
      es: {
        brief: 'Evento de duración',
        full: 'Evento de duración \r\nEsta configuración determina la duración durante la cual el evento respectivo está activo.',
        image: ''
      },
      it: {
        brief: 'Evento di durata',
        full: "Evento di durata \r\nQuesta impostazione determina la durata per la quale l'evento rispettivo è attivo.",
        image: ''
      }
    },
    event1_exec_gap: {
      en: {
        brief: 'Event Execution Rhythm',
        full: 'Event Execution Rhythm \r\nThis setting determines whether the event is executed every day, every other day up to every 28th day. \r\n\r\nBackground info: If a certain event has been executed to the end, the current date is saved. In the future, based on this saved date and the set Execution Rhythm, it will be decided whether the event should be executed again. In the Advanced Settings section under Reset Event Dates all saved date entries can be deleted.',
        image: ''
      },
      de: {
        brief: 'Event Ausführungs-Rhythmus',
        full: 'Event Ausführungs-Rhythmus \r\nDiese Einstellung bestimmt, ob das Event jeden Tag, jeden zweiten Tag oder bis hin zu jeden 28. Tag ausgeführt wird. \r\n\r\nHintergrund-Info: Wenn ein bestimmtes Event bis zum Ende ausgeführt wurde, wird das aktuelle Datum gespeichert. Zukünftig wird aufgrund dieses gespeicherten Datums und des eingestellten Ausführungs-Rhythmus entschieden, ob das Event wieder ausgeführt werden soll. Im Bereich Erweiterte Einstellungen unter Reset Event Datum können alle gespeicherten Datums-Einträge gelöscht werden.',
        image: ''
      },
      fr: {
        brief: "Rythme d'exécution de l'événement",
        full: "Rythme d'exécution de l'événement \r\nCe paramètre détermine si l'événement est exécuté tous les jours, tous les deux jours ou jusqu'à tous les 28 jours. \r\n\r\nInfo d'arrière-plan : si un événement donné a été exécuté jusqu'à la fin, la date actuelle est enregistrée. A l'avenir, il sera décidé sur la base de cette date enregistrée et du rythme d'exécution défini si l'événement doit être à nouveau exécuté. Dans la zone Paramètres avancés, sous Réinitialiser la date de l'événement, il est possible d'effacer toutes les entrées de date enregistrées",
        image: ''
      },
      es: {
        brief: 'Frecuencia de ejecución de eventos',
        full: 'Frecuencia de ejecución de eventos \r\nEsta configuración determina si el evento se ejecuta cada día, cada dos días o hasta cada 28 días. \r\nInformación de fondo: Si un determinado evento se ha ejecutado hasta el final, se guardará la fecha actual. En el futuro, en función de esta fecha guardada y del ritmo de ejecución establecido, se decide si el evento debe ejecutarse de nuevo. En la sección Ajustes Avanzados, en Restablecer Fecha de Evento, se pueden borrar todas las entradas de fechas guardadas.',
        image: ''
      },
      it: {
        brief: "Ritmo di esecuzione dell'evento",
        full: "Ritmo di esecuzione dell'evento \r\nQuesta impostazione determina se l'evento viene eseguito ogni giorno, ogni due giorni o fino a ogni 28 giorni. \r\n\r\nInformazione di background: una volta che un determinato evento è stato completato, la data corrente viene registrata. In futuro, in base a questa data registrata e al ritmo di esecuzione impostato, verrà deciso se l'evento deve essere eseguito nuovamente. Nella sezione Impostazioni avanzate, sotto Resetta data dell'evento, è possibile eliminare tutte le voci di data registrate.",
        image: ''
      }
    },

    // lights
    lights_max: {
      en: {
        brief: 'Lights MAX 0-10/1-10V',
        full: 'Lights MAX 0-10/1-10V',
        image: ''
      },
      de: {
        brief: 'Beleuchtung MAX 0-10/1-10V',
        full: 'Beleuchtung MAX 0-10/1-10V',
        image: ''
      },
      fr: {
        brief: 'Éclairage MAX 0-10/1-10V',
        full: 'Eclairage MAX 0-10/1-10V',
        image: ''
      },
      es: {
        brief: 'Iluminación MAX 0-10/1-10V',
        full: 'Iluminación MAX 0-10/1-10V',
        image: ''
      },
      it: {
        brief: 'Illuminazione MAX 0-10/1-10V',
        full: 'Illuminazione MAX 0-10/1-10V',
        image: ''
      }
    },
    lights_min: {
      en: {
        brief: 'Lights MIN 0-10/1-10V',
        full: 'Lights MIN 0-10/1-10V \r\nThe minimum dimming value of the connected lights (0-10/1-10V) can be set here. A sunrise starts at this value. A sunset ends at this value before the lighting is switched off.',
        image: ''
      },
      de: {
        brief: 'Beleuchtung MIN 0-10/1-10V',
        full: 'Beleuchtung MIN 0-10/1-10V \r\nHier kann der minimale Dimmwert der angeschlossenen Beleuchtung 0-10/1-10V eingestellt werden. Ein Sonnen-Aufgang beginnt bei diesem Wert. Ein Sonnen-Untergang endet bei diesem Wert, bevor die Beleuchtung abgeschaltet wird.',
        image: ''
      },
      fr: {
        brief: 'Eclairage MIN 0-10/1-10V',
        full: "Eclairage MIN 0-10/1-10V\nIl est possible de régler ici la valeur minimale de variation de l'éclairage 0-10/1-10V raccordé. Une montée de soleil commence à cette valeur. Un coucher de soleil se termine à cette valeur avant que l'éclairage ne soit éteint",
        image: ''
      },
      es: {
        brief: 'Iluminación MIN 0-10/1-10V',
        full: 'Iluminación MIN 0-10/1-10V \r\nAquí se puede ajustar el valor mínimo de atenuación de la iluminación 0-10/1-10V conectada. Un amanecer comienza en este valor. Una puesta de sol finaliza en este valor antes de que se apague la iluminación',
        image: ''
      },
      it: {
        brief: 'Illuminazione MIN 0-10/1-10V',
        full: "Illuminazione MIN 0-10/1-10V \r\nQui è possibile impostare il valore minimo di attenuazione delle luci 0-10/1-10V collegate. Un'alba inizia a questo valore. Un tramonto termina a questo valore prima che le luci vengano spente.",
        image: ''
      }
    },
    light_sunrise_duration: {
      en: {
        brief: 'Duration Sunrise&Sunset',
        full: 'Duration Sunrise&Sunset (0-10/1-10V)',
        image: ''
      },
      de: {
        brief: 'Dauer Sonnenauf- und Untergang',
        full: 'Dauer Sonnenauf- und Untergang (0-10/1-10V)',
        image: ''
      },
      fr: {
        brief: 'Durée du lever et du coucher du soleil',
        full: 'Durée du lever et du coucher du soleil (0-10/1-10V)',
        image: ''
      },
      es: {
        brief: 'Duración de la salida y puesta del sol',
        full: 'Duración de la salida y puesta del sol (0-10/1-10V)',
        image: ''
      },
      it: {
        brief: "Durata dell'alba e del tramonto",
        full: "Durata dell'alba e del tramonto (0-10/1-10V)",
        image: ''
      }
    },
    lights_dim_over_temp_enable: {
      en: {
        brief: 'Dimm or switch off lighting at high temperature',
        full: 'Dimm or switch off lighting at high temperature \r\nOn particularly hot days or in cases where fans or air conditioning fail, damage to the plants can be limited or avoided by dimming or switching off the lighting. This function can be switched on here. \r\n\r\nThe overtemperature can be set separately for the day and CO2 phases. If the respective overtemperature is exceeded for an adjustable period of time, the lighting is reduced in three adjustable phases/steps. \r\n\r\nProcedure: \r\n1. Overtemperature is present \r\n2. Lighting is dimmed to the value from phase 1 \r\n3. If after waiting time the temperature is still above the set overtemperature, it is dimmed to the value from phase 2 \r\n4. If after waiting time the temperature is still above the set overtemperature, it is dimmed to the value from phase 3 \r\n\r\nOnly when the temperature falls back below an adjustable threshold is the lighting dimmed back to the permitted maximum brightness or switched back on again',
        image: ''
      },
      de: {
        brief: 'Beleuchtung dimmen oder abschalten bei zu hoher Temperatur',
        full: 'Beleuchtung dimmen oder abschalten bei zu hoher Temperatur \r\nAn besonders heißen Tagen oder in Fällen, in denen Ventilatoren oder Klimaanlagen versagen, kann durch Dimmen bzw. Abschalten der Beleuchtung Schaden an den Pflanzen begrenzt oder vermieden werden. Diese Funktion kann hier eingeschaltet werden. \r\n\r\nDie Übertemperatur kann separat für die Phasen Tag und CO2 eingestellt werden. Wenn die jeweilige Übertemperatur über eine einstellbare Zeitdauer überschritten wird, wird die Beleuchtung in drei instellbaren Phasen/Stufen heruntergefahren. \r\n\r\nAblauf: \r\n1. Übertemperatur liegt vor \r\n2. Beleuchtung wird auf den Wert aus Phase 1 gedimmt \r\n3. Wenn nach Wartezeit die Temperatur immer noch über der eingestellten Übertemperatur liegt, wird auf den Wert aus Phase 2 gedimmt \r\n4. Wenn nach Wartezeit die Temperatur immer noch über der eingestellten Übertemperatur liegt, wird auf den Wert aus Phase 3 gedimmt \r\n\r\nErst wenn die Temperatur wieder unter eine einstellbare Schwelle fällt, wird die Beleuchtung wieder auf die erlaubte maximale Helligkeit gedimmt bzw. wieder eingeschaltet.',
        image: ''
      },
      fr: {
        brief: "Variation ou extinction de l'éclairage en cas de température élevée",
        full: "Variation ou extinction de l'éclairage en cas de température élevée \r\nLors de journées particulièrement chaudes ou dans les cas où les ventilateurs ou la climatisation sont défaillants, la variation ou l'extinction de l'éclairage peut limiter ou empêcher les dommages aux plantes. Cette fonction peut être activée ici. \r\n\r\nLa température excessive peut être réglée séparément pour les phases jour et CO2. Si la température excessive respective est dépassée pendant une durée réglable, l'éclairage est réduit en trois phases/niveaux réglables. \r\n\r\nProcédure : \r\n1. Température excessive \r\n2. L'éclairage est réduit à la valeur de la phase 1 \r\n3. Si, après le délai d'attente, la température est toujours supérieure à la température excessive réglée, elle est réduite à la valeur de la phase 2 \r\n4. Si, après le délai d'attente, la température est toujours supérieure à la température excessive réglée, elle est réduite à la valeur de la phase 3 \r\n\r\nCe n'est que lorsque la température tombe à nouveau en dessous d'un seuil réglable que l'éclairage est à nouveau réduit à la luminosité maximale autorisée ou rallumé.",
        image: ''
      },
      es: {
        brief: 'Atenuar o apagar la iluminación a altas temperaturas',
        full: 'Atenuar o apagar la iluminación a altas temperaturas \r\nEn días especialmente calurosos o en casos en los que los ventiladores o el aire acondicionado fallan, atenuar o apagar la iluminación puede limitar o evitar daños a las plantas. Esta función se puede activar aquí. \r\n\r\nLa temperatura excesiva se puede ajustar por separado para las fases de día y CO2. Si la temperatura excesiva respectiva se supera durante un período de tiempo ajustable, la iluminación se reduce en tres fases/niveles ajustables. \r\n\r\nProcedimiento: \r\n1. Temperatura excesiva \r\n2. La iluminación se reduce al valor de la fase 1 \r\n3. Si, después del tiempo de espera, la temperatura sigue siendo superior a la temperatura excesiva ajustada, se reduce al valor de la fase 2 \r\n4. Si, después del tiempo de espera, la temperatura sigue siendo superior a la temperatura excesiva ajustada, se reduce al valor de la fase 3 \r\n\r\nSólo cuando la temperatura vuelve a caer por debajo de un umbral ajustable, la iluminación se reduce de nuevo a la luminosidad máxima permitida o se vuelve a encender.',
        image: ''
      },
      it: {
        brief: "Dimmerare o spegnere l'illuminazione a temperature elevate",
        full: "Dimmerare o spegnere l'illuminazione a temperature elevate \r\nIn giornate particolarmente calde o nei casi in cui i ventilatori o l'aria condizionata non funzionano, la riduzione o lo spegnimento dell'illuminazione può limitare o prevenire i danni alle piante. Questa funzione può essere attivata qui. \r\n\r\nLa temperatura eccessiva può essere regolata separatamente per le fasi giorno e CO2. Se la temperatura eccessiva rispettiva viene superata per un periodo di tempo regolabile, l'illuminazione viene ridotta in tre fasi/livelli regolabili. \r\n\r\nProcedura: \r\n1. Temperatura eccessiva \r\n2. L'illuminazione viene ridotta al valore della fase 1 \r\n3. Se, dopo il tempo di attesa, la temperatura rimane superiore alla temperatura eccessiva impostata, viene ridotta al valore della fase 2 \r\n4. Se, dopo il tempo di attesa, la temperatura rimane ancora superiore alla temperatura eccessiva impostata, viene ridotta al valore della fase 3 \r\n\r\nSolo quando la temperatura torna a scendere al di sotto di una soglia regolabile, l'illuminazione viene nuovamente ridotta alla luminosità massima consentita o riattivata.",
        image: ''
      }
    },
    lights_dim_over_temp_temp_day: {
      en: {
        brief: 'Overtemperature day',
        full: 'Overtemperature day \r\nHere the overtemperature for the day phase can be set.',
        image: ''
      },
      de: {
        brief: 'Übertemperatur Tag',
        full: 'Übertemperatur Tag \r\n Hier kann die Übertemperatur für die Tagphase eingestellt werden.',
        image: ''
      },
      fr: {
        brief: 'Température excessive jour',
        full: 'Température excessive jour \r\nIci, la température excessive pour la phase jour peut être réglée.',
        image: ''
      },
      es: {
        brief: 'Temperatura excesiva día',
        full: 'Temperatura excesiva día \r\nAquí se puede ajustar la temperatura excesiva para la fase diurna.',
        image: ''
      },
      it: {
        brief: 'Temperatura eccessiva giorno',
        full: 'Temperatura eccessiva giorno \r\nQui è possibile impostare la temperatura eccessiva per la fase diurna.',
        image: ''
      }
    },
    lights_dim_over_temp_temp_co2: {
      en: {
        brief: 'Overtemperature CO₂',
        full: 'Overtemperature CO₂ \r\nHere the overtemperature for the CO₂ phase can be set',
        image: ''
      },
      de: {
        brief: 'Übertemperatur CO₂',
        full: 'Übertemperatur CO₂ \r\nHier kann die Übertemperatur für die CO₂-Phase eingestellt werden',
        image: ''
      },
      fr: {
        brief: 'Température excessive CO₂',
        full: 'Température excessive CO₂ \r\nIci, la température excessive pour la phase CO₂ peut être réglée',
        image: ''
      },
      es: {
        brief: 'Temperatura excesiva CO₂',
        full: 'Temperatura excesiva CO₂ \r\nAquí se puede ajustar la temperatura excesiva para la fase CO₂',
        image: ''
      },
      it: {
        brief: 'Temperatura eccessiva CO₂',
        full: 'Temperatura eccessiva CO₂ \r\nQui è possibile impostare la temperatura eccessiva per la fase CO₂',
        image: ''
      }
    },
    lights_dim_over_temp_reduct_ph1: {
      en: {
        brief: 'Dimming level phase 1',
        full: "Dimming level phase 1 \r\nHere the dimming level for phase 1 can be set. \r\nThe value cannot be greater than 'Lighting MAX'.",
        image: ''
      },
      de: {
        brief: 'Beleuchtungsstärke Phase 1',
        full: "Beleuchtungsstärke Phase 1 \r\nHier kann die Beleuchtungsstärke für die Phase 1 eingestellt werden. \r\nDer Wert kann nicht größer als 'Beleuchtung MAX' sein.",
        image: ''
      },
      fr: {
        brief: "Niveau d'éclairage phase 1",
        full: "Niveau d'éclairage phase 1 \r\nIci, le niveau d'éclairage pour la phase 1 peut être réglé. \r\nLa valeur ne peut pas être supérieure à 'Éclairage MAX'.",
        image: ''
      },
      es: {
        brief: 'Nivel de iluminación fase 1',
        full: "Nivel de iluminación fase 1 \r\nAquí se puede ajustar el nivel de iluminación para la fase 1. \r\nEl valor no puede ser mayor que 'Iluminación MAX'.",
        image: ''
      },
      it: {
        brief: 'Livello di illuminazione fase 1',
        full: "Livello di illuminazione fase 1 \r\nQui è possibile impostare il livello di illuminazione per la fase 1. \r\nIl valore non può essere superiore a 'Illuminazione MAX'.",
        image: ''
      }
    },
    lights_dim_over_temp_reduct_ph2: {
      en: {
        brief: 'Dimming level phase 2',
        full: 'Dimming level phase 2 \r\nHere the dimming level for phase 2 can be set. \r\nThe value cannot be greater than the value from phase 1.',
        image: ''
      },
      de: {
        brief: 'Beleuchtungsstärke Phase 2',
        full: 'Beleuchtungsstärke Phase 2 \r\nHier kann die Beleuchtungsstärke für die Phase 2 eingestellt werden. \r\nDer Wert kann nicht größer als der Wert aus Phase 1 sein.',
        image: ''
      },
      fr: {
        brief: "Niveau d'éclairage phase 2",
        full: "Niveau d'éclairage phase 2 \r\nIci, le niveau d'éclairage pour la phase 2 peut être réglé. \r\nLa valeur ne peut pas être supérieure à la valeur de la phase 1.",
        image: ''
      },
      es: {
        brief: 'Nivel de iluminación fase 2',
        full: 'Nivel de iluminación fase 2 \r\nAquí se puede ajustar el nivel de iluminación para la fase 2. \r\nEl valor no puede ser mayor que el valor de la fase 1.',
        image: ''
      },
      it: {
        brief: 'Livello di illuminazione fase 2',
        full: 'Livello di illuminazione fase 2 \r\nQui è possibile impostare il livello di illuminazione per la fase 2. \r\nIl valore non può essere superiore al valore della fase 1.',
        image: ''
      }
    },
    lights_dim_over_temp_reduct_ph3: {
      en: {
        brief: 'Dimming level phase 3',
        full: 'Dimming level phase 3 \r\nHere the dimming level for phase 3 can be set. \r\nThe value cannot be greater than the value from phase 2.',
        image: ''
      },
      de: {
        brief: 'Beleuchtungsstärke Phase 3',
        full: 'Beleuchtungsstärke Phase 3 \r\nHier kann die Beleuchtungsstärke für die Phase 3 eingestellt werden. \r\nDer Wert kann nicht größer als der Wert aus Phase 2 sein.',
        image: ''
      },
      fr: {
        brief: "Niveau d'éclairage phase 3",
        full: "Niveau d'éclairage phase 3 \r\nIci, le niveau d'éclairage pour la phase 3 peut être réglé. \r\nLa valeur ne peut pas être supérieure à la valeur de la phase 2.",
        image: ''
      },
      es: {
        brief: 'Nivel de iluminación fase 3',
        full: 'Nivel de iluminación fase 3 \r\nAquí se puede ajustar el nivel de iluminación para la fase 3. \r\nEl valor no puede ser mayor que el valor de la fase 2.',
        image: ''
      },
      it: {
        brief: 'Livello di illuminazione fase 3',
        full: 'Livello di illuminazione fase 3 \r\nQui è possibile impostare il livello di illuminazione per la fase 3. \r\nIl valore non può essere superiore al valore della fase 2.',
        image: ''
      }
    },
    lights_dim_over_temp_reduct_wait_minute: {
      en: {
        brief: 'Wait time',
        full: 'Wait time \r\nHere the time is set how long the dimming level should be maintained after the temperature has dropped below the set value.',
        image: ''
      },
      de: {
        brief: 'Dauer der einzelnen Phasen',
        full: 'Dauer der einzelnen Phasen \r\n Wenn nach ablauf dieser Zeit die Temperatur immer noch zu hoch ist, wird die nächste Phase aktiviert.',
        image: ''
      },
      fr: {
        brief: 'Durée des phases individuelles',
        full: 'Durée des phases individuelles \r\nSi la température est toujours trop élevée après cette période, la phase suivante est activée.',
        image: ''
      },
      es: {
        brief: 'Duración de las fases individuales',
        full: 'Duración de las fases individuales \r\nSi la temperatura sigue siendo demasiado alta después de este tiempo, se activa la siguiente fase.',
        image: ''
      },
      it: {
        brief: 'Durata delle singole fasi',
        full: 'Durata delle singole fasi \r\nSe la temperatura è ancora troppo alta dopo questo periodo, viene attivata la fase successiva.',
        image: ''
      }
    },

    // system
    temp_format: {
      en: {
        brief: 'Temperature Unit',
        full: 'Temperature Unit \r\nThis setting is currently only relevant for the controller menu.',
        image: ''
      },
      de: {
        brief: 'Temperatur-Einheit',
        full: 'Temperatur-Einheit \r\nDiese Einstellung ist aktuell nur für das Controllermenü relevant.',
        image: ''
      },
      fr: {
        brief: 'Unité de température',
        full: "Unité de température \r\nCe paramètre n'est actuellement pertinent que pour le menu du contrôleur",
        image: ''
      },
      es: {
        brief: 'Unidad de temperatura',
        full: 'Unidad de temperatura \r\nEste ajuste sólo es relevante actualmente para el menú del controlador',
        image: ''
      },
      it: {
        brief: 'Unità di temperatura',
        full: 'Unità di temperatura \r\nQuesta impostazione è attualmente rilevante solo per il menu del controller.',
        image: ''
      }
    },
    date_format: {
      en: {
        brief: 'Date Format',
        full: 'Date Format \r\nThis setting is currently only relevant for the controller menu.',
        image: ''
      },
      de: {
        brief: 'Format Datum',
        full: 'Format Datum \r\nDiese Einstellung ist aktuell nur für das Controllermenü relevant.',
        image: ''
      },
      fr: {
        brief: 'Format date',
        full: "Format dateNCe paramètre n'est actuellement pertinent que pour le menu du contrôleur",
        image: ''
      },
      es: {
        brief: 'Formato de fecha',
        full: 'Formato de Fecha \r\nEste ajuste sólo es relevante actualmente para el menú del controlador',
        image: ''
      },
      it: {
        brief: 'Formato data',
        full: 'Formato data \r\nQuesta impostazione è attualmente rilevante solo per il menu del controller.',
        image: ''
      }
    },
    language: {
      en: {
        brief: 'Controller Language',
        full: "Controller language \r\nThis setting is only relevant for the controller menu. (Controller display)\r\nThe language of the web page can be selected via the side menu. To save the current language permanently in the user account, go to 'My Accout' and click on 'APPLY CURRENT LANGUAGE'.",
        image: ''
      },
      de: {
        brief: 'Controller-Sprache',
        full: "Controller-Sprache \r\nDiese Einstellung ist nur für das Menü des Controllers relevant. (Controller-Display)\r\n Die Sprache der Internetseite kann über das seitliche Menü ausgewählt werden. Um die aktuelle Sprache dauerhaft im Benutzer-Konto zu speichern, gehe zu 'Mein Accout' und klicke auf 'AKTUELLE SPRACHE ÜBERNEHMEN'.",
        image: ''
      },
      fr: {
        brief: 'Langue du contrôleur',
        full: "Langue du contrôleur \r\nCe paramètre n'est pertinent que pour le menu du contrôleur. (écran du contrôleur)\r\n La langue de la page Internet peut être sélectionnée via le menu latéral. Pour enregistrer de manière permanente la langue actuelle dans le compte d'utilisateur, aller dans 'Mon compte' et cliquer sur 'MODIFIER LA LANGUE ACTUELLE'.",
        image: ''
      },
      es: {
        brief: 'Idioma del controlador',
        full: "Este ajuste sólo es relevante para el menú del controlador. (El idioma de la página web se puede seleccionar a través del menú lateral. Para guardar permanentemente el idioma actual en la cuenta de usuario, vaya a 'Mi cuenta' y haga clic en 'APLICAR IDIOMA ACTUAL'.",
        image: ''
      },
      it: {
        brief: 'Lingua del controller',
        full: "Lingua del controller \r\nQuesta impostazione è rilevante solo per il menu del controller. (Display del controller)\r\nLa lingua della pagina web può essere selezionata tramite il menu laterale. Per salvare la lingua corrente in modo permanente nell'account utente, vai su 'Il mio account' e fai clic su 'APPLICA LINGUA CORRENTE'.",
        image: ''
      }
    },
    fan_type_select: {
      en: {
        brief: 'Display Setting Fan Type (AC/EC)',
        full: 'Display Setting Fan Type (AC/EC) \r\nThis selection determines whether the menu options for AC, EC or AC&EC fans are displayed. This keeps the display clearer and hides unnecessary items.',
        image: ''
      },
      de: {
        brief: 'Aneige-Einstellung Ventilator-Art (AC/EC)',
        full: 'Aneige-Einstellung Ventilator-Art (AC/EC) \r\nVon dieser Auswahl ist abhängig, ob die Menü-Optionen für AC-, EC- oder AC&EC-Ventilatoren angezeigt werden. Dadurch bleibt die Anzeige übersichtlicher und unnötiges wird ausgeblendet.',
        image: ''
      },
      fr: {
        brief: "Réglage de l'affichage du type de ventilateur (AC/EC)",
        full: "Réglage de l'affichage du type de ventilateur (AC/EC)\rNCette sélection détermine si les options de menu pour les ventilateurs AC, EC ou AC&EC sont affichées. Ainsi, l'affichage reste plus clair et les éléments inutiles sont masqués",
        image: ''
      },
      es: {
        brief: 'Ajuste de pantalla Tipo de ventilador ((CA/EC)',
        full: 'Configuración de pantalla Tipo de ventilador (CA/EC) Esta selección determina si se muestran las opciones de menú de los ventiladores CA, EC o CA&CE. De este modo, la pantalla es más clara y se ocultan los elementos innecesarios',
        image: ''
      },
      it: {
        brief: 'Impostazione del display Tipo di ventilatore (AC/EC) \r\n',
        full: 'Impostazione del display Tipo di ventilatore (AC/EC) \r\nQuesta selezione determina la visualizzazione delle opzioni di menu per i ventilatori AC, EC o AC&EC. In questo modo il display risulta più chiaro e le voci non necessarie vengono nascoste.',
        image: ''
      }
    },
    multi_sensor_handling_rh: {
      en: {
        brief: 'Multi Sensor Calculation Humidity',
        full: 'Multi Sensor Calculation Humidity (Sensor RH/T) \r\nMultiple sensors of the same type can be connected. \r\n Here you can define how the individual sensor values are used.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Multi-Sensor-Berechnung RH',
        full: 'Multi-Sensor-Berechnung RH (Luftfeuchte, Sensor RH/T) \r\nEs können mehrere Sensoren vom gleichen Typ angeschlossen werden. \r\nHier kann eingegeben werden, wie die Messwerte von mehreren Sensoren genutzt werden.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'Calcul multi-capteurs RH',
        full: "Calcul multi-capteurs RH (humidité de l'air, capteur RH/T) \r\nIl est possible de connecter plusieurs capteurs du même type. \r\nIl est possible de saisir ici comment les valeurs de mesure de plusieurs capteurs sont utilisées",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Cálculo multisensor RH',
        full: 'Cálculo multisensor RH (humedad, sensor RH/T) \r\nSe pueden conectar varios sensores del mismo tipo. \r\nSe puede introducir cómo se utilizan los valores medidos de varios sensores',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: 'Calcolo multi-sensore RH',
        full: "Calcolo multi-sensore RH (umidità dell'aria, sensore RH/T) \r\nÈ possibile collegare più sensori dello stesso tipo. \r\nQui è possibile inserire come vengono utilizzati i valori di misura di più sensori.",
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },
    multi_sensor_handling_temp: {
      en: {
        brief: 'Multi Sensor Calculation Room Temperature',
        full: 'Multi Sensor Calculation Room Temperature \r\nMultiple sensors of the same type can be connected. \r\n Here you can define how the individual sensor values are used.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      de: {
        brief: 'Multi-Sensor-Berechnung Raumtemperatur',
        full: 'Multi-Sensor-Berechnung Raumtemperatur (Sensor RH/T) \r\nEs können mehrere Sensoren vom gleichen Typ angeschlossen werden. \r\nHier kann eingegeben werden, wie die Messwerte von mehreren Sensoren genutzt werden.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      fr: {
        brief: 'Calcul multi-capteurs de la température ambiante',
        full: 'Calcul multi-capteurs de la température ambiante (capteur RH/T) \r\nIl est possible de connecter plusieurs capteurs du même type. \r\nIl est possible de saisir ici comment les valeurs de mesure de plusieurs capteurs sont utilisées',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      es: {
        brief: 'Cálculo multisensor temperatura ambiente',
        full: 'Cálculo multisensor temperatura ambiente (sensor RH/T) \r\nSe pueden conectar varios sensores del mismo tipo. \r\nSe puede introducir cómo se utilizan los valores medidos de varios sensores',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      },
      it: {
        brief: 'Calcolo multi-sensore temperatura ambiente',
        full: 'Calcolo multi-sensore temperatura ambiente (sensore RH/T) \r\nÈ possibile collegare più sensori dello stesso tipo. \r\nQui è possibile inserire come vengono utilizzati i valori di misura di più sensori.',
        image: 'https://docs.growcontrol.de/sensRHT_800x.png'
      }
    },
    multi_sensor_handling_co2: {
      en: {
        brief: 'Multi Sensor Calculation CO₂',
        full: 'Multi Sensor Calculation CO₂ \r\nMultiple sensors of the same type can be connected. \r\n Here you can define how the individual sensor values are used.',
        image: ''
      },
      de: {
        brief: 'Multi-Sensor-Berechnung CO₂',
        full: 'Multi-Sensor-Berechnung CO₂ \r\nEs können mehrere Sensoren vom gleichen Typ angeschlossen werden. \r\nHier kann eingegeben werden, wie die Messwerte von mehreren Sensoren genutzt werden.',
        image: ''
      },
      fr: {
        brief: 'Calcul multi-capteurs CO₂',
        full: 'Calcul multi-capteurs CO₂ \r\nIl est possible de connecter plusieurs capteurs du même type. \r\nIl est possible de saisir ici comment les valeurs de mesure de plusieurs capteurs sont utilisées',
        image: ''
      },
      es: {
        brief: 'Cálculo multisensor CO₂',
        full: 'Cálculo multisensor CO₂ \r\nSe pueden conectar varios sensores del mismo tipo. \r\nSe puede introducir cómo se utilizan los valores medidos de varios sensores',
        image: ''
      },
      it: {
        brief: 'Calcolo multi-sensore CO₂',
        full: 'Calcolo multi-sensore CO₂ \r\nÈ possibile collegare più sensori dello stesso tipo. \r\nQui è possibile inserire come vengono utilizzati i valori di misura di più sensori.',
        image: ''
      }
    },
    multi_sensor_handling_pressure: {
      en: {
        brief: 'Multi Sensor Calculation Negative Pressure',
        full: 'Multi Sensor Calculation Negative Pressure \r\nMultiple sensors of the same type can be connected. \r\n Here you can define how the individual sensor values are used.',
        image: ''
      },
      de: {
        brief: 'Multi-Sensor-Berechnung Unterdruck',
        full: 'Multi-Sensor-Berechnung Unterdruck \r\nEs können mehrere Sensoren vom gleichen Typ angeschlossen werden. \r\nHier kann eingegeben werden, wie die Messwerte von mehreren Sensoren genutzt werden.',
        image: ''
      },
      fr: {
        brief: 'Calcul multi-capteurs de dépression',
        full: 'Calcul multi-capteurs de dépression \r\nIl est possible de connecter plusieurs capteurs du même type. \r\nIl est possible de saisir ici comment les valeurs de mesure de plusieurs capteurs sont utilisées',
        image: ''
      },
      es: {
        brief: 'Multi-Sensor Cálculo Presión Negativa',
        full: 'Multi-Sensor-Cálculo Presión Negativa \r\nSe pueden conectar varios sensores del mismo tipo. \r\nSe puede introducir cómo se utilizan los valores medidos de varios sensores',
        image: ''
      },
      it: {
        brief: 'Calcolo multi-sensore pressione negativa',
        full: 'Calcolo multi-sensore pressione negativa \r\nÈ possibile collegare più sensori dello stesso tipo. \r\nQui è possibile inserire come vengono utilizzati i valori di misura di più sensori.',
        image: ''
      }
    },
    multi_sensor_handling_heating_mat: {
      en: {
        brief: 'Multi Sensor Calculation Heating Mat',
        full: 'Multi Sensor Calculation Heating Mat \r\nMultiple sensors of the same type can be connected. \r\n Here you can define how the individual sensor values are used.',
        image: ''
      },
      de: {
        brief: 'Multi-Sensor-Berechnung Heizmatte',
        full: 'Multi-Sensor-Berechnung Heizmatte (Senor für Heizgeräte) \r\nEs können mehrere Sensoren vom gleichen Typ angeschlossen werden. \r\nHier kann eingegeben werden, wie die Messwerte von mehreren Sensoren genutzt werden.',
        image: ''
      },
      fr: {
        brief: 'Calcul multi-capteurs Tapis chauffant',
        full: 'Calcul multi-capteurs Tapis chauffant (capteur pour appareils de chauffage) \r\nIl est possible de connecter plusieurs capteurs du même type. \r\nIl est possible de saisir ici comment les valeurs de mesure de plusieurs capteurs sont utilisées',
        image: ''
      },
      es: {
        brief: 'Estera de calefacción de cálculo multisensor',
        full: 'Cálculo multisensor alfombrilla de calefacción (sensor para calefactores) - Se pueden conectar varios sensores del mismo tipo. \r\nSe puede introducir cómo se utilizan los valores medidos de varios sensores',
        image: ''
      },
      it: {
        brief: 'Calcolo multi-sensore tappetino riscaldante',
        full: 'Calcolo multi-sensore tappetino riscaldante (sensore per dispositivi di riscaldamento) \r\nÈ possibile collegare più sensori dello stesso tipo. \r\nQui è possibile inserire come vengono utilizzati i valori di misura di più sensori.',
        image: ''
      }
    },
    multi_sensor_handling_leaf: {
      en: {
        brief: 'Multi Sensor Calculation Leaf Temperature',
        full: 'Multi Sensor Calculation Leaf Temperature \r\nMultiple sensors of the same type can be connected. \r\n Here you can define how the individual sensor values are used.',
        image: ''
      },
      de: {
        brief: 'Multi-Sensor-Berechnung Blatttemperatursensor',
        full: 'Multi-Sensor-Berechnung Blatttemperatursensor \r\nEs können mehrere Sensoren vom gleichen Typ angeschlossen werden. \r\nHier kann eingegeben werden, wie die Messwerte von mehreren Sensoren genutzt werden.',
        image: ''
      },
      fr: {
        brief: 'Calcul multi-capteurs capteur de température de feuille',
        full: 'Calcul multi-capteurs Capteur de température de feuille \r\nIl est possible de connecter plusieurs capteurs du même type. \r\nIl est possible de saisir ici comment les valeurs de mesure de plusieurs capteurs sont utilisées',
        image: ''
      },
      es: {
        brief: 'Sensor de temperatura de la hoja de cálculo multisensor',
        full: 'Multi-Sensor-Cálculo Sensor Temperatura Hoja \r\nSe pueden conectar múltiples sensores del mismo tipo. \r\nSe puede introducir cómo se utilizan las lecturas de múltiples sensores.',
        image: ''
      },
      it: {
        brief: 'Calcolo multi-sensore sensore temperatura foglia',
        full: 'Calcolo multi-sensore sensore temperatura foglia \r\nÈ possibile collegare più sensori dello stesso tipo. \r\nQui è possibile inserire come vengono utilizzati i valori di misura di più sensori.',
        image: ''
      }
    },
    filter_val_sensor_co2: {
      en: {
        brief: 'Filter Setting CO₂ Sensor',
        full: 'Filter Setting CO₂ Sensor \r\nOur CO₂ sensors have an adjustable filter. \r\n Filter values 1-7: \r\nWith a small filter value, the sensor reacts faster to changing measured values than with a large filter value. However, the noise (short-term fluctuations) is greater with a small filter value than with a large filter value. \r\n\r\nFilter value 0: \r\nWith this filter value, the sensor itself selects the currently best filter and adapts to the prevailing measured values. This setting is normally the best option. ',
        image: ''
      },
      de: {
        brief: 'Filter CO₂-Sensor',
        full: 'Filter CO₂-Sensor \r\nUnsere CO₂-Sensoren verfügen über ein einstellbares Filter. \r\n\r\nFilterwerte 1-7: \r\nBei einem kleinen Filterwert reagiert der Sensor schneller auf sich ändernde Messwerte, als bei einem großen Filterwert. Das Rauschen (Kurzzeit-Schwankungen) ist bei einem kleinen Filterwert jedoch größer, als bei einem großen Filterwert. \r\n\r\nFilter-Wert 0: \r\nMit diesem Filterwert wählt der Sensor selbst den momentan besten Filter und passt sich den aktuellen Messwerten an. Diese Einstellung ist im Normalfall die beste Option.',
        image: ''
      },
      fr: {
        brief: 'Filtre capteur CO₂',
        full: "Filtre capteur CO₂ \r\nNos capteurs CO₂ disposent d'un filtre réglable. \r\n\r\nValeurs du filtre 1-7 : \r\nAvec une petite valeur de filtre, le capteur réagit plus rapidement aux valeurs de mesure changeantes qu'avec une grande valeur de filtre. Cependant, le bruit (variations à court terme) est plus important avec une petite valeur de filtre qu'avec une grande valeur de filtre. \r\n\rnValeur du filtre 0 : \r\nAvec cette valeur de filtre, le capteur choisit lui-même le meilleur filtre du moment et s'adapte aux valeurs de mesure actuelles. Ce réglage est normalement la meilleure option",
        image: ''
      },
      es: {
        brief: 'Filtro CO₂-Sensor',
        full: 'Filtro del sensor de CO₂ Nuestros sensores de CO₂ tienen un filtro ajustable. \r\nValores de filtro 1-7: Con un valor de filtro pequeño, el sensor reacciona más rápido a los cambios en los valores medidos que con un valor de filtro grande. Sin embargo, el ruido (fluctuaciones a corto plazo) es mayor con un valor de filtro pequeño que con un valor de filtro grande. \r\nValor de filtro 0: \r\nCon este valor de filtro, el propio sensor selecciona el mejor filtro actual y se adapta a los valores medidos actuales. Este ajuste es normalmente la mejor opción',
        image: ''
      },
      it: {
        brief: 'Filtro sensore CO₂',
        full: 'Filtro sensore CO₂ \r\nI nostri sensori CO₂ hanno un filtro regolabile. \r\nValori del filtro 1-7: \r\nCon un valore di filtro piccolo, il sensore reagisce più rapidamente ai valori misurati in cambiamento rispetto a un valore di filtro grande. Tuttavia, il rumore (fluttuazioni a breve termine) è maggiore con un valore di filtro piccolo rispetto a un valore di filtro grande. \r\nValore del filtro 0: \r\nCon questo valore di filtro, il sensore stesso seleziona il filtro attualmente migliore e si adatta ai valori misurati prevalenti. Questa impostazione è normalmente la migliore opzione.',
        image: ''
      }
    },
    action_transmit_filter_sensor: {
      en: {
        brief: '',
        full: '',
        image: ''
      },
      de: {
        brief: '',
        full: '',
        image: ''
      },
      fr: {
        brief: '',
        full: '',
        image: ''
      },
      es: {
        brief: '',
        full: '',
        image: ''
      },
      it: {
        brief: '',
        full: '',
        image: ''
      }
    },
    calib_val_sensor_co2: {
      en: {
        brief: 'Calibration CO₂ Sensor',
        full: "Calibration CO₂ Sensor \r\nThis function can be used to calibrate the CO₂ sensor or sensors to the CO₂ value set here. For accurate calibration, the CO₂ sensors must have been in a known and stable CO₂ concentration for at least approx. 15 minutes. We recommend setting the filter value to '7' beforehand and transferring the filter value to the CO₂ sensors. After calibration is complete, the filter should be set back to the desired value. (normally '0'). Without special laboratory equipment, the easiest way to calibrate the sensors is on fresh air. Fresh sea air has about 400 ppm. Fresh air in a city has about 400-500 ppm.",
        image: ''
      },
      de: {
        brief: 'Kalibrierung CO₂-Sensor',
        full: "Kalibrierung CO₂-Sensor \r\nMit dieser Funktion kann der CO₂-Sensor oder die CO2-Sensoren auf den hier eingestellten CO₂-Wert Kalibriert werden. \r\nFür eine genaue Kalibrierung müssen sich die CO₂-Sensoren dafür mindestens seit ca. 15 Minuten einer bekannten und stabilen CO₂-Konzentration befinden. Wir empfehlen, den Filter-Wert vorher auf '7' einszstellen und den Filter-Wert an die CO₂-Sensoren zu übertragen. Nach Abschluss der Kalibrierung sollte der Filter wieder auf den gewünschten Wert eingestellt werden. (normalerweise '0'). \r\nOhne spezielle Laborausstattung ist die einfachste Möglichkeit, die Sensoren an Frischluft zu kalibrieren. \r\nFrische Meerluft hat ca. 400 ppm. \r\nFrische Luft in einer Stadt hat ca. 400-500 ppm.",
        image: ''
      },
      fr: {
        brief: 'Calibrage du capteur CO₂',
        full: "Calibrage du capteur CO₂ \r\nCette fonction permet de calibrer le capteur CO₂ ou les capteurs de CO2 sur la valeur de CO₂ réglée ici. \r\nPour un étalonnage précis, les capteurs de CO₂ doivent pour cela se trouver depuis au moins 15 minutes environ dans une concentration de CO₂ connue et stable. Nous recommandons de régler au préalable la valeur du filtre sur '7' et de transmettre la valeur du filtre aux capteurs de CO₂. Une fois l'étalonnage terminé, le filtre doit à nouveau être réglé sur la valeur souhaitée. (normalement '0'). \r\nSans équipement de laboratoire spécial, le moyen le plus simple est d'étalonner les capteurs à l'air frais. \r\nL'air frais de la mer contient environ 400 ppm. \r\nL'air frais dans une ville a environ 400-500 ppm",
        image: ''
      },
      es: {
        brief: 'Calibración sensor de CO₂',
        full: "Con esta función, el sensor de CO₂ o los sensores de CO2 pueden calibrarse con el valor de CO₂ establecido aquí. Para una calibración precisa, los sensores de CO₂ deben haber estado a una concentración de CO₂ conocida y estable durante al menos 15 minutos. Se recomienda ajustar previamente el valor del filtro a '7' y transmitir el valor del filtro a los sensores de CO₂. Después de completar la calibración, el filtro debe ajustarse de nuevo al valor deseado. (normalmente '0'). \r\nSin equipo especial de laboratorio, la forma más fácil de calibrar los sensores es en aire fresco. El aire fresco del mar tiene aproximadamente 400 ppm. El aire fresco de una ciudad tiene unas 400-500 ppm",
        image: ''
      },
      it: {
        brief: 'Calibrazione sensore CO₂',
        full: "Con questa funzione, il sensore CO₂ o i sensori di CO2 possono essere calibrati sul valore di CO₂ impostato qui. \r\nPer una calibrazione precisa, i sensori di CO₂ devono essere stati a una concentrazione di CO₂ nota e stabile per almeno 15 minuti. Si consiglia di impostare in precedenza il valore del filtro su '7' e trasferire il valore del filtro ai sensori di CO₂. Dopo aver completato la calibrazione, il filtro deve essere riportato al valore desiderato. (normalmente '0'). \r\nSenza attrezzatura da laboratorio speciale, il modo più semplice per calibrare i sensori è all'aria aperta. \r\nL'aria fresca del mare ha circa 400 ppm. \r\nL'aria fresca di una città ha circa 400-500 ppm",
        image: ''
      }
    },
    action_transmit_calib_filter_sensor_co2: {
      en: {
        brief: '',
        full: '',
        image: ''
      },
      de: {
        brief: '',
        full: '',
        image: ''
      },
      fr: {
        brief: '',
        full: '',
        image: ''
      },
      es: {
        brief: '',
        full: '',
        image: ''
      },
      it: {
        brief: '',
        full: '',
        image: ''
      }
    },
    restore_factory_default: {
      en: {
        brief: 'Reset All Settings to Factory Default',
        full: 'Reset All Settings to Factory Default \r\nThis function resets all settings to the default values.\r\nPlease note that the functions of the outputs/sockets are also reset to the factory settings.',
        image: ''
      },
      de: {
        brief: 'Alle Einstellungen auf die Werkseinstellungen zurücksetzten',
        full: 'Alle Einstellungen auf die Werkseinstellungen zurücksetzten \r\nMit dieser Funktion werden alle Einstellungen auf die Standard-Werte zurückgesetzt. \r\nBitte beachte, dass auch die Funktionen der Ausgänge/Steckdosen auf die Werkseinstellungen zurückgesetzt werden.',
        image: ''
      },
      fr: {
        brief: 'Réinitialiser tous les paramètres aux valeurs par défaut',
        full: "Réinitialiser tous les paramètres aux valeurs par défaut \r\nCette fonction permet de réinitialiser tous les paramètres aux valeurs par défaut. \r\nVeuillez noter que les fonctions des sorties/prises de courant sont également réinitialisées aux paramètres d'usine",
        image: ''
      },
      es: {
        brief: 'Restablecer todos los ajustes a los valores predeterminados de fábrica',
        full: 'Restablecer todos los ajustes a los valores predeterminados de fábrica \r\nEsta función restablece todos los ajustes a los valores predeterminados. Tenga en cuenta que las funciones de las salidas/tomas también se restablecen a los valores de fábrica.',
        image: ''
      },
      it: {
        brief: 'Ripristina tutte le impostazioni ai valori predefiniti di fabbrica',
        full: 'Ripristina tutte le impostazioni ai valori predefiniti di fabbrica \r\nQuesta funzione ripristina tutte le impostazioni ai valori predefiniti. \r\nSi prega di notare che le funzioni delle uscite/presa di corrente sono anche ripristinate alle impostazioni di fabbrica.',
        image: ''
      }
    },
    firmware_hl: {
      en: {
        brief: 'Firmware information & update',
        full: "Firmware information & update \r\nThe system consists of a main controller and a WiFi module. \r\nThe main controller is responsible for the control and automation tasks, sensor communication and the controller menu. \r\nThe WiFi module is responsible for the WiFi connection and the data logging. \r\n\r\nBoth systems must have compatible firmware versions. In order for two versions to be compatible, only the last number (2 digits) of the version number may be different. (Apart from the 'w' in the fourth place) \r\n\r\n If the update is performed via the web interface/GUI, an update is always performed for both systems. This ensures compatibility. \r\n\r\nExample OK: \r\nVersion main controller: 5055.0.01.02b \r\nVersion WiFi module: 505w.0.01.03b \r\n\r\nExample NOT OK: \r\nVersion main controller: 5055.0.01.12b \r\nVersion WiFi module: 505w.0.02.02b",
        image: ''
      },
      de: {
        brief: 'Firmware-Informationen & Update',
        full: "Firmware-Informationen & Update \r\nDas System besteht aus einem Hauptcontroller und einem WLAN-Modul. \r\nDer Hauptcontroller ist für die Regelungs- und Automatisierungsaufgaben, Sensorkommunnikation und das Controller-Menü zuständig. \r\nDas WLAN-Modul ist für die WLAN-Anbinung und das Daten-Logging zuständig. \r\n\r\nBeide Systeme müssen zueinander kompatible Versionen haben. Damit zwei Versionen miteinander kompatibel sind, darf nur die letzte Nummer (2 Stellen) der Versionsnummer unterschiedlich sein. (Abgesehen von dem 'w' an vierter Stelle) \r\n\r\n Wenn das Update über das Webinterface/GUI ausgeführt wird, wird immer für beide Systeme ein Update durchgeführt. Dadurch ist die Kompatibilität sichergestellt. \r\n\r\nBeispiel OK: \r\nVersion Hauptcontroller: 5055.0.01.12b \r\nVersion WLAN-Modul: 505w.0.01.03b \r\n\r\nBeispiel NICHT OK: \r\nVersion Hauptcontroller: 5055.0.01.02b \r\nVersion WLAN-Modul: 505w.0.02.02b",
        image: ''
      },
      fr: {
        brief: 'Informations sur le micrologiciel et mise à jour',
        full: "Informations sur le micrologiciel et mise à jour \r\nLe système se compose d'un contrôleur principal et d'un module WiFi. \r\nLe contrôleur principal est responsable des tâches de contrôle et d'automatisation, de la communication des capteurs et du menu du contrôleur. \r\nLe module WiFi est responsable de la connexion WiFi et de l'enregistrement des données. \r\n\r\nLes deux systèmes doivent avoir des versions de micrologiciel compatibles. Pour que deux versions soient compatibles, seul le dernier chiffre (2 chiffres) du numéro de version peut être différent. (Mis à part le 'w' à la quatrième place) \r\n\r\nSi la mise à jour est effectuée via l'interface web/GUI, une mise à jour est toujours effectuée pour les deux systèmes. Cela garantit la compatibilité. \r\n\r\nExemple OK: \r\nVersion contrôleur principal: 5055.0.01.12b \r\nVersion module WiFi: 505w.0.01.03b \r\n\r\nExemple PAS OK: \r\nVersion contrôleur principal: 5055.0.01.02b \r\nVersion module WiFi: 505w.0.02.02b",
        image: ''
      },
      es: {
        brief: 'Información del firmware y actualización',
        full: "Información del firmware y actualización \r\nEl sistema consta de un controlador principal y un módulo WiFi. \r\nEl controlador principal es responsable de las tareas de control y automatización, la comunicación de sensores y el menú del controlador. \r\nEl módulo WiFi es responsable de la conexión WiFi y el registro de datos. \r\n\r\nAmbos sistemas deben tener versiones de firmware compatibles. Para que dos versiones sean compatibles, sólo el último número (2 dígitos) del número de versión puede ser diferente. (Aparte de la 'w' en el cuarto lugar) \r\n\r\nSi la actualización se realiza a través de la interfaz web/GUI, siempre se realiza una actualización para ambos sistemas. Esto garantiza la compatibilidad. \r\n\r\nEjemplo OK: \r\nVersión del controlador principal: 5055.0.01.12b \r\nVersión del módulo WiFi: 505w.0.01.03b \r\n\r\nEjemplo NO OK: \r\nVersión del controlador principal: 5055.0.01.02b \r\nVersión del módulo WiFi: 505w.0.02.02b",
        image: ''
      },
      it: {
        brief: 'Informazioni sul firmware e aggiornamento',
        full: "Informazioni sul firmware e aggiornamento \r\nIl sistema è composto da un controller principale e da un modulo WiFi. \r\nIl controller principale è responsabile delle attività di controllo e automazione, della comunicazione dei sensori e del menu del controller. \r\nIl modulo WiFi è responsabile della connessione WiFi e della registrazione dei dati. \r\n\r\nEntrambi i sistemi devono avere versioni firmware compatibili. Affinché due versioni siano compatibili, solo l'ultimo numero (2 cifre) del numero di versione può essere diverso. (A parte la 'w' in quarta posizione) \r\n\r\nSe l'aggiornamento viene eseguito tramite l'interfaccia web/GUI, viene sempre eseguito un aggiornamento per entrambi i sistemi. Ciò garantisce la compatibilità. \r\n\r\nEsempio OK: \r\nVersione controller principale: 5055.0.01.12b \r\nVersione modulo WiFi: 505w.0.01.03b \r\n\r\nEsempio NON OK: \r\nVersione controller principale: 5055.0.01.02b \r\nVersione modulo WiFi: 505w.0.02.02b",
        image: ''
      }
    },
    firmware_update: {
      en: {
        brief: 'Select The Firmware Version to Install',
        full: 'Select The Firmware Version to Install \r\nIn order for the update to be carried out, it must be confirmed on the controller menu.',
        image: ''
      },
      de: {
        brief: 'Wähle die zu installierende Firmware-Version',
        full: 'Wähle die zu installierende Firmware-Version \r\nDamit das Update durchgeführt werden kann, muss es am Controller-Menü bestätigt werden.',
        image: ''
      },
      fr: {
        brief: 'Choisir la version du firmware à installer',
        full: 'Choisis la version du firmware à installer\rNPour que la mise à jour puisse être effectuée, elle doit être confirmée dans le menu du contrôleur.',
        image: ''
      },
      es: {
        brief: 'Seleccione la versión de firmware a instalar',
        full: 'Seleccione la versión de firmware a instalar \r\nPara que la actualización se lleve a cabo, debe confirmarse en el menú del controlador.',
        image: ''
      },
      it: {
        brief: 'Seleziona la versione del firmware da installare',
        full: "Seleziona la versione del firmware da installare \r\nPerché l'aggiornamento venga eseguito, deve essere confermato nel menu del controller.",
        image: ''
      }
    },

    //Alarms
    expected_power_extender_out_cnt: {
      en: {
        brief: 'Select The Firmware Version to Install',
        full: 'Select The Firmware Version to Install \r\nIn order for the update to be carried out, it must be confirmed on the controller menu.',
        image: ''
      },
      de: {
        brief: 'Erwartete Anzahl erxterner Ausgänge',
        full: 'Erwartete Anzahl erxterner Ausgänge \r\nHier kann die Anzahl der erwarteten externen Ausgänge eingegeben werden. Dabei werden nur die Ausgänge gezählt, die sich nicht am Controller selbst befinden. \r\nMit einem PowerExtender 4+ währe die erwartete Anzahl externer Ausgänge 5. (4 Steckdosen + 1 Transistor-Ausgang). \r\nWenn der Alarm eingeschaltet ist, wird ein Alarm ausgläst, wenn weniger externe Ausgänge erkannt wurden, als erwartet.',
        image: ''
      },
      fr: {
        brief: 'Nombre attendu de sorties externes',
        full: "Nombre attendu de sorties externes \r\nil est possible de saisir le nombre de sorties externes attendues. Seules les sorties qui ne se trouvent pas sur le contrôleur lui-même sont comptées. \r\nAvec un PowerExtender 4+, le nombre attendu de sorties externes serait de 5 (4 prises + 1 sortie transistorisée). \r\nLorsque l'alarme est activée, une alarme est émise si moins de sorties externes que prévu ont été détectées.",
        image: ''
      },
      es: {
        brief: 'Número esperado de salidas externas',
        full: 'Número esperado de salidas externas \r\nAquí se puede introducir el número de salidas externas esperadas. Sólo se cuentan las salidas que no están en el propio controlador. \r\nCon un PowerExtender 4+, el número esperado de salidas externas sería 5 (4 tomas + 1 salida de transistor). \r\nCuando la alarma esté activada, sonará una alarma si se han detectado menos salidas externas de las esperadas.',
        image: ''
      },
      it: {
        brief: 'Numero previsto di uscite esterne',
        full: "Numero previsto di uscite esterne \r\nIl numero di uscite esterne previste può essere inserito qui. Vengono conteggiate solo le uscite che non si trovano sul controllore stesso. \r\nCon un PowerExtender 4+, il numero previsto di uscite esterne è 5 (4 prese + 1 uscita a transistor). \r\nQuando l'allarme è attivato, se viene rilevato un numero di uscite esterne inferiore a quello previsto, viene emesso un allarme acustico.",
        image: ''
      }
    },
    alarm_low_temperature_air_day_setting: {
      en: {
        brief: 'Low temperature alarm - day',
        full: 'Low temperature alarm - day \r\nIf this value is undershot during the day and the alarm is switched on, the alarm will be triggered. To send an email with the corresponding alarm, an email address must be specified in the account settings and the email address must have been confirmed.',
        image: ''
      },
      de: {
        brief: 'Alarm Temperatur unterschritten Tag',
        full: 'Alarm Temperatur unterschritten Tag \r\nWenn dieser Wert am Tag unterschritten wird und der Alarm eingeschaltet ist, wird der Alarm ausgelöst. Damit eine Email mit dem entsprechenden Alarm versendet werden kann, muss eine Emailadresse in den Account-Einstellungen angegeben sein und die Emailadresse muss bestätigt worden sein.',
        image: ''
      },
      fr: {
        brief: 'Alarme de température basse - jour',
        full: "Alarme de température basse - jour \r\nSi cette valeur est sous le seuil pendant la journée et que l'alarme est activée, l'alarme sera déclenchée. Pour envoyer un e-mail avec l'alarme correspondante, une adresse e-mail doit être spécifiée dans les paramètres du compte et l'adresse e-mail doit avoir été confirmée.",
        image: ''
      },
      es: {
        brief: 'Alarma de temperatura baja - día',
        full: 'Alarma de temperatura baja - día \r\nSi este valor se subestima durante el día y la alarma está encendida, se activará la alarma. Para enviar un correo electrónico con la alarma correspondiente, se debe especificar una dirección de correo electrónico en la configuración de la cuenta y se debe haber confirmado la dirección de correo electrónico.',
        image: ''
      },
      it: {
        brief: 'Allarme temperatura bassa - giorno',
        full: "Allarme temperatura bassa - giorno \r\nSe questo valore viene sottostimato durante il giorno e l'allarme è acceso, l'allarme verrà attivato. Per inviare un'e-mail con l'allarme corrispondente, è necessario specificare un indirizzo e-mail nelle impostazioni dell'account e l'indirizzo e-mail deve essere stato confermato.",
        image: ''
      }
    },

    alarm_over_temperature_air_day_setting: {
      en: {
        brief: 'Alarm temperature exceeded day',
        full: 'Alarm temperature exceeded day \r\nIf the temperature falls below this value during the day and the alarm is switched on, the alarm is triggered. To send an email with the corresponding alarm, an email address must be specified in the account settings and the email address must have been confirmed.',
        image: ''
      },
      de: {
        brief: 'Alarm Temperatur überschritten Tag',
        full: 'Alarm Temperatur überschritten Tag \r\nWenn dieser Wert am Tag unterschritten wird und der Alarm eingeschaltet ist, wird der Alarm ausgelöst. Damit eine Email mit dem entsprechenden Alarm versendet werden kann, muss eine Emailadresse in den Account-Einstellungen angegeben sein und die Emailadresse muss bestätigt worden sein.',
        image: ''
      },
      fr: {
        brief: 'Alarme de température dépassée jour ',
        full: "Alarme de température dépassée jour \r\nLorsque cette valeur est dépassée vers le bas pendant la journée et que l'alarme est activée, l'alarme est déclenchée. Pour qu'un e-mail puisse être envoyé avec l'alarme correspondante, une adresse e-mail doit être indiquée dans les paramètres du compte et l'adresse e-mail doit être confirmée."
      },
      es: {
        brief:
          'Alarma temperatura superada día \r\nSi la temperatura desciende por debajo de este valor durante el día y la alarma está activada, la alarma se dispara. Para que se envíe un correo electrónico con la alarma correspondiente, es necesario haber introducido una dirección de correo electrónico en los ajustes de la cuenta y haber confirmado la dirección de correo electrónico.',
        image:
          'Alarma temperatura superada día \r\nSi la temperatura desciende por debajo de este valor durante el día y la alarma está activada, la alarma se dispara. Para que se envíe un correo electrónico con la alarma correspondiente, es necesario haber introducido una dirección de correo electrónico en los ajustes de la cuenta y haber confirmado la dirección de correo electrónico.'
      },
      it: {
        brief: 'Allarme temperatura superata Giorno',
        full: "Allarme temperatura superata Giorno \r\nSe la temperatura scende al di sotto di questo valore durante il giorno e l'allarme è acceso, l'allarme viene attivato. Affinché venga inviata un'e-mail con l'allarme corrispondente, è necessario inserire un indirizzo e-mail nelle impostazioni dell'account e confermare l'indirizzo e-mail.",
        image: ''
      }
    },
    // advanced
    fan_ac_intake_turnoff: {
      en: {
        brief: 'Fan AC Intake Turnoff',
        full: "Fan AC Intake Turnoff \r\nThe speed of AC fans cannot be reduced to any extent. If the speed falls below a critical value, which depends on the respective fan, the AC fan would no longer run reliably. Therefore, a value is defined below which an AC fan switches off instead of being controlled with a value that is too low. \r\n\r\nIn order for the intake AC fan not to be switched off, the value 'Intake AC MIN' must be greater than the value 'Fan AC Intake Turnoff'. \r\n\r\nMake sure that the AC fan is still rotating at the set minimum speed and that the air flow is sufficient for cooling the motor.",
        image: ''
      },
      de: {
        brief: 'Abschaltung AC-Zuluft',
        full: "Abschaltung AC-Zuluft \r\nDie Drehzahl von AC-Ventilatoren kann nicht beliebig weit reduziert werden. Bei Unterschreitung eines Wertes, der vom jeweiligen Ventilator abhängig ist, würde der AC-Ventilator nicht mehr zuverlässig laufen. Daher wird ein Wert definiert, bei dessen Unterschreitung ein AC-Ventilator abschaltet, anstatt mit einem zu geringen Wert angesteuert zu werden. \r\n\r\nDamit der AC-Zuluft-Ventilator nicht abgeschaltet wird, muss der Wert 'Zuluft AC MIN' größer sein, als der Wert 'Abschaltung Zuluft AC'. \r\n\r\nVergewissern Sie sich, dass sich der AC-Ventilator bei der eingestellten Minimaldrehzahl noch dreht und dass der Luftstrom für die Kühlung des Motors ausreichend ist.",
        image: ''
      },
      fr: {
        brief: "Arrêt de l'intraction AC",
        full: "Arrêt de l'air d'admission AC\r\nLa vitesse des ventilateurs AC ne peut pas être réduite à volonté. En dessous d'une valeur qui dépend du ventilateur concerné, le ventilateur AC ne fonctionnerait plus de manière fiable. C'est pourquoi on définit une valeur en dessous de laquelle un ventilateur AC s'arrête au lieu d'être commandé avec une valeur trop faible. \r\n\r\nPour que le ventilateur d'air d'admission AC ne s'arrête pas, la valeur 'Air d'admission AC MIN' doit être supérieure à la valeur 'Arrêt air d'admission AC'. \r\n\r\nS'assurer que le ventilateur AC tourne encore à la vitesse minimale définie et que le débit d'air est suffisant pour refroidir le moteur",
        image: ''
      },
      es: {
        brief: 'Desconexión del aire de alimentación de CA',
        full: "La velocidad de los ventiladores de CA no puede reducirse arbitrariamente. Si la velocidad cae por debajo de un valor que depende del ventilador respectivo, el ventilador de CA dejaría de funcionar de forma fiable. Por lo tanto, se define un valor por debajo del cual un ventilador de CA se desconecta en lugar de controlarse con un valor demasiado bajo. \r\nPara que el ventilador de aire de impulsión de CA no se apague, el valor 'Aire de impulsión CA MIN' debe ser mayor que el valor 'Aire de impulsión CA desconexión'. \r\n Asegúrese de que el ventilador de CA sigue girando a la velocidad mínima ajustada y de que el caudal de aire es suficiente para refrigerar el motor",
        image: ''
      },
      it: {
        brief: 'Spegnimento ventilatore di aspirazione AC',
        full: "Spegnimento ventilatore di aspirazione AC \r\nLa velocità dei ventilatori AC non può essere ridotta a piacimento. Se la velocità scende al di sotto di un valore critico, che dipende dal rispettivo ventilatore, il ventilatore AC non funzionerebbe più in modo affidabile. Pertanto, viene definito un valore al di sotto del quale un ventilatore AC si spegne invece di essere controllato con un valore troppo basso. \r\n\r\nAffinché il ventilatore di aspirazione AC non venga spento, il valore 'Aspirazione AC MIN' deve essere maggiore del valore 'Spegnimento ventilatore di aspirazione AC'. \r\n\r\nAssicurarsi che il ventilatore AC stia ancora ruotando alla velocità minima impostata e che il flusso d'aria sia sufficiente per raffreddare il motore.",
        image: ''
      }
    },
    fan_ac_exhaust_turnoff: {
      en: {
        brief: 'Fan AC Exhaust Turnoff',
        full: "Fan AC Exhaust Turnoff \r\nThe speed of AC fans cannot be reduced to any extent. If the speed falls below a critical value, which depends on the respective fan, the AC fan would no longer run reliably. Therefore, a value is defined below which an AC fan switches off instead of being controlled with a value that is too low. \r\n\r\nIn order for the exhaust AC fan not to be switched off, the value 'Exhaust AC MIN' must be greater than the value 'Fan AC Exhaust Turnoff'. \r\n\r\nMake sure that the AC fan is still rotating at the set minimum speed and that the air flow is sufficient for cooling the motor.",
        image: ''
      },
      de: {
        brief: 'Abschaltung AC-Abluft',
        full: "Abschaltung AC-Abluft \rDie Drehzahl von AC-Ventilatoren kann nicht beliebig weit reduziert werden. Bei Unterschreitung eines Wertes, der vom jeweiligen Ventilator abhängig ist, würde der AC-Ventilator nicht mehr zuverlässig laufen. Daher wird ein Wert definiert, bei dessen Unterschreitung ein AC-Ventilator abschaltet, anstatt mit einem zu geringen Wert angesteuert zu werden. \r\n\r\nDamit der AC-Abluft-Ventilator nicht abgeschaltet wird, muss der Wert 'Abluft AC MIN' größer sein, als der Wert 'Abschaltung Abluft AC'. \r\n\r\nVergewissern Sie sich, dass sich der AC-Ventilator bei der eingestellten Minimaldrehzahl noch dreht und dass der Luftstrom für die Kühlung des Motors ausreichend ist.",
        image: ''
      },
      fr: {
        brief: "Arrêt de l'extraction AC",
        full: "Arrêt de l'extraction d'air AC \rLa vitesse des ventilateurs AC ne peut pas être réduite à volonté. En dessous d'une valeur qui dépend du ventilateur concerné, le ventilateur AC ne fonctionnerait plus de manière fiable. C'est pourquoi on définit une valeur en dessous de laquelle un ventilateur AC s'arrête au lieu d'être commandé avec une valeur trop faible. \r\n\r\nPour que le ventilateur d'extraction AC ne s'arrête pas, la valeur 'Extraction AC MIN' doit être supérieure à la valeur 'Arrêt de l'extraction AC'. \r\n\r\nS'assurer que le ventilateur AC tourne encore à la vitesse minimale définie et que le débit d'air est suffisant pour refroidir le moteur",
        image: ''
      },
      es: {
        brief: 'Desconexión del aire de escape de CA',
        full: "La velocidad de los ventiladores de CA no puede reducirse arbitrariamente. Si la velocidad cae por debajo de un valor que depende del ventilador correspondiente, el ventilador de CA dejaría de funcionar de forma fiable. Por lo tanto, se define un valor por debajo del cual un ventilador de CA se desconecta en lugar de controlarse con un valor demasiado bajo. \r\nPara que el ventilador de aire de extracción CA no se desconecte, el valor 'Aire de extracción CA MIN' debe ser mayor que el valor 'Desconectar aire de extracción CA'. \r\n Asegúrese de que el ventilador de CA sigue girando a la velocidad mínima ajustada y de que el caudal de aire es suficiente para refrigerar el motor",
        image: ''
      },
      it: {
        brief: 'Spegnimento ventilatore di aspirazione AC',
        full: "Spegnimento ventilatore di aspirazione AC \r\nLa velocità dei ventilatori AC non può essere ridotta a piacimento. Se la velocità scende al di sotto di un valore critico, che dipende dal rispettivo ventilatore, il ventilatore AC non funzionerebbe più in modo affidabile. Pertanto, viene definito un valore al di sotto del quale un ventilatore AC si spegne invece di essere controllato con un valore troppo basso. \r\n\r\nAffinché il ventilatore di aspirazione AC non venga spento, il valore 'Aspirazione AC MIN' deve essere maggiore del valore 'Spegnimento ventilatore di aspirazione AC'. \r\n\r\nAssicurarsi che il ventilatore AC stia ancora ruotando alla velocità minima impostata e che il flusso d'aria sia sufficiente per raffreddare il motore.",
        image: ''
      }
    },
    vpd_rh_allowed: {
      en: {
        brief: 'Allowed Humidity Range with VPD Control',
        full: 'Allowed Humidity Range with VPD Control \r\nWhen VPD control is active, the controller calculates the required humidity at a given leaf temperature to achieve the desired VPD value. The allowed humidity range can be defined with these settings.',
        image: ''
      },
      de: {
        brief: 'Zulässige Luftfeuchtigkeit mit VPD-Regelung',
        full: 'Zulässiger Luftfeuchtigkeitsbereich mit VPD-Regelung \r\nWenn die VPD-Regelung aktiv ist, berechnet der Controller die erforderliche Luftfeuchtigkeit bei der aktuellen Blatttemperatur, um den gewünschten VPD-Wert zu erreichen. Der zulässige Luftfeuchtigkeits-Bereich kann mit diesen Einstellungen definiert werden.',
        image: ''
      },
      fr: {
        brief: 'Humidité autorisée avec le contrôle VPD',
        full: "Plage d'humidité autorisée avec le contrôle VPD -rLorsque le contrôle VPD est actif, le contrôleur calcule l'humidité nécessaire à la température actuelle de la feuille pour atteindre la valeur VPD souhaitée. La plage d'humidité autorisée peut être définie avec ces paramètres",
        image: ''
      },
      es: {
        brief: 'Humedad admisible con control VPD',
        full: 'Rango de humedad admisible con control VPD \rcuando el control VPD está activo, el controlador calcula la humedad necesaria a la temperatura actual de la hoja para alcanzar el valor VPD deseado. El rango de humedad admisible puede definirse con estos ajustes',
        full: '',
        image: ''
      },
      it: {
        brief: "Umidità dell'aria consentita con controllo VPD",
        full: "Intervallo di umidità dell'aria consentito con controllo VPD \r\nQuando il controllo VPD è attivo, il controller calcola l'umidità dell'aria necessaria alla temperatura delle foglie corrente per raggiungere il valore VPD desiderato. L'intervallo di umidità dell'aria consentito può essere definito con queste impostazioni.",
        image: ''
      }
    },
    intake_min_diff_rh: {
      en: {
        brief: 'Intake Sensor Min Difference Humidity',
        full: 'Intake Sensor Min Difference Humidity \r\nThis setting defines how much drier the intake air must be compared to the humidity in the room in order to dehumidify via ventilation. r\n\r\nThe relative humidity depends on the temperature. This means that fresh air that is heated in the room is more humid at the intake air inlet than when it is heated. \r\nTo decide whether the humidity difference is sufficient for dehumidification, the measured intake air humidity is not used directly, but the humidity expected in the room due to the temperature difference is used. \r\nIf OFF is set here, the humidity difference to the intake air is not taken into account.',
        image: ''
      },
      de: {
        brief: 'Zuluftsensor Minimum Differenz Luftfeuchte',
        full: 'Zuluftsensor Minimum Differenz Luftfeuchte \r\nDiese Einstellung definiert wie viel trockener die Zuluft gegenüber der Luftfeuchtigkeit im Raum sein muss, um über die Lüftung zu entfeuchten. \n\r\nDie relative Luftfeuchtigkeit ist abhängig von der Temperatur. Das bedeutet, dass Frischluft die im Raum erwärmt wird, am Zuluft-Einlass feuchter ist, als im erwärmten Zustand. \r\nUm zu entscheiden, ob die Luftfeuchte-Differenz zum Entfeuchten ausreichend ist, wird nicht direkt die gemessene Zuluft-Feuchtigkeit verwendet, sondern es wird die im Innenraum aufgrund der Temperaturdifferenz erwartete Luftfeuchtigkeit verwendet. \r\nWenn hier AUS eingestellt ist, wird die Feuchtigkeits-Differenz zur Zuluft nicht berücksichtigt.',
        image: ''
      },
      fr: {
        brief: "Capteur d'air d'admission Différence minimale d'humidité",
        full: "Capteur d'air entrant Différence minimale d'humidité de l'air \r\nCe réglage définit de combien l'air entrant doit être plus sec que l'humidité de l'air dans la pièce pour être déshumidifié par la ventilation. r\n\r\nL'humidité relative de l'air dépend de la température. Cela signifie que l'air frais qui est réchauffé dans la pièce est plus humide à l'entrée de l'Intraction que lorsqu'il est réchauffé. \r\nPour décider si la différence d'humidité de l'air est suffisante pour la déshumidification, on n'utilise pas directement l'humidité mesurée de l'intraction, mais on utilise l'humidité attendue à l'intérieur en raison de la différence de température",
        image: ''
      },
      es: {
        brief: 'Sensor de admisión de aire mínimo de humedad relativa',
        full: 'Sensor de admisión de aire mínimo de humedad relativa \r\nEsta configuración define cuánto más seco debe estar el aire de admisión en comparación con la humedad relativa en la habitación para deshumidificar a través de la ventilación. \r\n\r\nLa humedad relativa depende de la temperatura. Esto significa que el aire fresco que se calienta en la habitación está más húmedo en el puerto de admisión de aire que en el estado calentado. \r\nPara decidir si la diferencia de humedad es suficiente para deshumidificar, no se utiliza directamente la humedad del aire de admisión medida, sino la humedad esperada en el interior debido a la diferencia de temperatura.',
        image: ''
      },
      it: {
        brief: "Sensore di umidità minima dell'aria di ingresso",
        full: "Sensore di umidità minima dell'aria di ingresso \r\nQuesta impostazione definisce di quanto deve essere più secco l'aria di ingresso rispetto all'umidità nell'ambiente per deumidificare attraverso la ventilazione. \r\n\r\nL'umidità relativa dipende dalla temperatura. Ciò significa che l'aria fresca che viene riscaldata nell'ambiente è più umida nel punto di ingresso dell'aria rispetto allo stato riscaldato. \r\nPer decidere se la differenza di umidità è sufficiente per deumidificare, non viene utilizzata direttamente l'umidità dell'aria di ingresso misurata, ma quella attesa all'interno a causa della differenza di temperatura.",
        image: ''
      }
    },
    intake_min_diff_temp: {
      en: {
        brief: 'Intake Sensor Min Difference Temperature',
        full: 'Intake Sensor Min Difference Temperature \r\nhis setting defines how much cooler the intake air must be compared to the room temperature for the controller to cool via ventilation. \r\nIf OFF is set here, the temperature difference to the intake air is not taken into account.',
        image: ''
      },
      de: {
        brief: 'Zuluftsensor Minimum Differenz Temperatur',
        full: 'Zuluftsensor Minimum Differenz Temperatur \r\nDiese Einstellung definiert wie viel kühler die Zuluft gegenüber der Raumtemperatur sein muss, damit der Controller über die Lüftung kühlt. \r\nWenn hier AUS eingestellt ist, wird die Temperatur-Differenz zur Zuluft nicht berücksichtigt.',
        image: ''
      },
      fr: {
        brief: "Capteur d'air d'admission Différence minimale de température",
        full: "Capteur d'air d'admission Différence minimale de température \r\nCe réglage définit de combien l'air d'admission doit être plus frais pour être refroidi par la ventilation. \r\nSi ce paramètre est réglé sur OFF, la différence de température avec l'Intraction n'est pas prise en compte.",
        image: ''
      },
      es: {
        brief: 'Temperatura de diferencia mínima del sensor de aire de suministro',
        full: 'Temperatura de diferencia mínima del sensor de aire de suministro \r\nEste ajuste define cuánto más frío debe ser el aire de suministro para enfriar mediante ventilación. \r\nSi se ajusta aquí OFF, no se tiene en cuenta la diferencia de temperatura con el aire de impulsión.',
        image: ''
      },
      it: {
        brief: 'Sensore aria di mandata temperatura minima differenziale',
        full: "Sensore aria di mandata temperatura minima differenziale \r\nQuesta impostazione definisce quanto deve essere più fredda l'aria di mandata rispetto alla temperatura ambiente perché il regolatore raffreddi attraverso la ventilazione.  \r\nSe si imposta OFF, la differenza di temperatura rispetto all'aria di mandata non viene presa in considerazione.",
        image: ''
      }
    },
    intake_retry_wait: {
      en: {
        brief: 'Intake Sensor Retry Wait',
        full: 'Intake Sensor Retry Wait \r\nIf the intake air is fed into the room through longer pipes or hoses, the intake air values can only be determined when the ventilation is running. However, if the ventilation is now switched off due to the settings in the Ventilation Settings section - and the difference is too small - the ventilation can be restarted after a set time. This checks whether the intake air has a sufficient difference in the meantime. \r\n\nThis setting defines how long to wait until the ventilation is started up again.',
        image: ''
      },
      de: {
        brief: 'Zuluftsensor Versuch Wartezeit',
        full: 'Zuluftsensor Versuch Wartezeit \r\nWenn die Zuluft durch längere Rohre oder Schläuche in den Raum geleitet wird, können die Zuluft-Werte nur bei laufender Lüftung ermittelt werden. Wenn nun aber aufgrund der Einstellungen im Bereich Lüftungs-Einstellungen – und einer zu geringen Differenz – die Lüftung abgeschaltet ist, kann ein erneutes Hochfahren der Lüftung nach einer eingestellten Zeit erfolgen. Dadurch wird überprüft, ob die Zuluft inzwischen eine ausreichende Differenz aufweist. \r\n\r\nDiese Einstellung definiert, wie lange gewartet wird, bis die Lüftung erneut hochgefahren wird.',
        image: ''
      },
      fr: {
        brief: "Essai du capteur d'air d'admission Temps d'attente",
        full: "Essai du capteur d'air d'admission Temps d'attente \r\nSi l'air d'admission est acheminé dans la pièce par des tubes ou des tuyaux plus longs, les valeurs d'air d'admission ne peuvent être déterminées que lorsque la ventilation est en marche. Mais si, en raison des réglages dans la zone Réglages de la ventilation - et d'une différence trop faible - la ventilation est arrêtée, il est possible de relancer la ventilation après un temps réglé. Cela permet de vérifier si l'Intraction présente entre-temps une différence suffisante. Ce réglage définit combien de temps il faut attendre avant de redémarrer la ventilation",
        image: ''
      },
      es: {
        brief: 'Tiempo de espera de la prueba del sensor de aire de impulsión',
        full: 'Si el aire de impulsión se introduce en el local a través de tuberías o mangueras más largas, los valores del aire de impulsión sólo pueden determinarse cuando la ventilación está en marcha. Sin embargo, si la ventilación está desconectada debido a los ajustes de la sección de ajustes de la ventilación - y la diferencia es demasiado pequeña - la ventilación se puede reiniciar después de un tiempo determinado. De este modo se comprueba si el aire de impulsión tiene entretanto una diferencia suficiente. \r\nEste ajuste define el tiempo que se espera hasta que se vuelve a poner en marcha la ventilación',
        image: ''
      },
      it: {
        brief: 'Tempo di attesa del sensore di aria di ingresso',
        full: "Tempo di attesa del sensore di aria di ingresso \r\nSe l'aria di ingresso viene convogliata nella stanza attraverso tubi o tubi flessibili lunghi, i valori di aria di ingresso possono essere determinati solo quando la ventilazione è in funzione. Tuttavia, se la ventilazione è spenta a causa delle impostazioni nell'area Impostazioni di ventilazione e di una differenza insufficiente, la ventilazione può essere riavviata dopo un tempo impostato. In questo modo si verifica se l'aria di ingresso ha ora una differenza sufficiente. \r\n\r\nQuesta impostazione definisce per quanto tempo attendere prima di riavviare la ventilazione.",
        image: ''
      }
    },
    intake_retry_durationt: {
      en: {
        brief: 'Intake Sensor Retry Duration',
        full: 'Intake Sensor Retry Duration \r\nIf the intake air is fed into the room through longer pipes or hoses, the intake air values can only be determined when the ventilation is running. However, if the ventilation is now switched off due to the settings in the Ventilation Settings section - and the difference is too small - the ventilation can be restarted after a set time. This checks whether the intake air has a sufficient difference in the meantime. \r\n\nThis setting defines for how long the ventilation is started up again.',
        image: ''
      },
      de: {
        brief: 'Zuluftsensor Versuch Dauer',
        full: 'Zuluftsensor Versuch Dauer \r\nWenn die Zuluft durch längere Rohre oder Schläuche in den Raum geleitet wird, können die Zuluft-Werte nur bei laufender Lüftung ermittelt werden. Wenn nun aber aufgrund der Einstellungen im Bereich Lüftungs-Einstellungen – und einer zu geringen Differenz – die Lüftung abgeschaltet ist, kann ein erneutes Hochfahren der Lüftung nach einer eingestellten Zeit erfolgen. Dadurch wird überprüft, ob die Zuluft inzwischen eine ausreichende Differenz aufweist. \r\n\r\nDiese Einstellung definiert, für wie lange die Lüftung hochgefahren wird.',
        image: ''
      },
      fr: {
        brief: "Capteur d'air d'admission essai durée",
        full: "Capteur d'air d'admission Essai de durée\r\nSi l'air d'admission est acheminé dans la pièce par des tubes ou des tuyaux plus longs, les valeurs d'air d'admission ne peuvent être déterminées que lorsque la ventilation est en marche. Mais si, en raison des réglages dans la zone Réglages de la ventilation - et d'une différence trop faible - la ventilation est arrêtée, il est possible de relancer la ventilation après un temps réglé. Cela permet de vérifier si l'Intraction présente entre-temps une différence suffisante. \r\n\r\nCe réglage définit la durée pendant laquelle la ventilation est montée",
        image: ''
      },
      es: {
        brief: 'Duración de la prueba del sensor de aire de impulsión',
        full: 'Si el aire de impulsión se conduce al local a través de tuberías o mangueras más largas, los valores del aire de impulsión sólo pueden determinarse cuando la ventilación está en marcha. Sin embargo, si la ventilación está desconectada debido a los ajustes de la sección de ajustes de la ventilación - y la diferencia es demasiado pequeña - la ventilación se puede reiniciar después de un tiempo determinado. De este modo se comprueba si el aire de impulsión tiene entretanto una diferencia suficiente. \nEste ajuste define durante cuánto tiempo se pone en marcha la ventilación',
        image: ''
      },
      it: {
        brief: "Durata del test del sensore dell'aria di mandata",
        full: "Durata del test del sensore dell'aria di mandata \r\nSe l'aria di mandata viene immessa nel locale attraverso tubi più lunghi o flessibili, i valori dell'aria di mandata possono essere determinati solo quando la ventilazione è in funzione. Tuttavia, se la ventilazione è disattivata a causa delle impostazioni nella sezione Impostazioni di ventilazione - e se la differenza è troppo piccola - la ventilazione può essere riavviata dopo un tempo prestabilito. In questo modo si verifica se nel frattempo l'aria di alimentazione ha una differenza sufficiente. \r\nQuesta impostazione definisce per quanto tempo viene avviata la ventilazione.",
        image: ''
      }
    },
    leaf_temp_diff: {
      en: {
        brief: 'Leaf Temperature Difference',
        full: 'Leaf Temperature Difference \r\nThe VPD value is calculated from the leaf temperature and the air humidity. To be able to use the VPD control without a leaf temperature sensor, the difference between the room temperature and the leaf temperature can be set here. The leaf temperature is usually approx. 1-3 °C below the room temperature.',
        image: ''
      },
      de: {
        brief: 'Differenz Blatttemperatur',
        full: 'Differenz Blatttemperatur \r\nDer VPD-Wert berechnet sich aus der Blatttemperatur und der Luftfeuchtigkeit. \r\nDamit die VPD-Regelung auch ohne Blatttemperatursensor genutzt werden kann, kann hier die Differenz zwischen der Raumtemperatur und der Blatttemperatur eingestellt werden. \r\nDie Blatttemperatur liegt üblicherweise ca. 1-3 °C unter der Raumtemperatur.',
        image: ''
      },
      fr: {
        brief: 'différence de température de la feuille',
        full: "Différence de température de la feuille \r\nLa valeur VPD se calcule à partir de la température de la feuille et de l'humidité de l'air. \r\n Pour que la régulation VPD puisse également être utilisée sans capteur de température de feuille, il est possible de régler ici la différence entre la température ambiante et la température de feuille. \r\nLa température de la feuille est généralement inférieure d'environ 1 à 3 °C à la température ambiante",
        image: ''
      },
      es: {
        brief: 'Diferencia temperatura hoja',
        full: 'Diferencia temperatura hoja \r\nEl valor VPD se calcula a partir de la temperatura de la hoja y la humedad del aire. \r\nPara poder utilizar el control VPD sin un sensor de temperatura de la hoja, se puede ajustar aquí la diferencia entre la temperatura ambiente y la temperatura de la hoja. La temperatura de la hoja suele estar entre 1 y 3 °C por debajo de la temperatura ambiente',
        image: ''
      },
      it: {
        brief: 'Differenza di temperatura foglia',
        full: "Differenza di temperatura foglia \r\nIl valore VPD si calcola dalla temperatura della foglia e dall'umidità dell'aria. \r\nPer poter utilizzare il controllo VPD anche senza un sensore di temperatura della foglia, qui è possibile impostare la differenza tra la temperatura ambiente e la temperatura della foglia. \r\nLa temperatura della foglia è di solito di circa 1-3 °C al di sotto della temperatura ambiente.",
        image: ''
      }
    },
    hysteresis_co2: {
      en: {
        brief: 'Hyteresis CO₂',
        full: 'Hyteresis CO₂ \r\nWith a small hysteresis value, a CO₂ valve or a CO₂ generator is switched on and off more frequently than with a large value. With a small value, the setpoint is adhered to more precisely.',
        image: ''
      },
      de: {
        brief: 'Hyterese CO₂',
        full: 'Hyterese CO₂ \r\nBei einem kleinen Hysterese-Wert wird ein CO₂-Ventil oder ein CO₂-Generator häufiger ein- und ausgeschaltet, als bei einem großen Wert. Bei einem kleinen Wert wird der Sollwert genauer eingehalten.',
        image: ''
      },
      fr: {
        brief: 'Hyterese CO₂',
        full: "Hyterese CO₂ \r\nAvec une petite valeur d'hystérésis, une vanne CO₂ ou un générateur de CO₂ est activé et désactivé plus souvent qu'avec une grande valeur. Avec une petite valeur, la valeur de consigne est respectée plus précisément",
        image: ''
      },
      es: {
        brief: 'Histéresis CO₂',
        full: 'Histéresis CO₂ Un valor de histéresis pequeño conecta y desconecta una válvula de CO₂ o un generador de CO₂ con más frecuencia que un valor grande. Con un valor pequeño, el punto de consigna se cumple con mayor precisión.',
        image: ''
      },
      it: {
        brief: 'Isteresi CO₂',
        full: 'Isteresi CO₂ \r\nCon un valore di isteresi piccolo, una valvola CO₂ o un generatore CO₂ vengono accesi e spenti più frequentemente rispetto a un valore grande. Con un valore piccolo, il valore di riferimento viene rispettato con maggiore precisione.',
        image: ''
      }
    },
    co2_min_dose_ms: {
      en: {
        brief: 'Minimum Activation Time for CO₂ Valve / CO₂ generator',
        full: 'Minimum Activation Time for CO₂ Valve / CO₂ generator \r\nHere you can set the minimum time for which a connected CO₂ valve or CO₂ generator is activated. The control waits with the next dosing until the CO₂ amount required for the next dosing is high enough not to fall below this activation time. This value also influences how often switching takes place. A valve can be operated with relatively short activation times, which makes less sense for a CO₂ generator.',
        image: ''
      },
      de: {
        brief: 'Minimale Einschaltzeit für CO₂-Ventil / CO₂-Generator',
        full: 'Minimale Einschaltzeit für CO₂-Ventil / CO₂-Generator \r\nHier kann eingestellt werden, wie lange ein angeschlossenes CO₂-Ventil oder ein CO₂-Generator mindestens aktiviert wird. Die Regelung wartet mit der nächsten Dosierung so lange, bis die für die nächste Dosierung die erforderliche CO₂-Dosis hoch genug ist, um diese Aktivierungs-Zeit nicht zu unterschreiten. Durch diesen Wert wird auch beeinflusst, wie häufig geschaltet wird. Ein Ventil kann mit relativ kurzen Einschaltdauern betrieben werden, was bei einem CO₂-Generator weniger sinnvoll ist.',
        image: ''
      },
      fr: {
        brief: 'Temps minimal de mise en marche pour la vanne CO₂ / le générateur CO₂',
        full: "Temps d'activation minimal pour la vanne CO₂ / le générateur CO₂ \rIl est possible de régler ici la durée minimale d'activation d'une vanne CO₂ ou d'un générateur CO₂ raccordé. La régulation attend avec le dosage suivant jusqu'à ce que la dose de CO₂ nécessaire pour le dosage suivant soit suffisamment élevée pour ne pas être inférieure à ce temps d'activation. La fréquence de commutation est également influencée par cette valeur. Une vanne peut être utilisée avec des durées d'activation relativement courtes, ce qui est moins judicieux pour un générateur de CO₂",
        image: ''
      },
      es: {
        brief: 'Tiempo mínimo de conexión para válvula de CO₂ / generador de CO₂',
        full: 'Tiempo mínimo de conexión de la válvula de CO₂ / del generador de CO₂ Aquí puede ajustar el tiempo mínimo durante el cual se activa una válvula de CO₂ o un generador de CO₂ conectado. El control espera con la siguiente dosificación hasta que la dosis de CO₂ necesaria para la siguiente dosificación sea lo suficientemente alta como para no caer por debajo de este tiempo de activación. Este valor también influye en la frecuencia con la que se produce la conmutación. Una válvula puede funcionar con tiempos de activación relativamente cortos, lo que tiene menos sentido para un generador de CO₂',
        image: ''
      },
      it: {
        brief: 'Tempo minimo di attivazione per la valvola CO₂ / il generatore CO₂',
        full: 'Tempo minimo di attivazione per la valvola CO₂ / il generatore CO₂ \r\nQui è possibile impostare il tempo minimo per cui viene attivata una valvola CO₂ o un generatore CO₂ collegato. Il controllo attende con la dosatura successiva fino a quando la quantità di CO₂ necessaria per la dosatura successiva non è abbastanza alta da non scendere al di sotto di questo tempo di attivazione. Questo valore influenza anche la frequenza di commutazione. Una valvola può essere azionata con tempi di attivazione relativamente brevi, il che ha meno senso per un generatore di CO₂.',
        image: ''
      }
    },
    reg_kd_pressure: {
      en: {
        brief: 'Differential Factor for Negative Pressure Control',
        full: 'Differential Factor for Negative Pressure Control \r\nThe value set here influences the behaviour of the negative pressure control.\r\nWhich value is optimal for your setup depends primarily on the room size and the responsiveness of the connected intake fans. \r\n\r\nWith a larger value, the negative pressure control reacts more strongly to changes in the currently measured negative pressure value. If the value is too high, the intake fan speed will oscillate. \r\n\r\nWith a small value, the negative pressure control reacts less strongly to changes in the currently measured negative pressure value. With small values the control becomes more stable (no oscillations) and at the same time the control becomes more sluggish. \r\n\r\nFactory setting: 8 \r\nThe differential portion is proportional to the change in value between two cycles of the control algorithm.',
        image: ''
      },
      de: {
        brief: 'Differentieller Faktor Unterdruckregelung',
        full: 'Differentieller Faktor Unterdruckregelung \r\nDer hier eingestellte Wert hat Einfluss auf das Verhalten der Unterdruckregelung.\r\nWelcher Wert für Dein Setup optimal ist, hängt in erster Linie von der Raumgröße und der Reaktionsfreudigkeit der angeschlossenen Zuluft-Ventilatoren ab. \r\nr\nMit einem größeren Wert reagiert die Unterdruckregelung stärker auf Änderungen des aktuell gemessenen Unterdruck-Wertes. Bei zu großen Werten kommt es zu Schwingungen in der Drehzahl des Zuluft-Ventilators. \r\nr\nMit einem kleinen Wert reagiert die Unterdruckregelung weniger stark auf Änderungen des aktuell gemessenen Unterdruck-Wertes. Bei kleinen Werten wird die Regelung stabiler (kein Aufschwingen) gleichzeitig wird die Regelung träger. \r\n\r\nWerkseinstellung: 8 \r\nDer differenzielle Anteil ist proportional zu der Wert-Änderung zwischen zwei Durchläufen des Regelungsalgorithmus.',
        image: ''
      },
      fr: {
        brief: 'Facteur différentiel de régulation de la dépression',
        full: "Facteur différentiel de régulation de la dépression \r\nLa valeur définie ici a une influence sur le comportement de la régulation de la dépression.\r\nLa valeur optimale pour ta configuration dépend en premier lieu de la taille de la pièce et de la réactivité des ventilateurs d'intraction connectés. \r\nr\nAvec une valeur plus élevée, la régulation de la dépression réagit plus fortement aux changements de la valeur de dépression actuellement mesurée. Si les valeurs sont trop élevées, des oscillations se produisent dans la vitesse de rotation du ventilateur d'intraction. \r\nrAvec une valeur faible, la régulation de dépression réagit moins fortement aux modifications de la valeur de dépression actuellement mesurée. Avec de petites valeurs, la régulation devient plus stable (pas d'oscillation) en même temps que la régulation devient plus lente. \r\n\r\nRéglage d'usine : 8 \r\nLa part différentielle est proportionnelle au changement de valeur entre deux passages de l'algorithme de régulation",
        image: ''
      },
      es: {
        brief: 'Control del factor diferencial de vacío',
        full: 'El valor ajustado aquí influye en el comportamiento del control de presión negativa. El valor óptimo para su instalación depende principalmente del tamaño de la sala y de la capacidad de respuesta de los ventiladores de suministro de aire conectados. Con un valor mayor, el regulador de presión negativa reacciona con más fuerza a los cambios en el valor de presión negativa medido en ese momento. Si el valor es demasiado alto, la velocidad del ventilador de impulsión de aire oscilará. Con un valor pequeño, el regulador de presión negativa reacciona con menos fuerza a los cambios en el valor de presión negativa medido en ese momento. Con valores pequeños, el control se vuelve más estable (sin oscilaciones) y, al mismo tiempo, el control se vuelve más lento. El componente diferencial es proporcional al cambio de valor entre dos ejecuciones del algoritmo de control',
        image: ''
      },
      it: {
        brief: 'Fattore differenziale per il controllo della pressione negativa',
        full: "Fattore differenziale per il controllo della pressione negativa \r\nIl valore qui impostato influenza il comportamento del controllo della pressione negativa.\r\nQual è il valore ottimale per la vostra configurazione dipende principalmente dalle dimensioni del locale e dalla reattività dei ventilatori dell'aria di mandata collegati. \r}Non è un valore di pressione negativa, ma un valore di pressione negativa. Con un valore maggiore, il regolatore di pressione negativa reagisce con maggiore forza alle variazioni del valore di pressione negativa attualmente misurato. Se il valore è troppo alto, la velocità del ventilatore dell'aria di mandata oscillerà. \r\nCon un valore piccolo, il controllo della pressione negativa reagisce in modo meno marcato alle variazioni del valore di pressione negativa attualmente misurato. Con valori piccoli il controllo diventa più stabile (nessuna oscillazione) e allo stesso tempo più lento. \r\nLa componente differenziale è proporzionale alla variazione di valore tra due esecuzioni dell'algoritmo di controllo.",
        image: ''
      }
    },
    reg_kd_temp_air: {
      en: {
        brief: 'Differential Factor Room Temperature (Ventilation)',
        full: 'Differential Factor Room Temperature (Ventilation) \r\nf required, set the differential factor for the PID temperature control (room air/ventilation) here. The differential factor is proportional to the change in value between two cycles of the control algorithm. The factor has no unit.',
        image: ''
      },
      de: {
        brief: 'Differentieller Faktor Raumtemperatur (Lüftung)',
        full: 'Differentieller Faktor Raumtemperatur (Lüftung) \r\nStelle hier bei Bedarf den differenziellen Faktor für die PID-Temperaturregelung (Raumluft/Lüftung) ein. Der differenzielle Anteil ist proportional zu der Wert-Änderung zwischen zwei Durchläufen des Regelungsalgorithmus. Der Faktor hat keine Einheit.',
        image: ''
      },
      fr: {
        brief: 'Facteur différentiel température ambiante (ventilation)',
        full: "Facteur différentiel température ambiante (ventilation) \nRéglez ici, si nécessaire, le facteur différentiel pour la régulation de la température PID (air ambiant/ventilation). Le facteur différentiel est proportionnel à la variation de la valeur entre deux passages de l'algorithme de régulation. Le facteur n'a pas d'unité",
        image: ''
      },
      es: {
        brief: 'Factor diferencial temperatura ambiente (ventilación)',
        full: 'Ajuste aquí el factor diferencial para el control de temperatura PID (aire ambiente/ventilación) si es necesario. El factor diferencial es proporcional al cambio de valor entre dos ejecuciones del algoritmo de control. El factor no tiene unidad',
        image: ''
      },
      it: {
        brief: 'Fattore differenziale temperatura ambiente (ventilazione)',
        full: "Fattore differenziale temperatura ambiente (ventilazione) \r\nSe necessario, impostare qui il fattore differenziale per il controllo della temperatura PID (aria ambiente/ventilazione). Il fattore differenziale è proporzionale alla variazione di valore tra due esecuzioni dell'algoritmo di controllo. Il fattore non ha unità.",
        image: ''
      }
    },
    interval_ramp_temp_sp: {
      en: {
        brief: 'Duration Temperature Transition Day/Nigt/CO₂',
        full: "Duration Temperature Transition Day/Nigt/CO₂ \r\nWhen switching between day/night/CO₂, the setpoint temperature usually changes, as different setpoint temperatures are usually set for the modes mentioned. The change of the setpoint value used for temperature control (ventilation, cooling) is slowly raised or decreased by the controller along a ramp. \r\nThis is particularly important for two scenarios: \r\n1. Without the above-mentioned ramp, a connected cooling system would try to cool down immediately to the set night temperature when changing from day to night. \r\n2. A suitable ramp ensures that the ventilation reacts to the rising temperature at an early stage when there is a change from night to day, even before the measured temperature approaches the 'set temperature day'. \r\nWhich value is optimal depends on your setup. In particular, it depends on how quickly your room heats up due to the waste heat from the lamps. An optimal value is reached when the ventilation reacts early and appropriately to the rising temperature when changing from night to day. \r\n\r\nThe setpoint temperature is updated immediately when: \r\n1.	The setpoint temperature for the current mode (day/night/CO₂) has been changed by the user. \r\n2.	When a change between (day/night/CO₂) occurs due to user input. (e.g. change of the current time).",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      },
      de: {
        brief: 'Dauer Temperaturübergang Tag/Nacht/CO₂',
        full: 'Dauer Temperaturübergang Tag/Nacht/CO₂ \r\nBei einem Wechsel zwischen Tag/Nacht/CO₂ ändert sich in der Regel die Solltemperatur, da für die genannten Modi in der Regel unterschiedliche Soll-Temperaturen eingestellt sind. Die Änderung des Soll-Wertes, der für die Temperaturregelung (Lüftung, Kühlung) genutzt wird, wird vom Controller langsam entlang einer Rampe angehoben oder abgesenkt. \r\n\r\nDies ist insbesondere für zwei Szenarien wichtig: \r\n1. Ohne die genannte Rampe würde bei einem Wechsel von Tag auf Nacht eine angeschlossene Kühlung versuchen unmittelbar auf die eingestellte Nachttemperatur abzukühlen. \r\n2. Durch eine geeignete Rampe wird erreicht, dass bei einem Wechsel von Nacht auf Tag die Lüftung frühzeitig auf die steigende Temperatur reagiert, schon bevor die gemessene Temperatur sich der „Solltemperatur Tag“ nährt. \r\n\r\nWelcher Wert optimal ist, hängt von Ihrem Setup ab. Insbesondere davon, wie schnell sich Ihr Raum durch die Abwärme der Lampen aufheizt. Ein optimaler Wert ist erreicht, wenn die Lüftung frühzeitig und angemessen auf die steigende Temperatur beim Wechsel von Nacht auf Tag reagiert. \r\n\r\nDie Solltemperatur wird sofort aktualisiert, wenn: \r\n1. Die Solltemperatur für den aktuellen Modus (Tag/Nacht/CO₂) durch den Benutzer geändert wurde. \r\n2. Wenn ein Wechsel zwischen (Tag/Nacht/CO₂) aufgrund von Benutzereingaben erfolgt. (z.B. Änderung der aktuellen Uhrzeit)',
        image: 'https://docs.growcontrol.de/temp_trans_heater_DE_x820.png'
      },
      fr: {
        brief: 'Durée de la transition de température jour/nuit/CO₂',
        full: "Durée de la transition de température jour/nuit/CO₂ \r\nLors d'un changement entre Jour/Nuit/CO₂, la température de consigne change en général, car des températures de consigne différentes sont généralement réglées pour les modes mentionnés. La modification de la valeur de consigne utilisée pour la régulation de la température (ventilation, refroidissement) est lentement augmentée ou diminuée par le contrôleur le long d'une rampe. \r\n\r\nCeci est particulièrement important pour deux scénarios : \r\n1. Sans la rampe mentionnée, lors du passage du jour à la nuit, un système de refroidissement connecté essaierait de refroidir immédiatement à la température de nuit définie. \r\n2. Une rampe appropriée permet à la ventilation de réagir rapidement à la hausse de la température lors du passage de la nuit au jour, avant même que la température mesurée ne s'approche de la 'température de consigne de jour'. \r\n\r\nLa valeur optimale dépend de votre configuration. En particulier de la vitesse à laquelle votre pièce se réchauffe sous l'effet de la chaleur dégagée par les lampes. Une valeur optimale est atteinte lorsque la ventilation réagit de manière précoce et appropriée à l'augmentation de la température lors du passage de la nuit au jour. \r\n\rLa température de consigne est immédiatement mise à jour lorsque : \r\n1. La température de consigne pour le mode actuel (jour/nuit/CO₂) a été modifiée par l'utilisateur. \r\n2. Si un changement entre (Jour/Nuit/CO₂) a lieu en raison d'une entrée de l'utilisateur. (par exemple, modification de l'heure actuelle)",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      },
      es: {
        brief: 'Duración temperatura transición día/noche/CO₂',
        full: "Duración temperatura transición día/noche/CO₂ \r\nAl cambiar entre día/noche/CO₂la temperatura de consigna suele cambiar porque normalmente se establecen temperaturas de consigna diferentes para los modos mencionados. El cambio del valor de consigna utilizado para el control de la temperatura (ventilación, refrigeración) es elevado o reducido lentamente por el regulador a lo largo de una rampa. \r\nEsto es especialmente importante en dos casos: \r\n1. Sin la rampa antes mencionada, un sistema de refrigeración conectado trataría de enfriar inmediatamente a la temperatura nocturna establecida al cambiar de día a noche. \r\n2. Una rampa adecuada garantiza que la ventilación reaccione al aumento de la temperatura en una fase temprana cuando se produce un cambio de noche a día, incluso antes de que la temperatura medida se aproxime a la 'temperatura de consigna día. \r\nEl valor óptimo depende de su instalación. En particular, depende de lo rápido que se caliente la habitación debido al calor residual de las lámparas. Se alcanza un valor óptimo cuando la ventilación reacciona pronto y adecuadamente al aumento de la temperatura al pasar de la noche al día. \nLa temperatura de consigna se actualiza inmediatamente cuando: \r\n1. La temperatura de consigna para el modo actual (día/noche/CO₂) ha sido modificada por el usuario. \r\n2. Cuando se produce un cambio entre (día/noche/CO₂) debido a la entrada del usuario. (por ejemplo, cambio de la hora actual)",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      },
      it: {
        brief: 'Durata della transizione di temperatura giorno/notte/CO₂',
        full: '',
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      }
    },
    reg_type_humidifier: {
      en: {
        brief: 'Regulation Type Humidifier',
        full: "Regulation type humidifier \r\nSet here whether the control for a dehumidifier operates as a P, PD or PID controller. \r\nIn the 'PID' mode the humidifier is switched on and off very frequently, in the 'PD' mode it is switched on and off less frequently, and in the 'P' mode it is switched on and off least frequently. \r\nWith the PID control algorithm the most accurate humidity control is possible. Accordingly, when VPD control is active, the VPD value is achieved more accurately.",
        image: ''
      },
      de: {
        brief: 'Regelungs-Typ Befeuchter',
        full: "Regelungs-Typ Befeuchter \r\nStelle hier ein, ob die Regelung für einen Luftentfeuchter als P-, PD- oder PID-Regler arbeitet. \r\nIm Modus 'PID' wird der Luftbefeuchter sehr häufig ein- und ausgeschaltet, im Modus 'PD' seltener und im Modus 'P' am seltensten. \r\nMit dem PID-Regelungs-Algorithmus ist die genaueste Luftfeuchte-Regelung möglich. Dementsprechend wird bei aktiver VPD-Regelung der VPD-Wert genauer erreicht.",
        image: ''
      },
      fr: {
        brief: "Type de régulation de l'humidificateur",
        full: "Type de régulation humidificateur \r\nRéglez ici si la régulation pour un déshumidificateur fonctionne comme régulateur P, PD ou PID. \r\nEn mode 'PID', l'humidificateur est très souvent allumé et éteint, en mode 'PD' moins souvent et en mode 'P' le moins souvent. \r\nL'algorithme de régulation PID permet la régulation la plus précise de l'humidité de l'air. En conséquence, lorsque la régulation VPD est active, la valeur VPD est atteinte plus précisément",
        image: ''
      },
      es: {
        brief: 'Humidificador de tipo control',
        full: "Tipo de control del humidificador \r\nAquí se establece si el control para un deshumidificador funciona como controlador P, PD o PID. En el modo 'PID', el humidificador se enciende y apaga con mucha frecuencia, en el modo 'PD' con menos frecuencia y en el modo 'P' con menos frecuencia. El algoritmo de control PID permite un control más preciso de la humedad. Por consiguiente, cuando el control VPD está activo, el valor VPD se alcanza con mayor precisión.",
        image: ''
      },
      it: {
        brief: 'Tipo di regolazione umidificatore',
        full: "Tipo di regolazione umidificatore \r\nImpostare qui se il controllo di un deumidificatore funziona come controllore P, PD o PID. \r\nIn modalità 'PID' l'umidificatore viene acceso e spento molto frequentemente, in modalità 'PD' meno frequentemente e in modalità 'P' meno frequentemente. \r\nIl controllo dell'umidità più accurato è possibile con l'algoritmo di controllo PID. Di conseguenza, quando il controllo VPD è attivo, il valore VPD viene raggiunto con maggiore precisione.",
        image: ''
      }
    },

    humidifier_kd: {
      en: {
        brief: 'Differential Factor Humidifier',
        full: 'Differential proportion/factor of humidifiers PD or PID control  \r\nWith a higher value, the control reacts more strongly to changes in the currently measured air humidity value. The factor has no unit.',
        image: ''
      },
      de: {
        brief: 'Differientiller Faktor Befeuchter',
        full: 'Differentieller Anteil/Faktor der Befeuchter PD- oder PID-Regelung \r\nMit einem höheren Wert reagiert die Regelung stärker auf Änderungen des aktuell gemessenen Luftfeuchte-Wertes. Der Faktor hat keine Einheit.',
        image: ''
      },
      fr: {
        brief: 'Facteur différentiel humidificateur',
        full: "Part/facteur différentiel de l'humidificateur Régulation PD ou PID \r\nAvec une valeur plus élevée, la régulation réagit plus fortement aux changements de la valeur d'humidité de l'air actuellement mesurée. Le facteur n'a pas d'unité",
        image: ''
      },
      es: {
        brief: 'Factor diferencial humidificador',
        full: 'Proporción/factor diferencial del humidificador PD o control PID \r\nCon un valor más alto, el control reacciona más fuertemente a los cambios en el valor de humedad medido actualmente. El factor no tiene unidad.',
        image: ''
      },
      it: {
        brief: 'Fattore differenziale umidificatore',
        full: "Parte/fattore differenziale dell'umidificatore PD o controllo PID \r\nCon un valore più alto, il controllo reagisce più fortemente ai cambiamenti del valore di umidità dell'aria attualmente misurato. Il fattore non ha unità.",
        image: ''
      }
    },
    hysteresis_humidifier: {
      en: {
        brief: 'Hysteresis Humidifier',
        full: "Hysteresis Humidifier \r\nSet the hysteresis for the control of a humidifier here. The hysteresis determines how often the humidifier is switched on and off and how precisely the setpoint is reached or how large the fluctuations of the actual value are. If the control operates as a P-controller, a setting of 1.5 means '±1.5 %RH'. For a PD or PID controller, no exact statement can be made, as the rate of change also enters into the control. In modes PD and PID the setting has no unit.",
        image: ''
      },
      de: {
        brief: 'Hysterese Luftbefeuchter',
        full: "Hysterese Luftbefeuchter \r\nStelle hier die Hysterese für die Regelung eines Luftbefeuchters ein. Die Hysterese bestimmt, wie häufig der Luftbefeuchter ein- und ausgeschaltet wird und wie genau der Sollwert erreicht wird bzw. wie groß die Schwankungen des Ist-Wertes sind. Wenn die Regelung als P-Regler arbeitet, bedeutet ein Einstell-Wert von 1.5 '±1.5%RH'. Für einen PD- oder PID-Regler kann keine genaue Aussage getroffen werden, da auch die Änderungsgeschwindigkeit in die Regelung eingeht. In den Modi PD und PID hat der Wert keine Einheit.",
        image: ''
      },
      fr: {
        brief: 'Hystérésis humidificateur',
        full: "Hystérésis humidificateur d'air \r\nRéglez ici l'hystérésis pour la régulation d'un humidificateur d'air. L'hystérésis détermine la fréquence de mise en marche et d'arrêt de l'humidificateur d'air et la précision avec laquelle la valeur de consigne est atteinte ou l'ampleur des variations de la valeur réelle. Si la régulation fonctionne comme un régulateur P, une valeur de réglage de 1,5 signifie '±1,5%RH'. Pour un régulateur PD ou PID, il n'est pas possible de donner une indication précise, car la vitesse de variation est également prise en compte dans la régulation. Dans les modes PD et PID, la valeur n'a pas d'unité",
        image: ''
      },
      es: {
        brief: 'Histéresis humidificador',
        full: "Histéresis humidificador \r\nAquí se ajusta la histéresis para el control de un humidificador. La histéresis determina la frecuencia de conexión y desconexión del humidificador y la precisión con la que se alcanza el valor nominal o la magnitud de las fluctuaciones del valor real. Si el control funciona como un regulador P, un valor de ajuste de 1,5 significa '±1,5%RH'. Para un regulador PD o PID, no se puede hacer una afirmación exacta, ya que la tasa de cambio también entra en el control. En los modos PD y PID, el valor no tiene unidad.",
        image: ''
      },
      it: {
        brief: 'Isteresi umidificatore',
        full: "Isteresi umidificatore \r\nImposta qui l'isteresi per il controllo di un umidificatore. L'isteresi determina la frequenza di accensione e spegnimento dell'umidificatore e la precisione con cui viene raggiunto il valore nominale o l'entità delle fluttuazioni del valore reale. Se il controllo funziona come un regolatore P, un valore di impostazione di 1,5 significa '±1,5%RH'. Per un regolatore PD o PID, non è possibile fare una dichiarazione esatta, poiché il tasso di cambiamento entra anche nel controllo. Nei modi PD e PID il valore non ha unità.",
        image: ''
      }
    },
    offset_sp_humidifier: {
      en: {
        brief: 'Distance between setpoints for humidifier and dehumidifier',
        full: 'Distance between setpoints for humidifier and dehumidifier \r\nTo prevent the humidifier and dehumidifier from working against each other, a distance between the setpoints of the humidifier and dehumidifier can be set here. \r\nExample: RH setpoint = 60% RH\r\nDistance should humidifier = 0.5 % RH \r\nThe setpoint for a humidifier is 59.5 % RH.',
        image: ''
      },
      de: {
        brief: 'Abstand zwischen den Sollwerten für Befeuchter und Entfeuchter',
        full: 'Abstand Sollwert Entfeucher \r\nUm zu vermeiden, dass Luftbe- und Entfeuchter gegeneinander arbeiten, kann hier ein Abstand der Sollwerte von Luftbe- und Entfeuchter eingestellt werden. \r\nBeispiel: RH Soll = 60% RH\r\nAbstand soll Befeuchter = 0.5 % RH \r\nDer Sollwert für einen Luftbefeuchter ist 59.5% RH.',
        image: ''
      },
      fr: {
        brief: "Ecart entre les valeurs de consigne de l'humidificateur et du déshumidificateur",
        full: "Ecart entre les valeurs de consigne du déshumidificateur \r\nPour éviter que l'humidificateur et le déshumidificateur ne fonctionnent en opposition, il est possible de régler ici un écart entre les valeurs de consigne de l'humidificateur et du déshumidificateur. \r\nExemple : RH consigne = 60% RH\r\nLa distance doit être de 0.5 % RH pour le humidificateur \r\nLa valeur de consigne pour un humidificateur est de 59.5 % RH.",
        image: ''
      },
      es: {
        brief: 'Distancia entre puntos de consigna humidificador y deshumidificador',
        full: 'Distancia punto de consigna deshumidificador \r\nPara evitar que el humidificador y el deshumidificador trabajen el uno contra el otro, aquí se puede establecer una distancia entre los puntos de consigna del humidificador y del deshumidificador. Ejemplo: Valor de consigna de HR = 60% HR Distancia entre valores de consigna del humidificador = 0.5 % HR \r\nEl valor de consigna del humidificador es 59.5 % HR.',
        image: ''
      },
      it: {
        brief: 'Distanza setpoint deumidificatore',
        full: "Distanza setpoint deumidificatore \r\nPer evitare che l'umidificatore e il deumidificatore lavorino l'uno contro l'altro, è possibile impostare una distanza tra i setpoint dell'umidificatore e del deumidificatore. Esempio: setpoint RH = 60% RH \r\nDistanza dall'umidificatore = 0,5% RH \r\nIl setpoint dell'umidificatore è 59,5% RH.",
        image: ''
      }
    },
    reg_type_dehumidifier: {
      en: {
        brief: 'Regulation Type Dehumidifier',
        full: "Regulation Type Dehumidifier \r\nSet here whether the control for a dehumidifier operates as a PD or P controller. Usually 'P' is the better option for a dehumidifier.",
        image: ''
      },
      de: {
        brief: 'Regelungs-Typ Luftentfeuchter',
        full: "Regelungs-Typ Luftbefeuchter \r\nStelle hier ein, ob die Regelung für einen Luftentfeuchter als PD- oder P-Regler arbeitet. Normalerweise ist die Option 'p' besser für einen Luftentfeuchter geeignet.",
        image: ''
      },
      fr: {
        brief: 'Type de régulation déshumidificateur',
        full: "Type de régulation Humidificateur d'air\nRéglez ici si la régulation pour un déshumidificateur d'air fonctionne comme régulateur PD ou P. Normalement, l'option 'p' est plus appropriée pour un déshumidificateur",
        image: ''
      },
      es: {
        brief: 'Regelungs-Typ Luftbefeuchter',
        full: "Regelungs-Typ Luftbefeuchter \r\nStelle hier ein, ob die Regelung für einen Luftentfeuchter als PD- oder P-Regler arbeitet. Normalerweise ist die Option 'p' besser für einen Luftentfeuchter geeignet.",
        image: ''
      },
      it: {
        brief: 'Tipo di regolazione deumidificatore',
        full: "Tipo di regolazione deumidificatore \r\nImposta qui se il controllo per un deumidificatore funziona come regolatore PD o P. Di solito 'P' è la migliore opzione per un deumidificatore.",
        image: ''
      }
    },
    hysteresis_dehumidifier: {
      en: {
        brief: 'Hysteresis for Dehumidifier',
        full: "Set the hysteresis for the control of a dehumidifier here. If the control operates as a P-controller, a setting value of 5.0 means '±5.0%RH'. In mode PD, no exact statement can be made, as the rate of change also enters into the control. In mode PD the setting has no unit.",
        image: ''
      },
      de: {
        brief: 'Hysterese für Entfeuchter',
        full: "Stelle hier die Hysterese für die Regelung eines Luftentfeuchters ein. Wenn die Regelung als P-Regler arbeitet, bedeutet ein Einstell-Wert von 5.0 '±5.0%RH'. Für einen PD-Regler kann keine genaue Aussage getroffen werden, da auch die Änderungsgeschwindigkeit in die Regelung eingeht. Im Modus PD hat der Wert keine Einheit.",
        image: ''
      },
      fr: {
        brief: 'Hystérésis pour déshumidificateur',
        full: "Régler ici l'hystérésis pour la régulation d'un déshumidificateur. Si la régulation fonctionne comme un régulateur P, une valeur de réglage de 5.0 signifie '±5.0%RH'. Pour un régulateur PD, il n'est pas possible de donner une indication précise, car la vitesse de variation est également prise en compte dans la régulation. En mode PD, la valeur n'a pas d'unité",
        image: ''
      },
      es: {
        brief: 'Histéresis para deshumidificador',
        full: "Ajuste aquí la histéresis para el control de un deshumidificador. Si el control funciona como un controlador P, un valor de ajuste de 5,0 significa '±5,0%RH'. Para un controlador PD, no se puede hacer una afirmación exacta porque la tasa de cambio también entra en el control. En el modo PD, el valor no tiene unidad.",
        image: ''
      },
      it: {
        brief: 'Isteresi per deumidificatore',
        full: "Imposta qui l'isteresi per il controllo di un deumidificatore. Se il controllo funziona come un regolatore P, un valore di impostazione di 5,0 significa '±5,0%RH'. In modalità PD, non è possibile fare una dichiarazione esatta, poiché il tasso di variazione entra anche nel controllo. In modalità PD, il valore non ha unità.",
        image: ''
      }
    },
    offset_sp_dehumidifier: {
      en: {
        brief: 'Distance between setpoints for humidifier and dehumidifier',
        full: 'Distance between setpoints for humidifier and dehumidifier \r\nTo prevent the humidifier and dehumidifier from working against each other, a distance between the setpoints of the humidifier and dehumidifier can be set here. \r\nExample: RH setpoint = 60% RH\r\nDistance should dehumidifier = 9% RH \r\nThe setpoint for a dehumidifier is 69% RH.',
        image: ''
      },
      de: {
        brief: 'Abstand zwischen den Sollwerten für Befeuchter und Entfeuchter',
        full: 'Abstand Sollwert Entfeucher \r\nUm zu vermeiden, dass Luftbe- und Entfeuchter gegeneinander arbeiten, kann hier ein Abstand der Sollwerte von Luftbe- und Entfeuchter eingestellt werden. \r\nBeispiel: RH Soll = 60% RH\r\nAbstand soll Entfeuchter = 9%RH \r\nDer Sollwert für einen Luftentfeuchter ist 69% RH.',
        image: ''
      },
      fr: {
        brief: "Ecart entre les valeurs de consigne de l'humidificateur et du déshumidificateur",
        full: "Ecart entre les valeurs de consigne du déshumidificateur \r\nPour éviter que l'humidificateur et le déshumidificateur ne fonctionnent en opposition, il est possible de régler ici un écart entre les valeurs de consigne de l'humidificateur et du déshumidificateur. \r\nExemple : RH consigne = 60% RH\r\nLa distance doit être de 9%RH pour le déshumidificateur \r\nLa valeur de consigne pour un déshumidificateur est de 69% RH.",
        image: ''
      },
      es: {
        brief: 'Distancia entre puntos de consigna humidificador y deshumidificador',
        full: 'Distancia punto de consigna deshumidificador \r\nPara evitar que el humidificador y el deshumidificador trabajen el uno contra el otro, aquí se puede establecer una distancia entre los puntos de consigna del humidificador y del deshumidificador. Ejemplo: Valor de consigna de HR = 60% HR Distancia entre valores de consigna del deshumidificador = 9% HR \r\nEl valor de consigna del deshumidificador es 69% HR',
        image: ''
      },
      it: {
        brief: 'Distanza setpoint deumidificatore',
        full: "Distanza setpoint deumidificatore \r\nPer evitare che l'umidificatore e il deumidificatore lavorino l'uno contro l'altro, è possibile impostare una distanza tra i setpoint dell'umidificatore e del deumidificatore. Esempio: setpoint RH = 60% RH \r\nDistanza setpoint deumidificatore = 9% RH \r\nIl setpoint per un deumidificatore è 69% RH.",
        image: ''
      }
    },
    hysteresis_heater: {
      en: {
        brief: 'Hysteresis Heater',
        full: 'Hysteresis Heater \r\nSet the hysteresis for the control of a heater here. A value of 0.2 means ±0.2°C hysteresis.',
        image: ''
      },
      de: {
        brief: 'Hysterese für Heizungs-Regelung',
        full: 'Hystereses für Heizungs-Regelung \r\nStelle hier die Hysterese für die Regelung einer Heizung ein. Ein Wert von 0.2 bedeutet ±0.2°C Hysterese',
        image: ''
      },
      fr: {
        brief: 'Hystérésis pour la régulation du chauffage',
        full: "Hystérésis pour la régulation du chauffage \nRéglez ici l'hystérésis pour la régulation d'un chauffage. Une valeur de 0.2 signifie ±0.2°C d'hystérésis",
        image: ''
      },
      es: {
        brief: 'Histéresis para el control de la calefacción',
        full: 'Histéresis para control de calefacción \r\nAquí se ajusta la histéresis para el control de una calefacción. Un valor de 0,2 significa una histéresis de ±0,2°C',
        image: ''
      },
      it: {
        brief: 'Isteresi per il controllo del riscaldamento',
        full: "Isteresi per il controllo del riscaldamento \r\nImposta qui l'isteresi per il controllo di un riscaldamento. Un valore di 0,2 significa ±0,2°C di isteresi",
        image: ''
      }
    },
    hysteresis_chiller_low: {
      en: {
        brief: 'Lower Hysteresis Chiller',
        full: "Lower hysteresis setting for chiller \r\nA connected chiller is switched off if the measured temperature is below 'Set temperature - Lower hysteresis'.",
        image: ''
      },
      de: {
        brief: 'Untere Hysterese-Einstellung für Kühlung',
        full: "Untere Hysterese-Einstellung für Kühlung \r\nEine angeschlossene Kühlung wird abgeschaltet, wenn die gemessene Temperatur unter 'Solltemperatur - Untere Hysterese' liegt.",
        image: ''
      },
      fr: {
        brief: "Réglage de l'hystérésis inférieure pour le refroidissement",
        full: "Réglage inférieur de l'hystérésis pour la climatisation\nUne climatisation raccordée est désactivée lorsque la température mesurée est inférieure à 'Température de consigne - Hystérésis inférieure'",
        image: ''
      },
      es: {
        brief: 'Ajuste de histéresis inferior para refrigeración',
        full: "Ajuste de histéresis inferior para refrigeración \r\nUna refrigeración conectada se desconecta si la temperatura medida es inferior a 'Temperatura de consigna - Histéresis inferior'",
        image: ''
      },
      it: {
        brief: "Impostazione dell'isteresi inferiore per il raffreddamento",
        full: "Impostazione dell'isteresi inferiore per il raffreddamento \r\nUn raffreddamento collegato viene spento se la temperatura misurata è inferiore a 'Temperatura impostata - Isteresi inferiore'",
        image: ''
      }
    },
    hysteresis_chiller_high: {
      en: {
        brief: 'Upper Hysteresis Setting for Chiller',
        full: "Upper Hysteresis Setting for Chiller \r\nA connected chiller is switched on if the measured temperature is above 'Set temperature + Upper hysteresis'.",
        image: ''
      },
      de: {
        brief: 'Obere Hysterese-Einstellung für Kühlung',
        full: "Obere Hysterese-Einstellung für Kühlung \r\nEine angeschlossene Kühlung wird eingeschaltet, wenn die gemessene Temperatur über 'Solltemperatur + Obere Hysterese' liegt.",
        image: ''
      },
      fr: {
        brief: "Réglage de l'hystérésis supérieure pour le refroidissement",
        full: "Réglage supérieur de l'hystérésis pour la climatisation\nUne climatisation raccordée est activée lorsque la température mesurée est supérieure à la 'température de consigne + hystérésis supérieure'",
        image: ''
      },
      es: {
        brief: 'Ajuste de histéresis superior para refrigeración',
        full: "Ajuste de histéresis superior para refrigeración \r\nUna refrigeración conectada se activa cuando la temperatura medida es superior a 'Temperatura de consigna + Histéresis superior'.",
        image: ''
      },
      it: {
        brief: "Impostazione dell'isteresi superiore per il raffreddamento",
        full: "Impostazione dell'isteresi superiore per il raffreddamento \r\nUn raffreddamento collegato viene acceso se la temperatura misurata è superiore a 'Temperatura impostata + Isteresi superiore'",
        image: ''
      }
    },
    reset_event_dates: {
      en: {
        brief: 'Event Rhythm Data Reset',
        full: 'Event Rhythm Data Reset \r\n',
        image: ''
      },
      de: {
        brief: 'Event Rhythmus Daten Löschen',
        full: 'Event Rhythmus Daten Löschen \r\n',
        image: ''
      },
      fr: {
        brief: "Réinitialiser les données de rythme d'événement",
        full: "Réinitialiser les données de rythme d'événement \r\n",
        image: ''
      },
      es: {
        brief: 'Reiniciar datos de ritmo de eventos',
        full: 'Reiniciar datos de ritmo de eventos \r\n',
        image: ''
      },
      it: {
        brief: 'Reset dati ritmo eventi',
        full: 'Reset dati ritmo eventi \r\n',
        image: ''
      }
    },
    pre_release_notice: {
      en: {
        brief: 'This is a beta version',
        full: 'This is a beta version. \r\nWe are still working on the system and not all features are available yet. \r\nPlease let us know if you encounter any problems or if you have any questions or suggestions.',
        image: ''
      },
      de: {
        brief: 'Dies is eine beta Version',
        full: 'Dies is eine beta Version. \r\nWir arbeiten noch an dem System und es stehen noch nicht alle Funktionen zur Verfügung. \r\nBitte gib uns Bescheid, falls du Probleme feststellst oder falls du Fragen oder Anregungen hast.',
        image: ''
      },
      fr: {
        brief: 'Ceci est une version bêta',
        full: "Ceci est une version bêta. \r\nNNous travaillons encore sur le système et toutes les fonctionnalités ne sont pas encore disponibles. \r\nS'il vous plaît, faites-nous savoir si vous rencontrez des problèmes ou si vous avez des questions ou des suggestions.",
        image: ''
      },
      es: {
        brief: 'Esta es una versión beta',
        full: 'Esta es una versión beta. Todavía estamos trabajando en el sistema y no todas las características están disponibles todavía. Por favor, háganos saber si usted experimenta cualquier problema o si tiene alguna pregunta o sugerencia.',
        image: ''
      },
      it: {
        brief: 'Questa è una versione beta',
        full: 'Questa è una versione beta. \r\nStiamo ancora lavorando sul sistema e non tutte le funzionalità sono ancora disponibili. \r\nPer favore, fateci sapere se riscontrate problemi o se avete domande o suggerimenti.',
        image: ''
      }
    }
  },
  5055: {
    interval_ramp_temp_sp: {
      en: {
        brief: 'Duration Temperature Transition Day/Nigt/CO₂',
        full: "Duration Temperature Transition Day/Nigt/CO₂ \r\nWhen switching between day/night/CO₂, the setpoint temperature usually changes, as different setpoint temperatures are usually set for the modes mentioned. The change of the setpoint value used for temperature control (ventilation, cooling) is slowly raised or decreased by the controller along a ramp. \r\nThis is particularly important for two scenarios: \r\n1. Without the above-mentioned ramp, a connected cooling system would try to cool down immediately to the set night temperature when changing from day to night. \r\n2. A suitable ramp ensures that the ventilation reacts to the rising temperature at an early stage when there is a change from night to day, even before the measured temperature approaches the 'set temperature day'. \r\nWhich value is optimal depends on your setup. In particular, it depends on how quickly your room heats up due to the waste heat from the lamps. An optimal value is reached when the ventilation reacts early and appropriately to the rising temperature when changing from night to day. \r\n\r\nThe setpoint temperature is updated immediately when: \r\n1.	The setpoint temperature for the current mode (day/night/CO₂) has been changed by the user. \r\n2.	When a change between (day/night/CO₂) occurs due to user input. (e.g. change of the current time).",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      },
      de: {
        brief: 'Dauer Temperaturübergang Tag/Nacht/CO₂',
        full: 'Dauer Temperaturübergang Tag/Nacht/CO₂ \r\nBei einem Wechsel zwischen Tag/Nacht/CO₂ ändert sich in der Regel die Solltemperatur, da für die genannten Modi in der Regel unterschiedliche Soll-Temperaturen eingestellt sind. Die Änderung des Soll-Wertes, der für die Temperaturregelung (Lüftung, Kühlung) genutzt wird, wird vom Controller langsam entlang einer Rampe angehoben oder abgesenkt. \r\n\r\nDies ist insbesondere für zwei Szenarien wichtig: \r\n1. Ohne die genannte Rampe würde bei einem Wechsel von Tag auf Nacht eine angeschlossene Kühlung versuchen unmittelbar auf die eingestellte Nachttemperatur abzukühlen. \r\n2. Durch eine geeignete Rampe wird erreicht, dass bei einem Wechsel von Nacht auf Tag die Lüftung frühzeitig auf die steigende Temperatur reagiert, schon bevor die gemessene Temperatur sich der „Solltemperatur Tag“ nährt. \r\n\r\nWelcher Wert optimal ist, hängt von Ihrem Setup ab. Insbesondere davon, wie schnell sich Ihr Raum durch die Abwärme der Lampen aufheizt. Ein optimaler Wert ist erreicht, wenn die Lüftung frühzeitig und angemessen auf die steigende Temperatur beim Wechsel von Nacht auf Tag reagiert. \r\n\r\nDie Solltemperatur wird sofort aktualisiert, wenn: \r\n1. Die Solltemperatur für den aktuellen Modus (Tag/Nacht/CO₂) durch den Benutzer geändert wurde. \r\n2. Wenn ein Wechsel zwischen (Tag/Nacht/CO₂) aufgrund von Benutzereingaben erfolgt. (z.B. Änderung der aktuellen Uhrzeit)',
        image: 'https://docs.growcontrol.de/temp_trans_heater_DE_x820.png'
      },
      fr: {
        brief: 'Durée de la transition de température jour/nuit/CO₂',
        full: "Durée de la transition de température jour/nuit/CO₂ \r\nLors d'un changement entre Jour/Nuit/CO₂, la température de consigne change en général, car des températures de consigne différentes sont généralement réglées pour les modes mentionnés. La modification de la valeur de consigne utilisée pour la régulation de la température (ventilation, refroidissement) est lentement augmentée ou diminuée par le contrôleur le long d'une rampe. \r\n\r\nCeci est particulièrement important pour deux scénarios : \r\n1. Sans la rampe mentionnée, lors du passage du jour à la nuit, un système de refroidissement connecté essaierait de refroidir immédiatement à la température de nuit définie. \r\n2. Une rampe appropriée permet à la ventilation de réagir rapidement à la hausse de la température lors du passage de la nuit au jour, avant même que la température mesurée ne s'approche de la 'température de consigne de jour'. \r\n\r\nLa valeur optimale dépend de votre configuration. En particulier de la vitesse à laquelle votre pièce se réchauffe sous l'effet de la chaleur dégagée par les lampes. Une valeur optimale est atteinte lorsque la ventilation réagit de manière précoce et appropriée à l'augmentation de la température lors du passage de la nuit au jour. \r\n\rLa température de consigne est immédiatement mise à jour lorsque : \r\n1. La température de consigne pour le mode actuel (jour/nuit/CO₂) a été modifiée par l'utilisateur. \r\n2. Si un changement entre (Jour/Nuit/CO₂) a lieu en raison d'une entrée de l'utilisateur. (par exemple, modification de l'heure actuelle)",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      },
      es: {
        brief: 'Duración temperatura transición día/noche/CO₂',
        full: "Duración temperatura transición día/noche/CO₂ \r\nAl cambiar entre día/noche/CO₂ \r\nLa temperatura de consigna suele cambiar, ya que para los modos mencionados suelen fijarse temperaturas de consigna diferentes. El cambio del valor de consigna utilizado para el control de la temperatura (ventilación, refrigeración) es elevado o reducido lentamente por el regulador a lo largo de una rampa. \r\nEsto es especialmente importante en dos casos: \r\n1. Sin la rampa antes mencionada, un sistema de refrigeración conectado trataría de enfriar inmediatamente a la temperatura nocturna establecida al cambiar de día a noche. \r\n2. Una rampa adecuada garantiza que la ventilación reaccione al aumento de la temperatura en una fase temprana cuando se produce un cambio de noche a día, incluso antes de que la temperatura medida se aproxime a la 'temperatura de consigna día'. \r\nEl valor óptimo depende de su instalación. En particular, depende de lo rápido que se caliente la habitación debido al calor residual de las lámparas. Se alcanza un valor óptimo cuando la ventilación reacciona pronto y adecuadamente al aumento de la temperatura al pasar de la noche al día. \nLa temperatura de consigna se actualiza inmediatamente cuando: \r\n1. La temperatura de consigna para el modo actual (día/noche/CO₂) ha sido modificada por el usuario. \r\n2. Cuando se produce un cambio entre (día/noche/CO₂) debido a la entrada del usuario. (por ejemplo, cambio de la hora actual).",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      },
      it: {
        brief: 'Durata della transizione di temperatura giorno/notte/CO₂',
        full: "Durata della transizione di temperatura giorno/notte/CO₂ \r\nQuando si passa da giorno/notte/CO₂, la temperatura di setpoint di solito cambia, in quanto per le modalità citate vengono solitamente impostate temperature di setpoint diverse. La variazione del valore nominale utilizzato per la regolazione della temperatura (ventilazione, raffreddamento) viene aumentata o diminuita lentamente dal regolatore lungo una rampa. Questo è particolarmente importante per due scenari: \r\n1. Senza la suddetta rampa, un sistema di raffreddamento collegato cercherebbe di raffreddare immediatamente alla temperatura notturna impostata quando si passa dal giorno alla notte. \r\n2. Una rampa adeguata garantisce che la ventilazione reagisca all'aumento della temperatura in una fase precoce quando si passa dalla notte al giorno, anche prima che la temperatura misurata si avvicini alla 'temperatura diurna impostata'. \r\nIl valore ottimale dipende dalla configurazione. In particolare, dipende dalla velocità con cui l'ambiente si riscalda a causa del calore residuo delle lampade. Il valore ottimale si raggiunge quando la ventilazione reagisce tempestivamente e in modo adeguato all'aumento della temperatura nel passaggio dalla notte al giorno. \r\nLa temperatura nominale viene aggiornata immediatamente quando: \r\n1. La temperatura nominale per la modalità corrente (giorno/notte/CO₂) è stata modificata dall'utente. \r\n2. Quando si verifica un cambiamento tra (giorno/notte/CO₂) a causa di un input dell'utente. (ad esempio, la modifica dell'ora corrente).",
        image: 'https://docs.growcontrol.de/temp_trans_heater_EN_x820.png'
      }
    }
  },
  5054: {
    interval_ramp_temp_sp: {
      en: {
        brief: 'Duration Temperature Transition Day/Nigt/CO₂',
        full: "Duration Temperature Transition Day/Nigt/CO₂ \r\nWhen switching between day/night/CO₂, the setpoint temperature usually changes, as different setpoint temperatures are usually set for the modes mentioned. The change of the setpoint value used for temperature control (ventilation, cooling) is slowly raised or decreased by the controller along a ramp. \r\nThis is particularly important for two scenarios: \r\n1. Without the above-mentioned ramp, a connected cooling system would try to cool down immediately to the set night temperature when changing from day to night. \r\n2. A suitable ramp ensures that the ventilation reacts to the rising temperature at an early stage when there is a change from night to day, even before the measured temperature approaches the 'set temperature day'. \r\nWhich value is optimal depends on your setup. In particular, it depends on how quickly your room heats up due to the waste heat from the lamps. An optimal value is reached when the ventilation reacts early and appropriately to the rising temperature when changing from night to day. \r\n\r\nThe setpoint temperature is updated immediately when: \r\n1.	The setpoint temperature for the current mode (day/night/CO₂) has been changed by the user. \r\n2.	When a change between (day/night/CO₂) occurs due to user input. (e.g. change of the current time).",
        image: 'https://docs.growcontrol.de/temp_trans_no_heater_EN_x820.png'
      },
      de: {
        brief: 'Dauer Temperaturübergang Tag/Nacht/CO₂',
        full: 'Dauer Temperaturübergang Tag/Nacht/CO₂ \r\nBei einem Wechsel zwischen Tag/Nacht/CO₂ ändert sich in der Regel die Solltemperatur, da für die genannten Modi in der Regel unterschiedliche Soll-Temperaturen eingestellt sind. Die Änderung des Soll-Wertes, der für die Temperaturregelung (Lüftung, Kühlung) genutzt wird, wird vom Controller langsam entlang einer Rampe angehoben oder abgesenkt. \r\n\r\nDies ist insbesondere für zwei Szenarien wichtig: \r\n1. Ohne die genannte Rampe würde bei einem Wechsel von Tag auf Nacht eine angeschlossene Kühlung versuchen unmittelbar auf die eingestellte Nachttemperatur abzukühlen. \r\n2. Durch eine geeignete Rampe wird erreicht, dass bei einem Wechsel von Nacht auf Tag die Lüftung frühzeitig auf die steigende Temperatur reagiert, schon bevor die gemessene Temperatur sich der „Solltemperatur Tag“ nährt. \r\n\r\nWelcher Wert optimal ist, hängt von Ihrem Setup ab. Insbesondere davon, wie schnell sich Ihr Raum durch die Abwärme der Lampen aufheizt. Ein optimaler Wert ist erreicht, wenn die Lüftung frühzeitig und angemessen auf die steigende Temperatur beim Wechsel von Nacht auf Tag reagiert. \r\n\r\nDie Solltemperatur wird sofort aktualisiert, wenn: \r\n1. Die Solltemperatur für den aktuellen Modus (Tag/Nacht/CO₂) durch den Benutzer geändert wurde. \r\n2. Wenn ein Wechsel zwischen (Tag/Nacht/CO₂) aufgrund von Benutzereingaben erfolgt. (z.B. Änderung der aktuellen Uhrzeit)',
        image: 'https://docs.growcontrol.de/temp_trans_no_heater_DE_x820.png'
      },
      fr: {
        brief: 'Durée de la transition de température jour/nuit/CO₂',
        full: "Durée de la transition de température jour/nuit/CO₂ \r\nLors d'un changement entre Jour/Nuit/CO₂, la température de consigne change en général, car des températures de consigne différentes sont généralement réglées pour les modes mentionnés. La modification de la valeur de consigne utilisée pour la régulation de la température (ventilation, refroidissement) est lentement augmentée ou diminuée par le contrôleur le long d'une rampe. \r\n\r\nCeci est particulièrement important pour deux scénarios : \r\n1. Sans la rampe mentionnée, lors du passage du jour à la nuit, un système de refroidissement connecté essaierait de refroidir immédiatement à la température de nuit définie. \r\n2. Une rampe appropriée permet à la ventilation de réagir rapidement à la hausse de la température lors du passage de la nuit au jour, avant même que la température mesurée ne s'approche de la 'température de consigne de jour'. \r\n\r\nLa valeur optimale dépend de votre configuration. En particulier de la vitesse à laquelle votre pièce se réchauffe sous l'effet de la chaleur dégagée par les lampes. Une valeur optimale est atteinte lorsque la ventilation réagit de manière précoce et appropriée à l'augmentation de la température lors du passage de la nuit au jour. \r\n\rLa température de consigne est immédiatement mise à jour lorsque : \r\n1. La température de consigne pour le mode actuel (jour/nuit/CO₂) a été modifiée par l'utilisateur. \r\n2. Si un changement entre (Jour/Nuit/CO₂) a lieu en raison d'une entrée de l'utilisateur. (par exemple, modification de l'heure actuelle)",
        image: 'https://docs.growcontrol.de/temp_trans_no_heater_EN_x820.png'
      },
      es: {
        brief: 'Duración temperatura transición día/noche/CO₂',
        full: "Duración temperatura transición día/noche/CO₂ \r\nAl cambiar entre día/noche/CO₂ \r\nLa temperatura de consigna suele cambiar, ya que para los modos mencionados suelen fijarse temperaturas de consigna diferentes. El cambio del valor de consigna utilizado para el control de la temperatura (ventilación, refrigeración) es elevado o reducido lentamente por el regulador a lo largo de una rampa. \r\nEsto es especialmente importante en dos casos: \r\n1. Sin la rampa antes mencionada, un sistema de refrigeración conectado trataría de enfriar inmediatamente a la temperatura nocturna establecida al cambiar de día a noche. \r\n2. Una rampa adecuada garantiza que la ventilación reaccione al aumento de la temperatura en una fase temprana cuando se produce un cambio de noche a día, incluso antes de que la temperatura medida se aproxime a la 'temperatura de consigna día'. \r\nEl valor óptimo depende de su instalación. En particular, depende de lo rápido que se caliente la habitación debido al calor residual de las lámparas. Se alcanza un valor óptimo cuando la ventilación reacciona pronto y adecuadamente al aumento de la temperatura al pasar de la noche al día. \nLa temperatura de consigna se actualiza inmediatamente cuando: \r\n1. La temperatura de consigna para el modo actual (día/noche/CO₂) ha sido modificada por el usuario. \r\n2. Cuando se produce un cambio entre (día/noche/CO₂) debido a la entrada del usuario. (por ejemplo, cambio de la hora actual).",
        image: 'https://docs.growcontrol.de/temp_trans_no_heater_EN_x820.png'
      },
      it: {
        brief: 'Durata della transizione di temperatura giorno/notte/CO₂',
        full: "Durata della transizione di temperatura giorno/notte/CO₂ \r\nQuando si passa da giorno/notte/CO₂, la temperatura di setpoint di solito cambia, in quanto per le modalità citate vengono solitamente impostate temperature di setpoint diverse. La variazione del valore nominale utilizzato per la regolazione della temperatura (ventilazione, raffreddamento) viene aumentata o diminuita lentamente dal regolatore lungo una rampa. Questo è particolarmente importante per due scenari: \r\n1. Senza la suddetta rampa, un sistema di raffreddamento collegato cercherebbe di raffreddare immediatamente alla temperatura notturna impostata quando si passa dal giorno alla notte. \r\n2. Una rampa adeguata garantisce che la ventilazione reagisca all'aumento della temperatura in una fase precoce quando si passa dalla notte al giorno, anche prima che la temperatura misurata si avvicini alla 'temperatura diurna impostata'. \r\nIl valore ottimale dipende dalla configurazione. In particolare, dipende dalla velocità con cui l'ambiente si riscalda a causa del calore residuo delle lampade. Il valore ottimale si raggiunge quando la ventilazione reagisce tempestivamente e in modo adeguato all'aumento della temperatura nel passaggio dalla notte al giorno. \r\nLa temperatura nominale viene aggiornata immediatamente quando: \r\n1. La temperatura nominale per la modalità corrente (giorno/notte/CO₂) è stata modificata dall'utente. \r\n2. Quando si verifica un cambiamento tra (giorno/notte/CO₂) a causa di un input dell'utente. (ad esempio, la modifica dell'ora corrente).",
        image: 'https://docs.growcontrol.de/temp_trans_no_heater_EN_x820.png'
      }
    }
  }
};
